import { Timestamp } from "firebase/firestore";
import Contact          from "./Contact";
import { Features }     from "./Feature";
import LastModified     from "./LastModified";
import Name             from "./Name";
import PointOfContact   from "./PointOfContact";

/**
 * Class to create a User object
 */
 export default class User {
    /**
     *@param {string}               UUID                - Users current session affiliation
     *@param {string}               activeAFFL          - Users current session affiliation
     *@param {Contact}              [contact]           - Users contact information
     *@param {Features}             [features]          - Users service subscriptions
     *@param {string}               [group]             - Users affiliation group information (Optional)
     *@param {LastModified}         lastModified        - Last modification of user information transaction
     *@param {Timestamp}            dateCreated         - Date the User account was created
     *@param {string}               photoURL            - Users profile image url
     *@param {number}               [subGroup]          - Users affiliation secondary information (Optional)
     *@param {Name}                 [name]              - Users name
     *@param {string}               displayName         - Users preferred name
     *@param {boolean}              userVerified        - User has been verified
     *@param {Array<ProviderData>}  providerData        - Users authentication provider data
     *@param {number}               providerOptionSet   - Users Authentication provider option set specifies which authentication providers are linked
     *@param {number}               accessOptionSet   - Users Authentication provider option set specifies which authentication providers are linked
     */
//      *@param {LPA} [lpa] - Users audit roles (Optional)
//      *@param {AfflMap} [affl] - Users complete affiliation data


    constructor(UUID, activeAFFL, contact, features, group, lastModified, dateCreated, photoURL, subGroup, name, displayName, userVerified, providerData, providerOptionSet, accessOptionSet) {

        /**
         *@property {string}                UUID                - Users current session affiliation
         *@property {string}                activeAFFL          - Users current session affiliation
         *@property {Contact}               [contact]           - Users contact information
         *@property {Features}              [features]          - Users service subscriptions
         *@property {string}                [group]             - Users affiliation group information (Optional)
         *@property {LastModified}          lastModified        - Last modification of user information transaction
         *@property {Timestamp}             dateCreated         - Date the User account was created
         *@property {string}                photoURL            - Users profile image url
         *@property {number}                [subGroup]          - Users affiliation secondary information (Optional)
         *@property {Name}                  [name]              - Users name
         *@property {string}                displayName         - Users preferred name
         *@property {boolean}               userVerified        - User has been verified
         *@property {Array<ProviderData>}   providerData        - Users authentication provider data
         *@property {number}                providerOptionSet   - Users Authentication provider option set specifies which authentication providers are linked
         *@property {number}                accessOptionSet     - Users access option set specifies which screens are displayed by default or can be accessed.
         */

        // this.UUID           = UUID;
        // this.activeAFFL     = activeAFFL;
        // // this.affl           = affl;
        // this.contact        = contact;
        // this.features       = features;
        // this.group          = group;
        // this.lastModified   = lastModified;
        // // this.lpa            = lpa;
        // this.photoURL       = photoURL;
        // this.subGroup       = subGroup;
        // this.name           = name;
        // this.userVerified   = userVerified;

    this.UUID               = UUID;
    this.activeAFFL         = activeAFFL;
    this.contact            = contact;
    this.features           = features;
    this.group              = group;
    this.lastModified       = lastModified;
    this.dateCreated        = dateCreated;
    this.photoURL           = photoURL;
    this.subGroup           = subGroup;
    this.name               = name;
    this.displayName        = displayName;
    this.userVerified       = userVerified;
    this.providerData       = providerData;
    this.providerOptionSet  = providerOptionSet;
    this.accessOptionSet    = accessOptionSet;

    }

    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of
     * @returns     {Object}
     */

    ToDict() {

        // var dictAffl    = this.affl
        // var dictActAffl = this.activeAFFL
        // var returnAffl  = {
        //     dictActAffl: dictAffl[dictActAffl].ToDict()
        // }
        // console.log(this.affl[this.activeAFFL].ToDict())
        // console.log(returnAffl)


        return {

            // "UUID"          : this.UUID,
            // "activeAFFL"    : this.activeAFFL,
            // // "affl"          : this.affl.ToDict(), //returnAffl, //map((obj) => { return obj.ToDict() }),
            // "contact"       : this.contact.ToDict(),
            // "features"      : this.features.ToDict(),
            // "group"         : this.group,
            // "lastModified"  : this.lastModified.ToDict(),
            // // "lpa"           : this.lpa.ToDict(),
            // "photoURL"      : this.photoURL,
            // "subGroup"      : this.subGroup,
            // "name"          : this.name.ToDict(),
            // "userVerified"  : this.userVerified

            'UUID'              : this.UUID,
            'activeAFFL'        : this.activeAFFL,
            'contact'           : this.contact              .ToDict(),
            'features'          : this.features             .ToDict(),
            'group'             : this.group,
            'lastModified'      : this.lastModified         .ToDict(),
            'dateCreated'       : this.dateCreated,
            'photoURL'          : this.photoURL,
            'subGroup'          : this.subGroup,
            'name'              : this.name                 .ToDict(),
            'displayName'       : this.displayName,
            'userVerified'      : this.userVerified,
            'providerData'      : this.providerData,
            'providerOptionSet' : this.providerOptionSet,
            'accessOptionSet'   : this.accessOptionSet

        };
    }

    /**
     * Function toPOC
     * @property    {Function} toPOC Returns a dictionary format of
     * @returns     {PointOfContact}
     */

    toPOC() {
        return new PointOfContact(
            this.UUID,
            [],//this.affl,
            this.contact,
            this.group,
            this.photoURL,
            this.subGroup,
            this.name);
    }



    /**
     * Function getPOC
     * @property    {Function}  getPOC Returns a dictionary format of
     * @param       {User}      user the user to create contact
     * @static
     * @returns     {Object}
     */

    static getPOC(user) {

        // var dictAffl = this.affl
        // var dictActAffl = this.activeAFFL
        // var returnAffl = {
        //     dictActAffl: dictAffl[dictActAffl].ToDict()
        // }
        // console.log(this.affl[this.activeAFFL].ToDict())
        // console.log(returnAffl)
        // var affl = user.affl.ToDict();

        return {

            // "UUID"          : user.UUID,
            // "activeAFFL"    : 'noaffl',//user.activeAFFL,
            // // "affl"          : affl, //{ "ASkJK75eIHj0azzND6pz": null },//user.affl[user.activeAFFL].ToDict()), //returnAffl, //map((obj) => { return obj.ToDict() }),
            // "contact"       : user.contact.ToDict(),
            // // "features"      : user.features.ToDict(),
            // "group"         : user.group,
            // "lastModified"  : user.lastModified.ToDict(),
            // // "lpa"           : user.lpa.ToDict(),
            // "photoURL"      : user.photoURL,
            // "subGroup"      : user.subGroup,
            // "name"          : user.name.ToDict(),
            // "userVerified"  : user.userVerified

            'UUID'              : user.UUID,
            'activeAFFL'        : user.activeAFFL,
            'contact'           : user.contact,
            'features'          : user.features,
            'group'             : user.group,
            'lastModified'      : user.lastModified,
            'dateCreated'       : user.dateCreated,
            'photoURL'          : user.photoURL,
            'subGroup'          : user.subGroup,
            'name'              : user.name,
            'displayName'       : user.displayName,
            'userVerified'      : user.userVerified,
            'providerData'      : user.providerData,
            'providerOptionSet' : user.providerOptionSet,
            'accessOptionSet'   : user.accessOptionSet

        };
    }

    //     /**
    //  * Function getPOC
    //  * @property {Function} getPOC Returns a dictionary format of
    //  * @returns {PointOfContact}
    //  */

    //     toPOC() {

    //         return new PointOfContact(this.UUID, this.affl, this.contact, this.group, this.photoURL, this.subGroup, this.name)
    //     }

    /**
     * Function toPOC
     * @property    {Function} toPOCDict Returns a dictionary format of
     * @returns     {Object}
     */

    getPOCDict() {

        return {
            "UUID"          : this.UUID,
            // "affl"          : this.affl.ToDict(),
            "contact"       : this.contact.ToDict(),
            "group"         : this.group,
            "photoURL"      : this.photoURL,
            "subGroup"      : this.subGroup,
            "name"          : this.name.ToDict()
        };
    }

    toString() {
        return `${this.activeAFFL}, ${this.group}, ${this.userVerified}`;
    }
}

/**
 * A User
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} UserConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type UserConverter
 */

export const userConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send User object to database
     * @param       {User}      user User to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (user) {


        return {

            // UUID            : user.UUID,
            // activeAFFL      : user.activeAFFL,
            // // affl            : user.affl.ToDict(), //map((obj) => { return obj.ToDict() }),
            // contact         : user.contact.ToDict(),
            // features        : user.features.ToDict(),
            // group           : user.group,
            // lastModified    : user.lastModified.ToDict(),
            // // lpa             : user.lpa.ToDict(),
            // photoURL        : user.photoURL,
            // subGroup        : user.subGroup,
            // name            : user.name.ToDict(),
            // userVerified    : user.userVerified


            UUID                : user.UUID,
            activeAFFL          : user.activeAFFL,
            contact             : user.contact              .ToDict(),
            features            : user.features             .ToDict(),
            group               : user.group,
            lastModified        : user.lastModified         .ToDict(),
            dateCreated         : user.dateCreated,
            photoURL            : user.photoURL,
            subGroup            : user.subGroup,
            name                : user.name                 .ToDict(),
            displayName         : user.displayName,
            userVerified        : user.userVerified,
            providerData        : user.providerData,
            providerOptionSet   : user.providerOptionSet,
            accessOptionSet     : user.accessOptionSet

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into User object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {User}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new User(
            // data.UUID,
            // data.activeAFFL,
            // // AfflMap.FromDict(data.affl),
            // Contact.FromDict(data.contact),
            // Features.FromDict(data.features),
            // data.group,
            // LastModified.FromDict(data.lastModified),
            // // LPA.FromDict(data.lpa),
            // data.photoURL,
            // data.subGroup,
            // Name.FromDict(data.name),
            // data.userVerified

            data.UUID,
            data.activeAFFL,
            Contact.FromDict(data.contact),
            Features.FromDict(data.features),
            data.group,
            LastModified.FromDict(data.lastModified),
            data.dateCreated,
            data.photoURL,
            data.subGroup,
            Name.FromDict(data.name),
            data.displayName,
            data.userVerified,
            data.providerData,
            data.providerOptionSet,      
            data.accessOptionSet            
        );
    }
}
