import styled   from 'styled-components';

export const FormSelectStyled = styled.select`
  padding         : 16px 16px;
  margin-bottom   : 32px;
  border          : none;
  border-radius   : 4px;
`;

export const FormLabelLargeStyled = styled.label`
  margin-bottom     : 8px;
  font-size         : 18px;
  font-weight       : 400;
  color             : #fff;
`;

export const FormLabelStyled = styled.label`
  margin-bottom   : 8px;
  font-size       : 14px;
  color           : #fff;
`;

export const FormSelectContainer = styled.div`
  display         : grid;
`;