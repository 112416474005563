import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const BoltOnPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch the Bolt On page where you will be able to view our pre built app add ons! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default BoltOnPage