// Components
import React            from 'react';
import { useSelector }  from 'react-redux';
import Chart            from '../Chart';
import Featured         from '../Featured';
import LgWidget         from '../LgWidget';
import SmWidget         from '../SmWidget';
import { userData }     from '../../dummyData';

import {
  Container,
  DashboardContainer,
  DashboardWidgets


} from './dashboard.elements';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const Dashboard = props => {

  const { currentUser } = useSelector(mapState);

  return (
    <>
      <Container>
        <DashboardContainer>
            {/* <Featured />
            <Chart data={userData} title="User Analytics" grid dataKey="Active User" />
            <DashboardWidgets>
                <SmWidget />
                <LgWidget />
            </DashboardWidgets> */}
        </DashboardContainer>
      </Container>
    </>
  );
};

export default Dashboard;
