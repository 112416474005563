import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const PressPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch our Press Portal where we display any press articles or videos that have mentioned the business, and provide journalists and media organizations with the means to easily learn about and contact the us at Luckey Logic. Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default PressPage