import React from   'react';
import {
    FormInputStyled,
    FormLabelStyled,
    FormInputContainer, 
    FormLabelLargeStyled}  from './formInput.elements'

const FormInput = ({ handleChange, label, headline, ...otherProps }) => {
  return (
    <FormInputContainer>
      {headline && (
        <FormLabelLargeStyled htmlFor='for'>
          {headline}
        </FormLabelLargeStyled>
      )}

      {label && (
          <FormLabelStyled htmlFor='for'>
              {label}
          </FormLabelStyled>
      )}
      <FormInputStyled onChange={handleChange} {...otherProps} />
    </FormInputContainer>
  )
}

export default FormInput;