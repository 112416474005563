export const contactObj1 = {
    id          : 'contact',
    lightBg     : true,
    topLine     : 'Contact Info',
    headline    : 'Reach out to us anytime',
    description : 'Luckey, OH, 43443. \n Phone: (252)648-1099 \n Email: contact@luckeylogic.com',
    buttonLabel : 'Back to Home',
    img         : require('../../images/contact.svg').default,
    alt         : 'Contact',
    darkText    : true
};