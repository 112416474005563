import styled   from 'styled-components';
import { Link } from 'react-router-dom';
import FormBG   from '../../images/FormBG.jpg';

export const Container = styled.div`
  min-height      : 3000px;
  position        : absolute;
  bottom          : 0;
  left            : 0;
  right           : 0;
  top             : 0;
  z-index         : 0;
  background      : linear-gradient(
    108deg,
    rgba(25, 51, 86, 1) 0%,
    rgba(103, 214, 255, 1) 100%
  );
`;

export const FormWrap = styled.div`
  height          : 100%;
  display         : flex;
  flex-direction  : column;
  justify-content : center;

  @media screen and (max-width: 400px) {
    height        : 80%;
  }
`;

export const Icon = styled(Link)`
  margin-left     : 32px;
  margin-top      : 32px;
  text-decoration : none;
  color           : #fff;
  font-weight     : 700;
  font-size       : 32px;

  @media screen and (max-width: 480px) {
    margin-left   : 16px;
    margin-top    : 8px;
  }
`;

export const LogoImage = styled.img`
    height            : 75px;
    margin-bottom     : 10px;
`;

export const FormContent = styled.div`
  height          : 100%;
  display         : flex;
  flex-direction  : column;
  justify-content : center;

  @media screen and (max-width: 480px) {
    padding       : 10px;
  }
`;

export const Form = styled.form`
  /* background      : #010101; */
  /* background-image: url(${FormBG}); */
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${FormBG});
  background-size: cover;

  max-width       : 800px;
  height          : auto;
  width           : 100%;
  z-index         : 1;
  display         : grid;
  margin          : 0 auto;
  padding         : 80px 32px;
  border-radius   : 4px;
  box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
    padding       : 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom   : 40px;
  color           : #fff;
  font-size       : 40px;
  font-weight     : 400;
  text-align      : left;
`;

export const FormH2 = styled.h2`
  margin-bottom   : 40px;
  color           : #fff;
  font-size       : 25px;
  font-weight     : 300;
  text-align      : left;
`;


export const FormLabelLarge = styled.label`
  margin-bottom     : 8px;
  font-size         : 18px;
  font-weight       : 400;
  color             : #fff;
`;


export const FormLabel = styled.label`
  margin-bottom   : 8px;
  font-size       : 14px;
  color           : #fff;
`;

export const FormInput = styled.input`
  padding         : 16px 16px;
  margin-bottom   : 32px;
  border          : none;
  border-radius   : 4px;
`;

export const FormButton = styled.button`
  background      : #67d6ff;
  padding         : 16px 10px 16px 10px;
  border          : none;
  border-radius   : 4px;
  color           : #fff;
  font-size       : 20px;
  cursor          : pointer;
`;

export const SignInWithGoogleButton = styled.button`
background      : #193356;
padding         : 16px 10px 16px 10px;
border          : none;
border-radius   : 4px;
color           : #fff;
font-size       : 20px;
cursor          : pointer;
`;

export const Text = styled.span`
  text-align      : left;
  margin          : 0px 30px 30px 30px;
  color           : #ff0;
  font-size       : 14px;
`;

export const SmallText = styled.span`
  text-align      : left;
  margin          : 0px 30px 30px 30px;
  color           : #ff0;
  font-size       : 8px;
`;

export const Errors = styled.span`
  text-align      : center;
  margin          : 0px 30px 30px 30px;
  color           : #f00;
  font-size       : 14px;
`;

export const SigninA = styled.a`
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
`
export const FormSelect = styled.select`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

export const FormSelectWrap = styled.div`
  margin-bottom: 32px;
`;

export const FormSelectLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;
  display: block;
`;

// export const StyledCheckbox = styled.input`
//   display: none;

//   &:checked + label {
//     background-color: #2196F3;
//   }
// `;

// export const Label = styled.label`
//   position: relative;
//   display: inline-block;
//   width: 60px;
//   height: 34px;
//   background-color: #ccc;
//   border-radius: 34px;
//   cursor: pointer;

//   &:before {
//     content: "";
//     position: absolute;
//     height: 26px;
//     width: 26px;
//     left: 4px;
//     bottom: 4px;
//     background-color: white;
//     border-radius: 50%;
//     transition: transform 0.2s ease;
//   }
// `;