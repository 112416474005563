import React                                from 'react';
import { useState }                         from 'react';
import {
    SocialMediaContainer,
    SocialMediaWrapper,
    WhyChooseUs,
    FeedbackForm,
    SubmitButton
}                                           from './index.elements';
import Feedback                             from '../../model/Feedback';
import FormInput                            from '../Forms/FormInput';
import FormSelect                           from '../Forms/FormSelect';
import { handleSubmitSocialMediaFeedback }  from '../../firebase/utils';

const SocialMedia = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        var feedback = new Feedback(createIdFromEmail(email), email, name, [comments], services, "Social Media Feedback")
        // Implementation for form submission, e.g., API call
        handleSubmitSocialMediaFeedback(feedback)
        .then(()=>{
            setIsSubmitted(true)
            reset()
        })
        console.log("Feedback submitted");
    };

    function createIdFromEmail(email) {
        return email.replace(/[^a-zA-Z0-9]/g, '');
      }

    const handleServicesChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setServices(selectedOptions);
    };

    const [email,       setEmail]       = useState('');
    const [name,        setName]        = useState('');
    const [services,    setServices]    = useState([]);
    const [comments,    setComments]    = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const reset = () => {
        setEmail('');
        setName('');
        setServices([]);
        setComments('');
      }

    return (
        <SocialMediaContainer>
            <SocialMediaWrapper>
                <WhyChooseUs>
                    With our expertise in digital solutions and a keen understanding of digital marketing trends, we aim to offer services that truly make a difference.
                </WhyChooseUs>
                <FeedbackForm onSubmit={handleSubmit}>
                <FormInput
              label           ='Name'
              type            ='text'
              autoCapitalize  ='words'
              name            ='name'
              value           ={name}
              placeholder     ='Name'
              handleChange    ={e => setName(e.target.value)}
              required />

                    <FormInput
              label           ='Email'
              type            ='email'
              name            ='email'
              value           ={email}
              placeholder     ='Email'
              handleChange    ={e => setEmail(e.target.value)}
              required />


                <FormSelect
              label           ='Services of Interest'
              name            ='services'
              value           ={services}
              placeholder     ='Service'
              handleChange    ={handleServicesChange}
              multiple
              required >

                        <option value="" disabled>Select Services</option>
                        <option value="content-creation">Content Creation</option>
                        <option value="analytics">Analytics and Reporting</option>
                        <option value="strategy">Strategy Development</option>
                        <option value="community">Community Management</option>
                </FormSelect>

                   
                <FormInput
              label           ='Comments'
              type            ='text'
              autoCapitalize  ='sentences'
              name            ='comments'
              value           ={comments}
              placeholder     ='Leave your comments here'
              handleChange    ={e => setComments(e.target.value)}
              required />
                    <SubmitButton type="submit" disabled={isSubmitted}>
                        {isSubmitted ? "Thank You For Your Feedback" : "Submit Feedback"}
                    </SubmitButton>
                </FeedbackForm>
            </SocialMediaWrapper>
        </SocialMediaContainer>
    );
};

export default SocialMedia;
