// Components
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector }   from 'react-redux';
import AuthProviders                  from '../AuthProviders';
import { useNavigate }                from 'react-router-dom';
import { emailSignInStart }           from '../../redux/User/user.actions';

import {Container,
        FormWrap,
        FormContent,
        Form,
        FormH1,
        FormLabel,
        FormInput,
        FormButton,
        Errors,
        SignupA
      }                               from './elements';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const SignIn = props => {
  const dispatch                      = useDispatch();
  const navigate                      = useNavigate();
  const { currentUser }               = useSelector(mapState);
  const [email,         setEmail]     = useState('');
  const [password,      setPassword]  = useState('');
  const [errors,        setErrors]    = useState([]);

  useEffect(() => {
    if (currentUser) {
      
      resetForm();
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const resetForm = () => {
    setEmail('')
    setPassword('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    const err = []

    if (email.length < 5 || !email.includes('@')) {
      err.push('Email incorrect')
    }

    if (password.length < 6) {
      err.push('Password incorrect')
    }

    if (email.length > 4 && password.length > 5) {
        dispatch(emailSignInStart({ email, password }));
    } else {
      setErrors(err);
    }
  }

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Sign in to your account</FormH1>
              <Errors>
              {errors.length > 0 && (
                <ul>
                  {errors.map((err, index) => {
                    return (
                      <li key={index}>
                        {err}
                      </li>
                    )
                  })}
                </ul>
              )}
              </Errors>
              <FormLabel htmlFor  ='for'>Email</FormLabel>
              <FormInput
              type                ='email'
              name                ='email'
              value               ={email}
              placeholder         ='dan.daly@example.com'
              onChange            ={e => setEmail(e.target.value)}
              required />

              <FormLabel htmlFor  ='for'>Password</FormLabel>
              <FormInput
              type                ='password'
              name                ='password'
              value               ={password}
              placeholder         ='Enter password'
              onChange            ={e => setPassword(e.target.value)}
              required />              <FormButton               type    ='submit'>  Continue  </FormButton>
              <AuthProviders />
              <SignupA href='/recovery'>  Forgot password                </SignupA>
              <SignupA href='/signup'>    Don't have an account? Signup  </SignupA>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignIn;