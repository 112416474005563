// index.js
import React from 'react';
import { Card, Image, Name, Description, JobTitle, CityState, SaveButton, LinkList } from './index.elements';
import vCard from './vCard';

const ContactProfile = ({ user }) => {

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([vCard], {type: "text/vcard;charset=utf-8"});
        element.href = URL.createObjectURL(file);
        element.download = 'contacts.vcf';
        document.body.appendChild(element);
        element.click();
      };

      const handleResumeDownload = () => {
        const element = document.createElement('a');
        // Direct URL to Firebase Storage
        element.href = "https://firebasestorage.googleapis.com/v0/b/luckey-logic.appspot.com/o/Users%2Ftemp%2Fdocuments%2FMiguel%20Brown%20Resume%2010-03-2023.pdf?alt=media&token=8e1fc1f1-2973-4c21-95a8-349faf856567&_gl=1*1k566vj*_ga*MTI1ODE0NzkyLjE2OTE3MjQ0MzM.*_ga_CW55HF8NVT*MTY5NjQ3MzE1My41My4xLjE2OTY0NzMyMTAuMy4wLjA.";
        element.download = 'Miguel-Brown-Resume-10-03-2023.pdf';
        document.body.appendChild(element);
        element.click();
    };

  return (
    <Card>
      <Image src={user.image} />
      <Name>{user.name}</Name>
      <Description>{user.description}</Description>
      <JobTitle>{user.jobTitle}</JobTitle>
      <CityState>{user.city}, {user.state}</CityState>
      <LinkList>
        {user.links.map(link => (
          <a key={link.name} href={link.url}>
            {link.name}
          </a>
        ))}
      </LinkList>
      <SaveButton onClick={handleDownload}>Open Contact Card</SaveButton>
      <SaveButton onClick={handleResumeDownload}>Download Resume</SaveButton>
    </Card>
  );
};

export default ContactProfile;
