import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const LegalPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch our Legal page which will contain a copy of all of our relevant privacy and usage policies! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default LegalPage