import { 
    takeLatest, 
    call, 
    all, 
    put }                       from 'redux-saga/effects';

import { 
    signInSuccess, 
    // discoverySuccess,
    signOutUserSuccess, 
    resetPasswordSuccess,
     userError, 
     limitedSignInSuccess}                from './user.actions';

import { 
    auth, 
    handleUserProfile,
    // handleUserDiscovery,
    getCurrentUser, 
    googleProvider, 
    facebookProvider, 
    twitterProvider,
    staticGoogleProvider,
    staticFacebookProvider,
    staticTwitterProvider,
    staticEmailProvider, 
    handleUpdateUserProfile,
    getProviderOptionSet,
    getAccessOptionSet}     from "../../firebase/utils";

import { 
    handleResetPasswordAPI,
    handleLinkProvider, 
    handleLinkEmailPassword}    from './user.helpers';

import userTypes                from "./user.types";
import Name                     from '../../model/Name';
import LastModified             from '../../model/LastModified';
import Email                    from '../../model/Email';
import Phone                    from '../../model/Phone';
import Street                   from '../../model/Street';
import Address                  from '../../model/Address';
import Contact                  from '../../model/Contact';
import User                     from '../../model/User';
import { Timestamp } from 'firebase/firestore';
import Feature, { Features, featuresJSON } from '../../model/Feature';

/** 
 * Generator Function to return User Data from Database
 * @param {firebase.default.auth.UserCredential}    user                - The user credential from authentication.
 * @param {Object}                                  [additionalData]    - Any additional data provided for the user.
 * 
*/

export function* getSnapshotFromUserAuth(user, additionalData={}) {
    try {
        const userRef   = yield call(handleUserProfile, { userAuth: user, additionalData });
        const snap      = yield userRef.get();

        
        if (userRef.path.includes('NewUsers')) {
            console.log(`New User Still Onboarding. User Ref: ${userRef.path}`)
            yield put(
                limitedSignInSuccess({
                    id  : snap.id,
                      ...snap.data()
                  })
            );
        } else {
            console.log(`Onboarded User. User Ref: ${userRef.path}`)
            yield put(
                signInSuccess({
                    id  : snap.id,
                      ...snap.data()
                  })
            );
        }
        

    } catch(error) {
        console.log('Error in getSnapshotFromUserAuth():', error)
    }
}

/** 
 * Generator Function to return User Discovery data from Database
 * @param {User}                                    user                - User Object to submit to database
 * @param {Object}                                  [additionalData]    - Any additional data provided for the user.
 * 
*/

export function* getSnapshotFromUpdatedUser(user, additionalData={}) {
    try {
        const userRef   = yield call(handleUpdateUserProfile, { user: user,});
        const snap      = yield userRef.get();

        yield put(
            signInSuccess({
                id  : snap.id,
                  ...snap.data()
              })
        );

    } catch(error) {
        console.log('Error in getSnapshotFromUserDiscovery():', error)
    }
}

/**
 * Generator Function to sign in with email and password.
 * @param {Object}  payload             - The user credential from authentication.
 * @param {string}  payload.email       - email of the user.
 * @param {string}  payload.password    - password of the user.
 * 
*/

export function* emailSignIn({ payload: { email, password } }) {
    console.log(`user.sagas.js::emailSignIn()`)
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        yield getSnapshotFromUserAuth(user)
        
    } catch (error) {
        console.log("Sign In Error in SignUp/index.js:",error);
    }
}

export function* onEmailSignInStart() {
    console.log(`user.sagas.js::onEmailSignInStart()`)
    yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

export function* isUserAuthenticated() {
    try {
        const userAuth = yield getCurrentUser();
        if (!userAuth) return;

        yield getSnapshotFromUserAuth(userAuth);
        
    } catch (error) {
        console.log('Error checking if user is authenticated in isUserAuthenticated:', error);
    }
}

export function* onCheckUserSession() {
    yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOutUser() {
    try {
        yield auth.signOut();
        yield put(
            signOutUserSuccess()
        )
    } catch (error) {
        console.log('Error signing out user:', error);
    }
}

export function* onSignOutUserStart() {
    yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser)
}

// export function* getUserDiscovery({ payload: { answers } }) {
//     try {
//         const userAuth = yield getCurrentUser();
//         if (!userAuth) return;

//         yield getSnapshotFromUserDiscovery(userAuth, answers);
        
//     } catch (error) {
//         console.log('Error checking if user is authenticated in isUserAuthenticated:', error);
//     }
// }

// export function* onGetUserDiscoveryStart() {
//     yield takeLatest(userTypes.)
// }

export function* signUpUser({ payload: {
    displayName,
    email,
    password,
    confirmPassword
}}) {

    if (password !== confirmPassword) {
        const error = ['Passwords do not match'];
        yield put(
            userError(error)
        );
        return;
    }

    try {
        const { user }          = yield auth.createUserWithEmailAndPassword(email, password);
        const additionalData    = { displayName };
        yield getSnapshotFromUserAuth(user, additionalData);
        
    } catch(error) {
        console.log('Error Signing up user: ', error);
    }
}

export function* onSignUpUserStart() {
    yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser)
}

/**
 * Generator Function to complete user profile.
 * @param {Object}  payload                 - The user data from the form.
 * @param {string}  payload.prefix          - prefix of the name of the user.
 * @param {string}  payload.first           - password of the user.
 * @param {string}  payload.middle          - middle name of the user.
 * @param {string}  payload.last            - last name of the user.
 * @param {string}  payload.suffix          - suffix of the name of the user.
 * @param {string}  payload.phoneLabel      - label of the phone provided by the user.
 * @param {string}  payload.number          - phone number provided by the user.
 * @param {string}  payload.provider        - provider of the phone service for the user.
 * @param {string}  payload.textMe          - boolean value representing if the user accepts text notifications.
 * @param {string}  payload.type            - type of phone device.
 * @param {string}  payload.addrType        - the style of roadway for the address provided by the user.
 * @param {string}  payload.streetName      - the name of the street address provided by the user.
 * @param {string}  payload.streetNumber    - the number of the street address provided by the user.
 * @param {string}  payload.city            - the city of the address provided by the user.
 * @param {string}  payload.state           - the state of the address provided by the user.
 * @param {string}  payload.zip             - the zip code of the address provided by the user.
 * @param {string}  payload.country         - the country of the address provided by the user.
 * @param {string}  payload.addressLabel    - the label for the address provided by the user.

 * 
*/

export function* completeUserProfile({ payload: {
            prefix,
            first, 
            middle,
            last,  
            suffix,
            email,
            phoneLabel,
            number,
            provider,
            textMe,
            type,
            street,
            // addrType,    
            // streetName,  
            // streetNumber,
            city,        
            state,       
            zip,         
            country,     
            addressLabel
}}) {
    console.log(`user.sagas.js:completeUserProfile()`);
    try {

        const userAuth = yield getCurrentUser();

        var providerOptionSet = getProviderOptionSet(userAuth);
        var accessOptionSet = getAccessOptionSet(['profileComplete']);
        // console.log("Listing Test accessOptionSets:");
        // console.log(getAccessOptionSet(["profileComplete"]));
        // console.log(getAccessOptionSet(["discovery"]));
        // console.log(getAccessOptionSet(["profileComplete", "discovery"]));
        
        
        
        console.log(`providerOptionSet: ${providerOptionSet}, accessOptionSet: ${accessOptionSet}`);
        const submitName            = new Name(
            prefix,
            first,
            middle,
            last,
            suffix);

        const submitLastModified    = new LastModified(
            submitName.fullFormal(),
            '',
            Timestamp.now(),
            'updated');

        const submitFeatures        = new Features(featuresJSON)
        // const submitFeature        = new Feature(
        //     'LUCKEYLOGIC',
        //     true,
        //     0);

        // const submitEmail           = new Email(
        //     userAuth.email,
        //     userAuth.email,
        //     'default');

        const submitEmail           = Email.fromString(email)

        const submitPhone           = new Phone(
            phoneLabel,
            number,
            provider,
            textMe,
            type);

        // const submitStreet          = new Street(
        //     addrType,
        //     streetName,
        //     streetNumber);

        const submitAddress         = new Address(
            street,
            city,
            state,
            zip,
            country,
            addressLabel);

        const submitContact         = new Contact(
            submitAddress,
            submitEmail,
            submitPhone);

        const submitUser            = new User(
            userAuth.uid,
            'noaffl',
            submitContact,
            submitFeatures,
            null,
            submitLastModified,
            // Timestamp.now(),
            userAuth.metadata.creationTime,
            userAuth.photoURL,
            null,
            submitName,
            userAuth.displayName,
            false,
            userAuth.providerData,
            providerOptionSet,
            accessOptionSet);
        if (!userAuth) return;
            console.log(`SubmitUser:`,submitUser)
        yield getSnapshotFromUpdatedUser(submitUser);
        
    } catch (error) {
        console.log('Error checking if user is authenticated in isUserAuthenticated:', error);
    }
}

export function* onCompleteUserProfileStart() {
    yield takeLatest(userTypes.COMPLETE_USER_PROFILE_START, completeUserProfile)
}

export function* resetPassword({ payload: { email }}) {
    try {
        yield call(handleResetPasswordAPI, email);
        yield put(
            resetPasswordSuccess()
        );
    } catch(error) {
        console.log("Error requesting Password Reset:", error);
        yield put(
            userError(error)
        );
    }
}

export function* onResetPasswordStart() {
    yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword)
}

export function* googleSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(googleProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Google:', error)
    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn)
}

export function* facebookSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(facebookProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Facebook:', error)
    }
}

export function* onFacebookSignInStart() {
    yield takeLatest(userTypes.FACEBOOK_SIGN_IN_START, facebookSignIn)
}

export function* twitterSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(twitterProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Twitter:', error)
    }
}

export function* onTwitterSignInStart() {
    yield takeLatest(userTypes.TWITTER_SIGN_IN_START, twitterSignIn)
}

// Link Providers

export function* linkPassword({ payload: { email, password } }) {
    try {

        const { user } = yield handleLinkEmailPassword(staticEmailProvider, email, password);
        yield getSnapshotFromUserAuth(user)

    } catch (error) {
        console.log("Sign In Error in SignUp/index.js:",error);
    }
}

export function* onLinkPasswordStart() {
    yield takeLatest(userTypes.LINK_PASSWORD_START , linkPassword);
}

export function* linkGoogle() {
    try {
        yield handleLinkProvider(staticGoogleProvider)
        // yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error Linking Provider with Google:', error)
    }
}

export function* onLinkGoogleStart() {
    yield takeLatest(userTypes.LINK_GOOGLE_START, linkGoogle)
}


export function* linkFacebook() {
    try {
        yield handleLinkProvider(staticFacebookProvider)

    } catch(error) {
        console.log('Error Linking Provider with Facebook:', error)
    }
}

export function* onLinkFacebookStart() {
    yield takeLatest(userTypes.LINK_FACEBOOK_START, linkFacebook)
}

export function* linkTwitter() {
    try {
        yield handleLinkProvider(staticTwitterProvider)

    } catch(error) {
        console.log('Error Linking Provider with Twitter:', error)
    }
}

export function* onLinkTwitterStart() {
    yield takeLatest(userTypes.LINK_TWITTER_START, linkTwitter)
}

export default function* userSagas() {
    yield all([
        call(onEmailSignInStart),
        call(onCompleteUserProfileStart),
        call(onCheckUserSession),
        call(onSignOutUserStart),
        call(onSignUpUserStart),
        call(onResetPasswordStart),
        call(onGoogleSignInStart),
        call(onFacebookSignInStart),
        call(onTwitterSignInStart),
        call(onLinkPasswordStart),
        call(onLinkGoogleStart),
        call(onLinkFacebookStart),
        call(onLinkTwitterStart)
    ])
}