// index.js
import React from 'react';
import {
  InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper,
  TopLine, Heading, Subtitle, ImgWrap, Img, BtnLink
} from './index.elements';

const Directory = () => {
  return (
    <>
      <InfoContainer lightBg={true} id='directory'>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>Directory</TopLine>
                <Heading>Roles & Departments</Heading>
                <Subtitle>
                  <BtnLink to="/profile/founder">Founder</BtnLink>
                  <BtnLink to="/profile/ceo">CEO</BtnLink>
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={require('../../images/directory.svg').default} alt='Directory' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Directory;
