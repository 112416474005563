
/**
 * Name: The Name class contains the name information for a User.
 */
 class Name {
    /**
     *@param {string} [prefix]  - Name name prefix (Optional)
     *@param {string} first     - Name first name
     *@param {string} [middle]  - Name middle name (Optional)
     *@param {string} last      - Name last name
     *@param {string} [suffix]  - Name name suffix (Optional)
     */

    constructor(prefix, first, middle, last, suffix) {

        /**
         *@property {string} [prefix]   - Name name prefix (Optional)
         *@property {string} first      - Name first name
         *@property {string} [middle]   - Name middle name (Optional)
         *@property {string} last       - Name last name
         *@property {string} [suffix]   - Name name suffix (Optional)
         */

        this.prefix = prefix;
        this.first  = first;
        this.middle = middle;
        this.last   = last;
        this.suffix = suffix;
    }


    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of
     * @returns {Object}
     */

    ToDict() {
        return {
            "prefix"    : this.prefix,
            "first"     : this.first,
            "middle"    : this.middle,
            "last"      : this.last,
            "suffix"    : this.suffix
        };
    }

    /**
* Function FromDict
* @property {Function}  FromDict Returns a dictionary format of a
* @property {Object}    data The data from database
* @static
* @returns  {Name}      The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        console.log(`Called FromDict in Name`);

        // console.log(`Instantiating new Name object using FromDict ${data.prefix} ${data.first} ${data.middle} ${data.last} ${data.suffix}`)
        return new Name(
            data.prefix,
            data.first,
            data.middle,
            data.last,
            data.suffix);

    }

    /**
     * Function greet: Returns a greeting with the users first name
     * @function    greet
     * @returns     {string}
     */

    greet() {
        return `Welcome ${this.first}!`;
    }

    /**
     * Function fullFormal: Returns users Full Name
     * @function    fullFormal
     * @returns     {string} Full name (First name Last name)
     */

    fullFormal() {
        return `${this.first} ${this.last}`

    }
    // fullFormal() {
    //     // return `${this.first} ${this.last}`
    //     var fullFormal = `${this.first} ${this.last}`
    //     return fullFormal
    // }

}

/**
 * A Name Object
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} NameConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type NameConverter
 */

export const nameConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Name object to database
     * @param       {Name}      name Name to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (name) {
        return {

            prefix  : name.prefix,
            first   : name.first,
            middle  : name.middle,
            last    : name.last,
            suffix  : name.suffix

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into Name object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {Name}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Name(
            data.prefix,
            data.first,
            data.middle,
            data.last,
            data.suffix
        );
    }
}

export default Name;