export const sampleUser = {
    name: 'Miguel Brown',
    description: 'Luckey Logic LLC',
    jobTitle: 'Founder/CEO',
    city: 'Luckey',
    state: 'OH',
    image: 'https://firebasestorage.googleapis.com/v0/b/luckey-logic.appspot.com/o/Users%2Ftemp%2Fmiguel_1.jpeg?alt=media&token=1b6bd206-1b00-4fd5-9220-411d805f3d5e',
    links: [
      {
        name: 'LinkedIn',
        url: 'https://linkedin.com/in/MiguelBrownOfficial',
      },
      // {
      //   name: 'Facebook',
      //   url: 'https://facebook.com/LuckeyLogic',
      // },
      // {
      //   name: 'Website',
      //   url: 'https://luckeylogic.com',
      // },
    ],
  };