import { LineStyle, Timeline, TrendingUp, PermIdentity, Storefront, LocalAtm, Assessment, Drafts, Feedback, Forum, Work, Receipt, PieChart } from "@material-ui/icons";
import styled, { css } from "styled-components";
import { FaTimes }        from 'react-icons/fa';


export const SidebarContainer   = styled.div`
    /* position                    : relative;
    z-index                     : 1;
    width                       : 175px;
    height                      : calc(100vh - 100px);
    background                  : #0d0d0d;
    flex                        : 1;
    background                  : rgba(251, 251, 255,0.5);
    display                     : grid;
    overflow-y                  : scroll;
    align-items                 : center;
    top                         : 80px;
    left                        : 0;
    transition                  : 0.3s ease-in-out;
    opacity                     : ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top                         : ${({ isOpen }) => (isOpen ? '0' : '-100%')}; */

    position                    : relative;
    z-index                     : 1;
    width                       : 175px;
    height                      : calc(100vh - 100px);
    background                  : #0d0d0d;
    flex                        : 1;
    /* background                  : rgb(251, 251, 255); */
    display                     : grid;
    overflow-y                  : scroll;
    align-items                 : center;
    top                         : 80px;
    left                        : 0;
    transition                  : 0.3s ease-in-out;
    /* opacity                     : ${({ isOpen }) => (isOpen ? '100%' : '0')}; */
    /* top                         : ${({ isOpen }) => (isOpen ? '0' : '-100%')}; */





`

export const CloseIcon          = styled(FaTimes)`
  color                         : #fff;
`;

export const Icon               = styled.div`
  position                      : absolute;
  top                           : 1.2rem;
  right                         : 1.5rem;
  background                    : transparent;
  border                        : transparent;
  font-size                     : 2rem;
  cursor                        : pointer;
  outline                       : none;
`;


export const SidebarWrapper     = styled.div`
    padding                     : 20px;
    color                       : #555;
`
export const SidebarMenu        = styled.div`
    margin-bottom               : 10px;
`
export const SidebarTitle       = styled.h3`
    font-size                   : 13px;
    color                       : rgb(187, 186, 186);
`
export const SidebarList        = styled.ul`
    list-style                  : none;
    padding                     : 5px;
`
export const SidebarListItem    = styled.li`
    padding                     : 5px;
    cursor                      : pointer;
    display                     : flex;
    align-items                 : center;
    border-radius               : 10px;
    &:hover {
        background-color        : rgb(240, 240, 255);
    }
`
export const sharedStyle        = css`
    margin-right                : 5px;
    font-size                   : 20px !important;
`
export const MyLineStyle        = styled(LineStyle)`
    ${sharedStyle}
`
export const MyTimeline         = styled(Timeline)`
    ${sharedStyle}
`
export const MyTrendingUp       = styled(TrendingUp)`
    ${sharedStyle}
`
export const MyPermIdentity     = styled(PermIdentity)`
    ${sharedStyle}
`
export const MyStorefront       = styled(Storefront)`
    ${sharedStyle}
`
export const MyAssessment       = styled(Assessment)`
    ${sharedStyle}
`
export const MyLocalAtm         = styled(LocalAtm)`
    ${sharedStyle}
`
export const MyDrafts           = styled(Drafts)`
    ${sharedStyle}
`
export const MyFeedback         = styled(Feedback)`
    ${sharedStyle}
`
export const MyForum            = styled(Forum)`
    ${sharedStyle}
`
export const MyWork             = styled(Work)`
    ${sharedStyle}
`
export const MyPieChart         = styled(PieChart)`
    ${sharedStyle}
`
export const MyReceipt          = styled(Receipt)`
    ${sharedStyle}
`