import React from 'react';

import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  SignupA
} from './elements';

const Recovery = () => {
  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form action='#'>
              <FormH1>Reset your account password</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' required />
              <FormButton type='submit'>Continue</FormButton>
              <SignupA href='/signin'>Remember your password? Signin</SignupA>
              <SignupA href='/signup'>Don't have an account? Signup</SignupA>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Recovery;
