import styled from 'styled-components';

export const Container = styled.div`
    padding-top : 100px;
    min-height  : 800px;
    position    : absolute;
    bottom      : 0;
    left        : 0;
    right       : 0;
    top         : 0;
    z-index     : 0;
    overflow    : hidden;
    background  : linear-gradient(
        108deg,
        rgba(25, 51, 86, 1) 0%,
        rgba(103, 214, 255, 1) 100%
  );
`;

export const DashboardContainer = styled.div`
    flex: 4;
    margin-left: 175px;
`

export const DashboardWidgets = styled.div`
    display: flex;
    margin: 20px;
`