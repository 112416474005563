import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 9px solid #070707;
`;

export const Entity = styled.div`
  color: #ffffff;
  max-width: 690px;
  width: 99%;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  &:first-of-type {
    margin-top: 1.5em;
  }

  @media (max-width: 768px) {
    margin-bottom: 5px; // Adjust this value as needed
  }
`;

export const Question = styled.div`
  font-size: 25px;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  font-weight: bold;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 0.75em 1.12em;
  align-items: center;
  border-radius: 10px;
`;


// export const Entity = styled.div`
//   color: #070707;
//   border: 1px solid #070707;
//   max-width: 690px;
//   width: 99%;
//   margin: auto;
//   margin-bottom: 10px;
//   &:first-of-type {
//     margin-top: 1.5em;
//   }
// `;

export const Inner = styled.div`
  padding: 75px 40px;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 50px 20px; // Adjust these values as needed
  }
`;

// export const Question = styled.div`
//   font-size: 25px;
//   justify-content: space-between;
//   cursor: pointer;
//   margin-bottom: 2px;
//   display: flex;
//   font-weight: bold;
//   background: #67d6ff;
//   color: #193356;
//   padding: 0.75em 1.12em;
//   align-items: center;
// `;

export const Text = styled.p`
  max-height: 1190px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  background: #000;
  border: 1px solid #fff;
  color: #fff;
  transition: max-height 0.23s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.9em 2.1em 0.7em 1.4em;
  user-select: none;
  white-space: pre-wrap;
  border-radius: 10px;

  @media (max-width: 550px) {
    font-size: 15px;
    line-height: 25px;
  }
`;

export const Header = styled.h1`
  color: #fff;
  line-height: 7;
  margin-top: 0 !important;
  font-size: 45px;
  margin-bottom: 9px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 22px;
    margin-bottom: 5px; // Adjust this value as needed
  }
`;

export const Footer = styled.h4`
  color: #fff;
  line-height: 7;
  margin-top: 0 !important;
  font-size: 18px;
  margin-bottom: 9px;
  text-align: center;
  @media (max-width: 768px) {
    line-height: 3;
    margin-bottom: 5px; // Adjust this value as needed
  }
`;

export const FAQFeedbackContainer = styled.div`
  padding     : 100px 0;
  background  : #010606;
  color       : #ffffff;
  border-radius: 10px;
`;

export const FAQFeedbackWrapper = styled.div`
  max-width : 1100px;
  margin    : auto;
  padding   : 0 20px;
`;

export const FeedbackForm = styled.form`
  display         : flex;
  flex-direction  : column;
`;

export const Input = styled.input`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;
`;

export const TextArea = styled.textarea`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;
`;

export const Select = styled.select`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;

  option {
         color      : black;
         background : white;
       }
`;

export const SubmitButton = styled.button`
  border-radius   : 50px;
  background      : #67d6ff;
  white-space     : nowrap;
  padding         : 10px 16px;
  color           : #010606;
  font-size       : 16px;
  outline         : none;
  border          : none;
  cursor          : pointer;
  transition      : all 0.2s ease-in-out;
  text-decoration : none;

  &:hover {
    transition  : all 0.2s ease-in-out;
    background  : #fff;
    color       : #010606;
  }
`;

export const Label = styled.label`
  margin-bottom : 5px;
  font-size     : 16px;
`;

export const HeroBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Set to a negative value */
`;


export const VideoBg = styled.video`
  width             : 100%;
  height            : 100%;
  -o-object-fit     : cover;
  object-fit        : cover;
  background        : #232a34;
`;
