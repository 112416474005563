import Category from "./Category"

/**
 * Class to create a Tag object
 */
 class Tag {
    /**
     *@param {string}                                       title           - Users current session affiliation
     *@param {Array<string>}                                gradientColors  - Users current session affiliation
     *@param {string}                                       image           - Users complete affiliation data
     *@param {Array<string>}                                [gallery]       - Users contact information
     *@param {string}                                       [headline]      - Users service subscriptions
     *@param {string}                                       [id]            - Users affiliation group information (Optional)
     *@param {string}                                       term            - Last modification of user information transaction
     *@param {Category}                                     category        - Users audit roles (Optional)
     *@param {Array<FirebaseFirestore.DocumentReference>}   [reference]     - Users profile image url
     *@param {FirebaseFirestore.DocumentReference}          [origin]        - Users affiliation secondary information (Optional)
     */

    constructor(
        title,
        gradientColors,
        image,
        gallery,
        headline,
        id,
        term,
        category,
        reference,
        origin
    ) {

        /**
         *@property {string}                                        title           - Users current session affiliation
         *@property {Array<string>}                                 gradientColors  - Users current session affiliation
         *@property {string}                                        image           - Users complete affiliation data
         *@property {Array<string>}                                 [gallery]       - Users contact information
         *@property {string}                                        [headline]      - Users service subscriptions
         *@property {string}                                        [id]            - Users affiliation group information (Optional)
         *@property {string}                                        term            - Last modification of user information transaction
         *@property {Category}                                      category        - Users audit roles (Optional)
         *@property {Array<FirebaseFirestore.DocumentReference>}    [reference]     - Users profile image url
         *@property {FirebaseFirestore.DocumentReference}           [origin]        - Users affiliation secondary information (Optional)
         */

        this.title          = title
        this.gradientColors = gradientColors
        this.image          = image
        this.gallery        = gallery
        this.headline       = headline
        this.id             = id
        this.term           = term
        this.category       = category
        this.reference      = reference
        this.origin         = origin


    }

    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of a Tag Object
     * @returns     {Object}
     */

    ToDict() {
        return {

            "title"             : this.title,
            "gradientColors"    : this.gradientColors,
            "image"             : this.image,
            "gallery"           : this.gallery,
            "headline"          : this.headline,
            "id"                : this.id,
            "term"              : this.term,
            "category"          : this.category.getCategory(),
            "reference"         : this.reference,
            "origin"            : this.origin,

        };
    }


        /**
* Function MapToDict
* @property {Function}                  MapToDict Creates a dictionary format of a a list of Tag Objects
* @property {Array<Tag>}                    The data of the Tag Object
* @static
* @returns  {Array<Object>}   The map object for export
*/

static MapToDict(data) {

    if (data == null) {
        return data
    }

    console.log(`Called FromDict in Tag`);
    return data.map((tag) => {
        return tag.ToDict()
    })

}
}

/**
 * A Tag
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} TagConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type TagConverter
 */

export const tagConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Tag object to database
     * @param       {Tag}       tag Tag to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (tag) {


        return {

            title           :tag.title,
            gradientColors  :tag.gradientColors,
            image           :tag.image,
            gallery         :tag.gallery,
            headline        :tag.headline,
            id              :tag.id,
            term            :tag.term,
            category        :tag.category.getCategory(),
            reference       :tag.reference,
            origin          :tag.origin


        }
    },




    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into Tag object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {Tag}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Tag(
            data.title,
            data.gradientColors,
            data.image,
            data.gallery,
            data.headline,
            data.id,
            data.term,
            Category(data.category),
            data.reference,
            data.origin
        );
    }
}
  
export default Tag;