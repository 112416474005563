
/**
 * Class to create a QuestionnaireBodyObject object
 */
 class QuestionnaireBodyObject {
    /**
     *@param {string}           color   - Color of the text for questionnaire body object
     *@param {string}           type    - Type of text for questionnaire body object
     *@param {Array<string>}    value   - List of text values to create questionnaire body object
     */

    constructor(color, type, value) {

        /**
         *@property {string}        color   - Color of the text for questionnaire body object
         *@property {string}        type    - Type of text for questionnaire body object
         *@property {Array<string>} value   - List of text values to create questionnaire body object
         */

        this.color  = color;
        this.type   = type;
        this.value  = value;

    }

    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of a QuestionnaireBodyObject
     * @returns     {Object}
     */

    ToDict() {
        return {
            "color" : this.color,
            "type"  : this.type,
            "value" : this.value,
        };
    }


        /**
* Function MapToDict
* @property {Function}                  MapToDict Creates a dictionary format of a a list of QuestionnaireBodyObjects
* @property {Array<QuestionnaireBodyObjects>}                    The data of the QuestionnaireBodyObject
* @static
* @returns  {Array<Object>}   The map object for export
*/

static MapToDict(data) {

    if (data == null) {
        return data
    }

    console.log(`Called FromDict in QuestionnaireBodyObject`);
    return data.map((cbo) => {
        return cbo.ToDict()
    })

}


    /**
* Function FromDict
* @property {Function}                  FromDict Returns a dictionary format of a QuestionnaireBodyObject
* @property {Object}                    The data of the QuestionnaireBodyObject
* @static
* @returns  {QuestionnaireBodyObject}   The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        console.log(`Called FromDict in QuestionnaireBodyObject`);

        return new QuestionnaireBodyObject(
            data.color,
            data.type,
            data.value);
    }
}

/**
 * A QuestionnaireBodyObject Object
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} QuestionnaireBodyObjectConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type QuestionnaireBodyObjectConverter
 */

export const questionnaireBodyObjectConverter = {

    /**
     * Function toFirestore
     * @property    {Function}      toFirestore Function to send QuestionnaireBodyObject object to database
     * @param       {QuestionnaireBodyObject}  questionnaireBodyObject QuestionnaireBodyObject to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (questionnaireBodyObject) {
        return {

            color   : questionnaireBodyObject.color,
            type    : questionnaireBodyObject.type,
            value   : questionnaireBodyObject.value,

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into QuestionnaireBodyObject object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {QuestionnaireBodyObject}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new QuestionnaireBodyObject(
            data.color,
            data.type,
            data.value,
        );
    }
}

export default QuestionnaireBodyObject