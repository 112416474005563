/**
 * Feature: A Feature class object will have the information of which features that a user is subscribed to. The features will be loaded with the user information and enable access to specified features of Corps Diamond Services.
 */
 export default class Feature {
    /**
     *@param {string}   FUID    - UID of feature
     *@param {boolean}  enabled - Flag indicating the status of the user feature
     *@param {number}   tag     - Tag used to assist in prioritization of the UI during initialization
     */

    constructor(FUID, enabled, tag) {

        /**
         *@property {string}    FUID - UID of feature
         *@property {boolean}   enabled - Flag indicating the status of the user feature
         *@property {number}    tag - Tag used to assist in prioritization of the UI during initialization
         */

        this.FUID       = FUID;
        this.enabled    = enabled;
        this.tag        = tag;

    }
    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of a Feature
     * @returns     {Object}
     */

    ToDict() {
        return {
            "FUID"      : this.FUID,
            "enabled"   : this.enabled,
            "tag"       : this.tag
        };
    }

    /**
* Function FromDict
* @property {Function}  FromDict Returns a dictionary format of a
* @property {Object}    data The data from database
* @static
* @returns  {Feature}   The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Feature`);

        return new Feature(
            data.FUID,
            data.enabled,
            data.tag);

    }
}

/**
 * A Feature|Service provided by Corps Diamond
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} FeatureConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type FeatureConverter
 */

export const featureConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Feature object to database
     * @param       {Feature}   feature Feature to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (feature) {
        return {

            FUID    : feature.FUID,
            enabled : feature.enabled,
            tag     : feature.tag

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into Feature object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {Feature}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Feature(
            data.FUID,
            data.enabled,
            data.tag
        );
    }
}

/**
 * A Feature|Service provided by Corps Diamond. A user will initially contain two features enabling basic access to the Corps Diamond System, and access to the users account profile information.
 * @typedef {Object<string, Feature>} FeaturesJSON a JSON Features collection
 */

/**
 * @type {FeaturesJSON}
 */

export const featuresJSON = {
    luckeyLogic : new Feature("Luckey Logic", true, 0),
    editProfile : new Feature("Edit Profile", true, 1)
}

/**
 * Features: This class simply stores multiple Corps Diamond Features.
 */
export class Features {
    /**
     *@param {FeaturesJSON} features - A collection of Features
     */

    constructor() {
        this.features = featuresJSON;
    }
    /**
 * Function ToDict
 * @property    {Function}  ToDict Returns a JSON Features collection
 * @returns     {Object}    The return JSON Features collection
 */

    ToDict() {
        var returnFeatures = {}
        for (var key in this.features) {
            if (this.features.hasOwnProperty(key)) {
                returnFeatures[key] = this.features[key].ToDict();
            }
            return returnFeatures;
        }
    }

    /**
* Function FromDict
* @property {Function}  FromDict Returns a dictionary format of a
* @property {Object}    data The data from database
* @static
* @returns  {Features}  The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Features`);

        var returnFeatureMap = {}
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                returnFeatureMap[key] = Feature.FromDict(data[key]);
            }
        }
        return returnFeatureMap;
    }
}
