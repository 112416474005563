import React, { useState }  from 'react';
import ContactHeroSection   from '../components/ContactHeroSection';
import ContactInfoSection   from '../components/ContactInfoSection';
import Sidebar              from '../components/Sidebar';
import { contactObj1 }      from '../components/ContactInfoSection/data';

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <ContactHeroSection />
      <ContactInfoSection {...contactObj1} />
    </>
  );
}


// {
//   return (
//     <>
//       <PageNotFound
//       headline    ="Thank you for your interest!"
//       description ="We are excited that you are interested in our Company. We are almost ready to launch our Contact page providing several ways to contact us! Please feel free to leave your info below and we can let you know as soon as we are ready"
//       actionText  ="Stay Connected" />
//     </>
//   )
// }

export default ContactPage