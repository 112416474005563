import React            from 'react';
import NavbarLimited    from '../components/NavbarLimited';
import Footer           from '../components/Footer';

const LimitedLayout = props => {
  return (
    <>
        <NavbarLimited {...props} />
        {props.children}
        <Footer />
    </>
  )
}

export default LimitedLayout