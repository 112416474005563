import styled from "styled-components";

export const ChartContainer = styled.div`
    margin: 20px;
    padding: 20px;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
`

export const ChartTitle = styled.h3`
    margin-bottom: 20px;
`