import React from 'react'
import { useSelector }  from 'react-redux';
import { Link } from "react-router-dom";
import {SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarTitle,
    SidebarList,
    SidebarListItem,
    MyLineStyle,
    MyTimeline,
    MyTrendingUp,
    MyPermIdentity,
    MyStorefront,
    MyAssessment,
    MyLocalAtm,
    MyDrafts,
    MyFeedback,
    MyForum,
    MyWork,
    MyPieChart,
    MyReceipt
} from '../SidebarAuth/sidebarAuth.elements'


const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser
  });
  
const SidebarAuth = ({ isOpen, toggle }) => {

    const {currentUser} = useSelector(mapStateToProps);

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
            <SidebarMenu>
                    <SidebarTitle>Next Steps</SidebarTitle>
                    <SidebarList>
                        <Link to="/complete-profile" className='link'>
                            <SidebarListItem>
                                <MyTimeline />
                                Complete Profile Setup
                            </SidebarListItem>
                        </Link>
                        <Link to="/link-account" className='link'>
                            <SidebarListItem>
                                <MyTimeline />
                                Account Settings
                            </SidebarListItem>
                        </Link>
                        <Link to="/discovery" className='link'>
                            <SidebarListItem>
                                <MyTimeline />
                                Discovery
                            </SidebarListItem>
                        </Link>
                    </SidebarList>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarTitle>Dashboard</SidebarTitle>
                    <SidebarList>
                        <Link to="/dashboard" className="link">
                            <SidebarListItem>
                                <MyLineStyle />
                                Home
                            </SidebarListItem>
                        </Link>
                        <SidebarListItem>
                            <MyTimeline />
                            Analytics
                        </SidebarListItem>
                        {/* <SidebarListItem>
                            <MyTrendingUp />
                            Sales
                        </SidebarListItem> */}
                    </SidebarList>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarTitle>All Menu</SidebarTitle>
                    <SidebarList>
                        {/* <Link to="/users" className="link"> */}
                            <SidebarListItem>
                                <MyPermIdentity />
                                Users
                            </SidebarListItem>
                        {/* </Link> */}
                        <Link to="/products" className="link">
                            <SidebarListItem>
                                <MyStorefront />
                                Products
                            </SidebarListItem>
                        </Link>
                        <SidebarListItem>
                            <MyAssessment />
                            Reports
                        </SidebarListItem>
                        <SidebarListItem>
                            <MyLocalAtm />
                            Transactions
                        </SidebarListItem>
                    </SidebarList>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarTitle>Connect</SidebarTitle>
                    <SidebarList>
                        <SidebarListItem>
                            <MyDrafts />
                            Mail
                        </SidebarListItem>
                        <SidebarListItem>
                            <MyFeedback />
                            Feedback
                        </SidebarListItem>
                        <SidebarListItem>
                            <MyForum />
                            Messages
                        </SidebarListItem>
                    </SidebarList>
                </SidebarMenu>
                {/* <SidebarMenu>
                    <SidebarTitle>Employees</SidebarTitle>
                    <SidebarList>
                        <SidebarListItem>
                            <MyWork />
                            Manage
                        </SidebarListItem>
                        <SidebarListItem>
                            <MyPieChart />
                            Analytics
                        </SidebarListItem>
                        <SidebarListItem>
                            <MyReceipt />
                            Reports
                        </SidebarListItem>
                    </SidebarList>
                </SidebarMenu> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarAuth
