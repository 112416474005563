import { Link as LinkS }  from 'react-scroll';
import { Link as LinkR }  from 'react-router-dom';
import styled             from 'styled-components';
import { FaBars }         from 'react-icons/fa';

export const Nav = styled.nav`
  background      : -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0.0))), -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.9)), to(transparent));
  background      : linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, transparent 100%);
  height          : 80px;
  margin-top      : -80px;
  display         : flex;
  justify-content : center;
  align-items     : center;
  font-size       : 1rem;
  position        : sticky;
  top             : 0;
  z-index         : 10;
`;

export const NavGradient = styled.nav`
  background      : ${({ scrollnav }) => (scrollnav ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')};
  height          : 80px;
  width           : 100%;
  margin-top      : -80px;
  display         : flex;
  position        : sticky;
  top             : 0;
  z-index         : 9;

  @media screen and (max-width: 1400px) {
    transition    : 0.8s all ease-in-out;
  }
`;

export const NavbarContainer = styled.div`
  display         : flex;
  justify-content : space-between;
  height          : 80px;
  z-index         : 1;
  width           : 100%;
  padding         : 0 24px;
  /* max-width       : 1100px; */
`;

export const NavLogo = styled(LinkR)`
  color           : #fff;
  justify-self    : flex-start;
  cursor          : pointer;
  font-size       : 1.5rem;
  display         : flex;
  align-items     : center;
  margin-left     : -24px;
  font-weight     : bold;
  text-decoration : none;
`;

export const NavLogoImage = styled.img`
height            : 100%;
margin-bottom     : 10px;
`;

export const Hamburger = styled(FaBars)`
  color: ${({ scrollnav }) => (scrollnav ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)')};
`;

export const MobileIcon = styled.div`
  display         : none;

  @media screen and (max-width: 768px) {
    display       : block;
    position      : absolute;
    top           : 0;
    right         : 0;
    transform     : translate(-100%, 60%);
    font-size     : 1.8rem;
    cursor        : pointer;
    color         : ${({ scrollnav }) => (scrollnav ? 'rgba(25,51,86,1)' : 'rgba(103,214,255,1)')};
  }
`;


export const NavMenu = styled.ul`
  display         : flex;
  align-items     : center;
  list-style      : none;
  text-align      : center;
  transform       : translate(-25%);
  margin-right    : -80px;

  @media screen and (max-width: 768px) {
    display       : none;
  }
`;

export const NavItem = styled.li`
  height          : 80px;
  /* width: 100px; */
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinks = styled(LinkS)`
  color: ${({ scrollnav }) => (scrollnav ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)')};
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 10px;
  height: 100%;
  cursor: pointer;

@media screen and (max-width: 1400px) {
  transition    : 0.8s all ease-in-out;
}

  &.active {
    border-bottom: 3px solid #67d6ff;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #67d6ff;
  white-space: nowrap;
  padding: 10px 16px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;







// import React from 'react'
// import { NotificationsNone, Language, Settings } from '@material-ui/icons';
// import { Link as LinkR }  from 'react-router-dom';
// import styled from 'styled-components';

// export const NavbarContainer = styled.div`
//     width: 100%;
//     height: 50px;
//     background-color: #fff;
//     position: sticky;
//     top: 0;
//     z-index: 999;
// `
// export const NavbarWrapper = styled.div`
//     height: 100%;
//     padding: 0px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// `
// export const Logo = styled.span`
//     font-weight: bold;
//     font-size: 30px;
//     color: maroon;
//     cursor: pointer;
// `
// export const TopLeft = styled.div``
// export const TopRight = styled.div`
//     display: flex;
//     align-items: center;
// `

export const IconContainer = styled.div`
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
`
export const IconBadge = styled.span`
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
`
export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`
export const Icon = styled.div`
  position              : absolute;
  top                   : 1.2rem;
  right                 : 1.5rem;
  background            : transparent;
  border                : transparent;
  font-size             : 2rem;
  cursor                : pointer;
  outline               : none;
`;
