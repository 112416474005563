export const homeObj1 = {
  id            : 'about',
  destination   : 'discover',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Why Luckey Logic?',
  headline      : 'Enterprise capability small town customer service',
  description   : 'We create your very own exclusive app that allows you to streamline and enhance all aspects of your business and customer experience from any device and location.',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../images/global.svg').default,
  alt           : 'GlobePins',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj2 = {
  id            : 'discover',
  destination   : 'discover2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Accounts',
  headline      : 'Create accounts for customers and employees',
  description   : 'We provide you the capability to create and store user accounts for your customers and your employees on the web and mobile devices.',
  buttonLabel   : 'Learn More',
  imgStart      : true,
  img           : require('../../images/user-accounts.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj3 = {
  id            : 'discover2',
  destination   : 'signup',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Asset Management',
  headline      : 'Track everything from cars to crayons',
  description   : 'Add some of out out of the box services to your app like AutoTrak Fleet Management, Task Trak, Quik Picks Logistics Management, Assessment Trak, Audit Trak, Train Trak, and much more!',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../images/logistics.svg').default,
  alt           : 'Accounts',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj4 = {
  id            : 'signup',
  destination   : '/signup',
  isScroll      : false,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Lets Start Today',
  headline      : 'Scheduling your consult is  only a click away',
  description   : "Share your vision, and 10 minutes of your time. All you need to do is add your information and you're ready to go.",
  buttonLabel   : 'Sign Up and Start Now',
  imgStart      : true,
  img           : require('../../images/custom-merch.svg').default,
  alt           : 'Papers',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj5 = {
  id            : 'discover',
  destination   : 'home',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Unlimited Potential',
  headline      : 'Create accounts for customers and employees',
  description   : 'We provide you the capability to create and store user accounts for your customers and your employees on the web and mobile devices.',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../images/thought-process.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};