
/**
 * Class to create a LastModified object
 */
 class LastModified {
    /**
     *@param {string} byName - Data last modified by this name
     *@param {string} byUUID - Data last modified by this UUID
     *@param {FirebaseFirestore.Timestamp|Date} date - Data last modified at this time
     *@param {string} state - Type of last modification to data
     */

    constructor(byName, byUUID, date, state) {

        /**
         *@property {string} byName - Data last modified by this name
         *@property {string} byUUID - Data last modified by this UUID
         *@property {FirebaseFirestore.Timestamp| Date} date - Data last modified at this time
         *@property {string} state - Type of last modification to data
         */

        this.byName = byName;
        this.byUUID = byUUID;
        this.date = date;
        this.state = state;

    }

    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of
     * @returns {Object}
     */

    ToDict() {
        return {
            "byName": this.byName,
            "byUUID": this.byUUID,
            "date": this.date,
            "state": this.state
        };
    }

    /**
* Function FromDict
* @property {Function} FromDict Returns a dictionary format of a
* @property {Object} data The data from database
* @static
* @returns {LastModified} The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in LastModified`);

        return new LastModified(
            data.byName,
            data.byUUID,
            data.date,
            data.state);

    }
}

/**
 * A LastModified Object
 * @typedef {FirebaseFirestore.FirestoreDataConverter} LastModifiedConverter
 * @property {Function} toFirestore - Function to send data to database
 * @property {Function} fromFirestore - Function to retrieve data from database
 */

/**
 * @type LastModifiedConverter
 */

const lastModifiedConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send LastModified object to database
     * @param {LastModified} lastModified LastModified to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (lastModified) {
        return {

            byName: lastModified.byName,
            byUUID: lastModified.byUUID,
            date: lastModified.date,
            state: lastModified.state

        }
    },

    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into LastModified object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {LastModified}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new LastModified(
            data.byName,
            data.byUUID,
            data.date,
            data.state
        );
    }
}

export default LastModified;