import {
  FieldValue,
  Firestore,
  serverTimestamp,
  Timestamp }                   from 'firebase/firestore';

import React, {
  useState,
  useEffect }                   from 'react';

import {
  handleSubmitQuestionnaire,
  handleUserDiscovery,
  fetchQuestions }         from '../../firebase/utils';

import Address                  from '../../model/Address';
import Category                 from '../../model/Category';
import Contact                  from '../../model/Contact';
import Email                    from '../../model/Email';
import Name                     from '../../model/Name';
import Phone                    from '../../model/Phone';
import PointOfContact           from '../../model/PointOfContact';
import Questionnaire            from '../../model/Questionnaire.model';
import QuestionnaireBodyObject  from '../../model/QuestionnaireBodyObject';
import Tag                      from '../../model/Tag';

  
import {
  useDispatch,
  useSelector,
  connect }                     from 'react-redux';

import FormInput                from '../Forms/FormInput';

import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormH2,
  // FormLabel,
  // FormLabelLarge,
  // FormInput,
  FormButton,
  Text,
  SmallText,
  Errors}                       from './Discovery.elements';
import { useNavigate }          from 'react-router-dom';
import Section from '../../model/Section';
import QuestionBlock from '../../model/QuestionBlock';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const Discovery = props => {
  const dispatch                      = useDispatch();
  const navigate                      = useNavigate();
  const { currentUser }               = useSelector(mapState);
  const [question1,   setQuestion1]   = useState('');
  const [question2,   setQuestion2]   = useState('');
  const [question3,   setQuestion3]   = useState('');
  const [question4,   setQuestion4]   = useState('');
  const [question5,   setQuestion5]   = useState('');
  const [question6,   setQuestion6]   = useState('');
  const [question7,   setQuestion7]   = useState('');
  const [question8,   setQuestion8]   = useState('');
  const [question9,   setQuestion9]   = useState('');
  const [question10,  setQuestion10]  = useState('');
  const [question11,  setQuestion11]  = useState('');
  const [question12,  setQuestion12]  = useState('');
  const [question13,  setQuestion13]  = useState('');
  const [question14,  setQuestion14]  = useState('');
  const [errors,      setErrors]      = useState([]);
  const [questions,   setQuestions]   = useState([]);
  
  const createQuestionnaireBeta = () => {
    const projectManagementQuestions = [
        new QuestionBlock('Who are your key team members?', 'Who on your team will be the key players in guiding us through a successful build process?', 'Key Players'),
        new QuestionBlock('Who are the people with influence and authority?', 'Who are the people with influence and authority or have a stake in this project? (Big bosses or investors)', 'Individuals with Authority'),
        new QuestionBlock('Who will be the Project Champion?', 'Who will be the Project Champion and has final authority of day to day decisions?', 'Project Champion')
    ];

    const teamRolesQuestions = [
        new QuestionBlock('Content Creators Lead', 'Do you have a lead for Content Creators?', 'Content Creators'),
        new QuestionBlock('Content Editors Lead', 'Do you have a lead for Content Editors?', 'Content Editors'),
        new QuestionBlock('Content Publishers Lead', 'Do you have a lead for Content Publishers?', 'Content Publishers')
    ];

    const organizationDetailsQuestions = [
        new QuestionBlock('Official Name', 'What is the official name of your organization?', 'Official Name'),
        new QuestionBlock('Organization Registration', 'Is your organization registered?', 'Yes/No'),
        new QuestionBlock('Type of Organization', 'What type of organization is this?', '501c3, LLC, S-Corp, etc'),
        new QuestionBlock('Services Provided', 'What services do you provide?', 'Our services are...'),
        new QuestionBlock('Organization Location', 'Do you want to provide a location of your organization?', 'Location of organization'),
        new QuestionBlock('Organization Hours', 'What are the hours of your organization?', 'Hours')
    ];

    const name          = new Name(null, "Miguel", "Angel", "Brown", null);
    const email         = new Email("google.com", "mrmiguelbrown", "personal");
    const phone         = new Phone("personal", 2526481099, "Verizon", true, "cell");
    const address       = new Address("341 Walker Ave.", "Luckey","Ohio", 43443,"United States", "Home");

    const contact       = new Contact(address, email, phone);

    const projectManagementSection = new Section('Project Management', projectManagementQuestions);
    const teamRolesSection = new Section('Team Roles', teamRolesQuestions);
    const organizationDetailsSection = new Section('Organization Details', organizationDetailsQuestions);
    const POC           = new PointOfContact("testUID",null,contact, "Corporate", "testProfileImage", 1, name)

    const questionnaireSections = [projectManagementSection, teamRolesSection, organizationDetailsSection];

    // const questionnaire = new QuestionnaireModel(questionnaireSections);
    const questionnaire = new Questionnaire(POC, questionnaireSections, Category.Development, new Timestamp(), "TestQuestionnaire", new Tag([]), "Test Question here")

    handleSubmitQuestionnaire(questionnaire);
    console.log("Test Questionnaire", questionnaire);
}


  const reset = () => {
    setQuestion1('');
    setQuestion2('');
    setQuestion3('');
    setQuestion4('');
    setQuestion5('');
    setQuestion6('');
    setQuestion7('');
    setQuestion8('');
    setQuestion9('');
    setQuestion10('');
    setQuestion11('');
    setQuestion12('');
    setQuestion13('');
    setQuestion14('');
    setErrors([])
  }

  const createQuestionnaire = () => {
    const name          = new Name(null, "Miguel", "Angel", "Brown", null);
    const email         = new Email("google.com", "mrmiguelbrown", "personal");
    const phone         = new Phone("personal", 2526481099, "Verizon", true, "cell");
    // const street        = new Street("Ave.", "Walker", 341);
    const address       = new Address("341 Walker Ave.", "Luckey","Ohio", 43443,"United States", "Home");
    const contact       = new Contact(address, email, phone);
    const POC           = new PointOfContact("testUID",null,contact, "Corporate", "testProfileImage", 1, name)
    const qbos          = [new QuestionnaireBodyObject("#fff", "title", "This is my test title")]
    const tags          = [new Tag("TagTitle", ['#fff','#000'], "testImg", null, "Test Headline", "TestUid","TestTerm", Category.Development, null, null)]
    const timestamp     = Timestamp.now()//firebase.firestore.FieldValue.serverTimestamp ();
    const questionnaire = new Questionnaire(POC, qbos, Category.Development, timestamp, "TestID",tags, "Test Questionnaire")

    // Questionnaire(POC, [])

    handleSubmitQuestionnaire(questionnaire);
    console.log("Test Questionnaire", questionnaire);
  }

  const handleFormSubmit = async event => {
    event.preventDefault();

    createQuestionnaire();
    createQuestionnaireBeta()
    try {

      const answersArray = [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question8,
        question9,
        question10,
        question11,
        question12,
        question13,
        question14]

        console.log('Answers to submit', answersArray)
      await handleUserDiscovery(answersArray);

      reset();

    } catch(error) {
  // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode === 'auth/weak-password') {
      alert('The password is too weak.\nPassword should be at least 6 characters.');
    } else {
      alert(`Default: ${errorMessage}`);
    }
    console.log(error);
};

  }



  useEffect(() => {
    if (currentUser.accessOptionSet !== 1) {
      reset();
      navigate('/dashboard');
    }

    const fetch = async () => {
      const fetchedQuestions = await fetchQuestions();
      setQuestions(fetchedQuestions);
      console.log(fetchedQuestions);
    };
    fetch();

  }, [currentUser, navigate]);


    return (
      <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleFormSubmit}>
              <FormH1>Discovery</FormH1>
              <FormH2>Expectations</FormH2>
              <Text>We are going to take on this project together and there are several very important steps to complete to ensure that your final product is everything you wanted and that we have met all of your expectations. Don’t worry, we will be working right beside you during this process building off of your vision. We will start with the discovery process.</Text>
              <FormH2>Project Management</FormH2>
              <SmallText>
              We would like to establish the project management. Your system should work no matter how large your organization is. If one of the following questions do not apply to you, just respond with N/A. If there are any issues with the responses don’t worry, we will be sure to reach out to you.
              </SmallText>
              <Errors>
              {errors.length > 0 && (
                <ul>
                  {errors.map((err, index) => {
                    return (
                      <li key={index}>
                        {err}
                      </li>
                    )
                  })}
                </ul>
              )}
              </Errors>

              <FormInput
              headline        ='Who are your key team members?'
              label           ='Who on your team will be the key players in guiding us through a successful build process?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question1'
              value           ={question1}
              placeholder     ='Key Players'
              handleChange    ={e => setQuestion1(e.target.value)}
              required />

              <FormInput
              label           ='Who are the people with influence and authority or have a stake in this project? (Big bosses or investors)'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question2'
              value           ={question2}
              placeholder     ='Individuals with Authority'
              handleChange    ={e => setQuestion2(e.target.value)}
              required />

              <FormInput
              label           ='Who will be the Project Champion and has final authority of day to day decisions?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question3'
              value           ={question3}
              placeholder     ='Project Champion'
              handleChange    ={e => setQuestion3(e.target.value)}
              required />

              <FormInput
              headline        ='Do you have a lead for the following roles?'
              label           ='Content Creators?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question4'
              value           ={question4}
              placeholder     ='Content Creators'
              handleChange    ={e => setQuestion4(e.target.value)}
              required />

              <FormInput
              label           ='Content Editors?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question5'
              value           ={question5}
              placeholder     ='Content Editors'
              handleChange    ={e => setQuestion5(e.target.value)}
              required />

              <FormInput
              label           ='Content Publishers?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question6'
              value           ={question6}
              placeholder     ='Content Publishers'
              handleChange    ={e => setQuestion6(e.target.value)}
              required />

              <FormInput
              headline        ='Who will be your site managers who will keep your site running after we have finished development?'
              label           ='Web Managers?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question7'
              value           ={question7}
              placeholder     ='Web Managers'
              handleChange    ={e => setQuestion7(e.target.value)}
              required />

              <FormInput
              label           ='Tech Support Managers?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question8'
              value           ={question8}
              placeholder     ='Tech Support Managers'
              handleChange    ={e => setQuestion8(e.target.value)}
              required />

              <FormInput
              headline        ='Tell us about your organization. Help us understand who you are and what services you provide.'
              label           ='What is the official name of your organization?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question9'
              value           ={question9}
              placeholder     ='Official Name'
              handleChange    ={e => setQuestion9(e.target.value)}
              required />

              <FormInput
              label           ='Is your organization registered?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question10'
              value           ={question10}
              placeholder     ='Is your organization registered?'
              handleChange    ={e => setQuestion10(e.target.value)}
              required />

              <FormInput
              label           ='What type of organization is this?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question11'
              value           ={question11}
              placeholder     ='501c3, LLC, S-Corp, etc'
              handleChange    ={e => setQuestion11(e.target.value)}
              required />

              <FormInput
              label           ='What services do you provide?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question12'
              value           ={question12}
              placeholder     ='Our services are...'
              handleChange    ={e => setQuestion12(e.target.value)}
              required />

              <FormInput
              label           ='Do you want to provide a location of your organization?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question13'
              value           ={question13}
              placeholder     ='Location of organization'
              handleChange    ={e => setQuestion13(e.target.value)}
              required />

              <FormInput
              label           ='What are the hours of your organization?'
              type            ='text'
              autoCapitalize  ='words'
              name            ='question14'
              value           ={question14}
              placeholder     ='Hours'
              handleChange    ={e => setQuestion14(e.target.value)}
              required />

              <FormButton type    ='submit'>Continue</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
    )
}

export default Discovery;
