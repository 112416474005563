import React from 'react';
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroP } from './index.elements';

function ContactHeroSection() {
  return (
    <HeroContainer id='contact'>
      <HeroBg />
      <HeroContent>
        <HeroH1>Contact Luckey Logic</HeroH1>
        <HeroP>Reach out to us with your questions, feedback, or just to say hello!</HeroP>
      </HeroContent>
    </HeroContainer>
  );
}

export default ContactHeroSection;
