 /**
  * TODO:
  * 
  * Test for prefix and suffix. If empty, return null values
  * 
  * Update Email Label and placeholder text.
  * 
  * Add displayname to export for database
  * 
  * Populate Email from userAuth and remove email input
  */

import {
    FieldValue,
    Firestore,
    serverTimestamp,
    Timestamp }                 from 'firebase/firestore';
import { useDispatch, useSelector }   from 'react-redux';

import React, {useEffect, useState }       from 'react';
import {
    handleSubmitQuestionnaire,
    handleUserDiscovery,
    handleUpdateUserProfile, 
    getCurrentUser}   from '../../firebase/utils'

// import Address                  from '../../model/Address';
// import Category                 from '../../model/Category';
// import Contact                  from '../../model/Contact';
// import Email                    from '../../model/Email';
// import LastModified             from '../../model/LastModified';
// import Name                     from '../../model/Name';
// import Phone                    from '../../model/Phone';
// import PointOfContact           from '../../model/PointOfContact';
// import Questionnaire            from '../../model/Questionnaire.model';
// import QuestionnaireBodyObject  from '../../model/QuestionnaireBodyObject';
// import Street                   from '../../model/Street';
// import Tag                      from '../../model/Tag';
// import User                     from '../../model/User';

import FormInput                from '../Forms/FormInput';
import FormCheckbox             from '../Forms/FormCheckbox';

import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormH2,
  FormButton,
  Text,
  Errors,
  FormSelect,
  FormSelectWrap,
  FormSelectLabel,
} from './FinishProfile.elements';

import { useNavigate } from 'react-router-dom';
import { completeUserProfileStart } from '../../redux/User/user.actions';
// import FormCheckbox from '../Forms/FormCheckbox';


const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const FinishProfile = props => {

  const dispatch                                = useDispatch();
  const { currentUser }                         = useSelector(mapState);
  const navigate                                = useNavigate();
  const userAuth = getCurrentUser()

    //Name
  const [prefix,    setPrefix]              = useState('');
  const [first,     setFirst]               = useState('');
  const [middle,    setMiddle]              = useState('');
  const [last,      setLast]                = useState('');
  const [suffix,    setSuffix]              = useState('');

  // Email
  // const [domain,    setDomain]              = useState('');
  const [email,     setEmail]               = useState('');
  // const [label,     setLabel]               = useState('');


  // Phone
  const [phoneLabel,    setPhoneLabel]      = useState('');
  const [number,        setNumber]          = useState('');
  const [provider,      setProvider]        = useState('');
  const [textMe,        setTextMe]          = useState(false);
  const [type,          setType]            = useState('');

  // // Street
  // const [addrType,      setAddrType]        = useState('');
  // const [streetName,    setStreetName]      = useState('');
  // const [streetNumber,  setStreetNumber]    = useState('');

  // Address
  const [street,        setStreet]          = useState('');
  const [city,          setCity]            = useState('');
  const [state,         setState]           = useState('');
  const [zip,           setZip]             = useState('');
  const [country,       setCountry]         = useState('');
  const [addressLabel,  setAddressLabel]    = useState('');
  const [errors,        setErrors]          = useState([]);


  useEffect(() => {
    if (currentUser.accessOptionSet !== 0) {
      reset();
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const reset = () => {
    setPrefix('');
    setFirst('');
    setMiddle('');
    setLast('');
    setSuffix('');
    // setDomain('');
    setEmail('');
    // setLabel('');
    setPhoneLabel('');
    setNumber('');
    setProvider('');
    setTextMe('');
    setType('');
    // setAddrType('');
    // setStreetName('');
    // setStreetNumber('');
    setStreet('')
    setCity('');
    setState('');
    setZip('');
    setCountry('');
    setAddressLabel('');
    setErrors([]);

  }

//   const createQuestionnaire = () => {
//     const name          = new Name(null, "Miguel", "Angel", "Brown", null);
//     const email         = new Email("google.com", "mrmiguelbrown", "personal");
//     const phone         = new Phone("personal", 2526481099, "Verizon", true, "cell");
//     const street        = new Street("Ave.", "Walker", 341);
//     const address       = new Address(street, "Luckey","Ohio", 43443,"United States", "Home");
//     const contact       = new Contact(address, email, phone);
//     const POC           = new PointOfContact("testUID",null,contact, "Corporate", "testProfileImage", 1, name)
//     const qbos          = [new QuestionnaireBodyObject("#fff", "title", "This is my test title")]
//     const tags          = [new Tag("TagTitle", ['#fff','#000'], "testImg", null, "Test Headline", "TestUid","TestTerm", Category.Development, null, null)]
//     const timestamp     = Timestamp.now()//firebase.firestore.FieldValue.serverTimestamp ();
//     const questionnaire = new Questionnaire(POC, qbos, Category.Development, timestamp, "TestID",tags, "Test Questionnaire")

//     handleSubmitQuestionnaire(questionnaire);
//     console.log("Test Questionnaire", questionnaire);
//   }

  const handleFormSubmit = async event => {
    event.preventDefault();

    // createQuestionnaire();
    try {


        if (prefix === "") {
          setPrefix(null);
        }

        if (suffix === "") {
          setSuffix(null);
        }

        console.log('Profile Information to update')
        dispatch(completeUserProfileStart({
          prefix,
          first, 
          middle,
          last,  
          suffix,
          email,
          phoneLabel,
          number,
          provider,
          textMe,
          type,
          street,
          city,        
          state,       
          zip,         
          country,     
          addressLabel}))
          console.log(`Post profile information update`)

      // await handleUpdateUserProfile(submitUser);


    } catch(error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode === 'auth/weak-password') {
      alert('The password is too weak.\nPassword should be at least 6 characters.');
    } else {
      alert(`Default: ${errorMessage}`);
    }
    console.log(error);
};

  }

    return (
      <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleFormSubmit}>
              <FormH1>User Profile</FormH1>
              <FormH2>Basic Information</FormH2>
              <Text>Next we just need you to fill out your basic profile information.</Text>
              <Errors>
              {errors.length > 0 && (
                <ul>
                  {errors.map((err, index) => {
                    return (
                      <li key={index}>
                        {err}
                      </li>
                    )
                  })}
                </ul>
              )}
              </Errors>
{/* 
              <FormInput
              headline        ='Name'
              label           ='Prefix'
              type            ='text'
              autoCapitalize  ='words'
              name            ='prefix'
              value           ={prefix}
              placeholder     ='Prefix'
              handleChange    ={e => setPrefix(e.target.value)} /> */}

              <FormInput
              headline        ='Name'
              label           ='First Name **'
              type            ='text'
              autoCapitalize  ='words'
              name            ='first'
              value           ={first}
              placeholder     ='First Name'
              handleChange    ={e => setFirst(e.target.value)}
              required />

              <FormInput
              label           ='Middle Name'
              type            ='text'
              autoCapitalize  ='words'
              name            ='middle'
              value           ={middle}
              placeholder     ='Middle Name'
              handleChange    ={e => setMiddle(e.target.value)} />

              <FormInput
              label           ='Last Name **'
              type            ='text'
              autoCapitalize  ='words'
              name            ='last'
              value           ={last}
              placeholder     ='Last Name'
              handleChange    ={e => setLast(e.target.value)}
              required />

              {/* <FormInput
              label           ='Suffix'
              type            ='text'
              autoCapitalize  ='words'
              name            ='suffix'
              value           ={suffix}
              placeholder     ='Suffix'
              handleChange    ={e => setSuffix(e.target.value)} /> */}

{/* 
              <FormInput
              headline          ='Email'
              label           ='Domain'
              type            ='text'
              autoCapitalize  ='words'
              name            ='domain'
              value           ={domain}
              placeholder     ='gmail.com, yahoo.com, etc.'
              handleChange    ={e => setDomain(e.target.value)}
              required /> */}

              {/* <FormInput
              label           ='Email Name'
              type            ='text'
              autoCapitalize  ='words'
              name            ='email'
              value           ={email}
              placeholder     ='Email with out domain'
              handleChange    ={e => setEmail(e.target.value)}
              required /> */}

{/* 
              <FormInput
              label           ='Email Label'
              type            ='text'
              autoCapitalize  ='words'
              name            ='label'
              value           ={label}
              placeholder     ='personal, work, etc.'
              handleChange    ={e => setLabel(e.target.value)}
              required /> */}

              <FormInput
              headline          ='Phone Information'
              label             ='Phone Label'
              type              ='text'
              autoCapitalize    ='words'
              name              ='phoneLabel'
              value             ={phoneLabel}
              placeholder       ='personal, work, etc.'
              handleChange      ={e => setPhoneLabel(e.target.value)} />

              <FormInput
              label           ='Phone Number'
              type            ='text'
              autoCapitalize  ='words'
              name            ='number'
              value           ={number}
              placeholder     ='Phone Number'
              handleChange    ={e => setNumber(e.target.value)} />

              {/* <FormInput
              label           ='Phone Provider'
              type            ='text'
              autoCapitalize  ='words'
              name            ='provider'
              value           ={provider}
              placeholder     ='Verizon, AT&T, etc'
              handleChange    ={e => setProvider(e.target.value)} /> */}

              {/* <FormInput
              label           ='Allow Text Message Notifications'
              type            ='text'
              autoCapitalize  ='words'
              name            ='textMe'
              value           ={textMe}
              placeholder     ='Allow Text Messages'
              handleChange    ={e => setTextMe(e.target.value)} /> */}

{/* FormCheckbox = ({ handleChange, label, headline, checked, ...otherProps }) */}

              <FormCheckbox
                id='textMe'
                headline="Allow Text Message Notifications"
                label="Allow Text Messages"
                checked={textMe}
                // disabled={type !== 'Mobile'}
                handleChange={e => {
                  console.log(e)
                  console.log(e.target.value)
                  console.log(e.target.checked)
                  setTextMe(e.target.checked)
                }
              }
              />

              {/* <FormInput
              label           ='Type of phone'
              type            ='text'
              autoCapitalize  ='words'
              name            ='type'
              value           ={type}
              placeholder     ='Cell, Landline, etc'
              handleChange    ={e => setType(e.target.value)} /> */}
              <FormSelectWrap>
                <FormSelectLabel>Phone Type</FormSelectLabel>
                <FormSelect value={type} onChange={e => setType(e.target.value)} required>
                <option value="">Select Phone Type</option>
                <option value="Mobile">Mobile</option>
                <option value="Land Line">Land Line</option>
                <option value="Fax">Fax</option>
                {/* Add more options if needed */}
              </FormSelect>
              </FormSelectWrap>
              
              {/* <FormInput
              headline          ='Address Information'
              label             ='Address Type'
              type              ='text'
              autoCapitalize    ='words'
              name              ='addrType'
              value             ={addrType}
              placeholder       ='Road, Street, Avenue, etc.'
              handleChange      ={e => setAddrType(e.target.value)} /> */}


            <FormInput
              headline        ='Address Information'
              label           ='Street Address'
              type            ='text'
              autoCapitalize  ='words'
              name            ='street'
              value           ={street}
              placeholder     ='Street Address'
              handleChange    ={e => setStreet(e.target.value)}
              required />

{/* <FormInput
              label           ='Street Number'
              type            ='text'
              autoCapitalize  ='words'
              name            ='streetNumber'
              value           ={streetNumber}
              placeholder     ='Street Number'
              handleChange    ={e => setStreetNumber(e.target.value)}
              required /> */}

<FormInput
              label           ='City'
              type            ='text'
              autoCapitalize  ='words'
              name            ='city'
              value           ={city}
              placeholder     ='City'
              handleChange    ={e => setCity(e.target.value)}
              required />

              <FormSelectWrap>
                <FormSelectLabel>State</FormSelectLabel>
                <FormSelect value={state} onChange={e => setState(e.target.value)} required>
              <option value="">Select State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
              <option value="AS">American Samoa</option>
              <option value="GU">Guam</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="PR">Puerto Rico</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="VI">U.S. Virgin Islands</option>
            </FormSelect>

              </FormSelectWrap>

{/* <FormInput
              label           ='State'
              type            ='text'
              autoCapitalize  ='words'
              name            ='state'
              value           ={state}
              placeholder     ='State'
              handleChange    ={e => setState(e.target.value)}
              required /> */}

<FormInput
              label           ='ZIP Code'
              type            ='text'
              autoCapitalize  ='words'
              name            ='zip'
              value           ={zip}
              placeholder     ='ZIP Code'
              handleChange    ={e => setZip(e.target.value)}
              required />

{/* <FormInput
              label           ='Country'
              type            ='text'
              autoCapitalize  ='words'
              name            ='country'
              value           ={country}
              placeholder     ='Country'
              handleChange    ={e => setCountry(e.target.value)}
              required /> */}

            <FormSelectWrap>
              <FormSelectLabel>Country</FormSelectLabel>
              <FormSelect value={country} onChange={e => setCountry(e.target.value)} required>
                <option value="United States">United States</option>
                {/* Add more countries */}
              </FormSelect>
            </FormSelectWrap>


<FormInput
              label           ='Address Label'
              type            ='text'
              autoCapitalize  ='words'
              name            ='addressLabel'
              value           ={addressLabel}
              placeholder     ='Address Label'
              handleChange    ={e => setAddressLabel(e.target.value)}
              required />

              <FormButton type    ='submit'>Continue</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
    )
}

export default FinishProfile;
