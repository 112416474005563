// export const firebaseConfig = {
//   apiKey            : "AIzaSyCGT-WOrT_CUZbqc3UXdUObJ1xaJr3rclw",
//   authDomain        : "luckey-logic-98a9b.firebaseapp.com",
//   projectId         : "luckey-logic-98a9b",
//   storageBucket     : "luckey-logic-98a9b.appspot.com",
//   messagingSenderId : "647704961",
//   appId             : "1:647704961:web:559eb226bef8606c28b880",
//   measurementId     : "G-1E2NBQTNVJ"
// };
export const firebaseConfig = {
  apiKey            : "AIzaSyDz6glvgVeVvjUkKUSmMMu7DUlNuG4p_e4",
  authDomain        : "luckey-logic-cff63.firebaseapp.com",
  projectId         : "luckey-logic",
  storageBucket     : "luckey-logic.appspot.com",
  messagingSenderId : "49029051358",
  appId             : "1:49029051358:web:35fd5b6fb4e3046f33e34f"
};