import Contact from "./Contact";
import Name from "./Name";

/**
* Class to create a PointOfContact object
*/
class PointOfContact {
//@param {AfflMap}   [affl]          - Object containing all of the users affiliations
    /**
    *@param {string}        UUID            - User Unique Identifier
	*@param {Array<string>} [affl]          - Object containing all of the users affiliations
	*@param {Contact}       contact         - Users Contact information
	*@param {string}        [group]         - Group within a users Affiliate
	*@param {string}        photoURL        - Users profile image file name
	*@param {number}        [subGroup]      - Subgroup within the group that the user belongs to (Optional)
	*@param {Name}          name            - The users name information
    */

    constructor(UUID, affl, contact, group, photoURL, subGroup, name) {

        /**
        *@property { string }       UUID            - User Unique Identifier
		*@property {Array<string>}  [affl]          - Object containing all of the users affiliations
		*@property { Contact }      contact         - Users Contact information
		*@property { string }       [group]         - Group within a users Affiliate
		*@property { string }       photoURL        - Users profile image file name
		*@property { number }       [subGroup]      - Subgroup within the group that the user belongs to (Optional)
		*@property { Name }         name            - The users name information
        */

        this.UUID           = UUID;
        this.affl           = affl;
        this.contact        = contact;
        this.group          = group;
        this.photoURL       = photoURL;
        this.subGroup       = subGroup;
        this.name           = name;

    }
    /**
     * Function ToDict
     * @property    {Function}  ToDict Returns a dictionary format of a PointOfContact
     * @returns     {Object}    The map object for export
     */

    ToDict() {
        console.log("PointOfContact ToDict() values:", this.UUID, this.contact.ToDict(), this.group, this.photoURL, this.subGroup, this.name.ToDict())
        // console.log("affl.ToDict():", this.affl.ToDict())
        console.log("typeof affl", typeof this.affl, this.affl)
        return {
            "UUID"          : this.UUID,
            "affl"          : this.affl,
            "contact"       : this.contact      .ToDict(),
            "group"         : this.group,
            "photoURL"      : this.photoURL,
            "subGroup"      : this.subGroup,
            "name"          : this.name         .ToDict()
        };
    }




    /**
    * Function FromDict
    * @property {Function} FromDict Returns a dictionary format of a PointOfContact object
    * @property {Object} data The data from database
    * @static
    * @returns  {PointOfContact} The database object to convert to PointOfContact
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        // console.log(`Called FromDict in PointOfContact`);

        return new PointOfContact(
            data.UUID,
            data.affl,
            Contact.FromDict(data.contact),
            data.group,
            data.photoURL,
            data.subGroup,
            Name.FromDict(data.name));
    }

}

/**
* A PointOfContact
* @typedef  {FirebaseFirestore.FirestoreDataConverter} PointOfContactConverter
* @property {Function} toFirestore      - Function to send data to database
* @property {Function} fromFirestore    - Function to retrieve data from database
* @exports
*/

/**
* @type PointOfContactConverter
*/

export const pointOfContactConverter = {

    /**
     * Function toFirestore
     * @property    {Function}          toFirestore Function to send PointOfContact object to database
     * @param       {PointOfContact}    pointOfContact PointOfContact to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (pointOfContact) {
        return {
            UUID            : pointOfContact.UUID,
            affl            : pointOfContact.affl,
            contact         : pointOfContact.contact        .ToDict(),
            group           : pointOfContact.group,
            photoURL        : pointOfContact.photoURL,
            subGroup        : pointOfContact.subGroup,
            name            : pointOfContact.name           .ToDict()
        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into PointOfContact object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {PointOfContact}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        console.log(`Called FromFirestore in pointOfContactConverter`);

        return new PointOfContact(
            data.UUID,
            data.affl,
            Contact.FromDict(data.contact),
            data.group,
            data.photoURL,
            data.subGroup,
            Name.FromDict(data.name));
    }
}

export default PointOfContact;