import React, { useState }  from "react";
import Feedback             from "../../model/Feedback";
import FormInput            from "../Forms/FormInput";
import FormSelect           from "../Forms/FormSelect";
import {
  Container,
  Header,
  Footer,
  Entity,
  Inner,
  Question,
  Text,
  FeedbackForm, 
  SubmitButton,
  FAQFeedbackContainer,
  FAQFeedbackWrapper}            from "./faq.elements";
import { handleSubmitFAQFurtherInquiryFeedback } from "../../firebase/utils";

const QuestionContext = React.createContext();
export default function Banner({ children, ...restProps }) {
  return (
    <Container {...restProps}>
      <Inner>{children}</Inner>
    </Container>
  );
}
Banner.Header = function BannerHeader({ children, ...restProps }) {
  return <Header {...restProps}> {children}</Header>;
};

Banner.Footer = function BannerFooter({ children, ...restProps }) {
    return <Footer {...restProps}> {children}</Footer>;
}

Banner.Entity = function BannerEntity({ children, ...restProps }) {
  const [open, setOpen] = useState(false);
  return (
    <QuestionContext.Provider value={{ open, setOpen }}>
      <Entity {...restProps}> {children}</Entity>
    </QuestionContext.Provider>
  );
};
Banner.Question = function BannerHeader({ children, ...restProps }) {
  const { open, setOpen } = React.useContext(QuestionContext);

  return (
    <Question onClick={() => setOpen((open) => !open)} {...restProps}>
      {children}
      {open ? <h3>^</h3> : <h3>+</h3>}
    </Question>
  );
};
Banner.Text = function BannerText({ children, ...restProps }) {
  const { open } = React.useContext(QuestionContext);
  return open ? <Text {...restProps}>{children}</Text> : null;
};

Banner.ContactForm = function BannerContactForm({ children, ...restProps }) {


  const [email,         setEmail]         = useState('');
  const [name,          setName]          = useState('');
  const [contactMethod, setContactMethod] = useState([]);
  const [comments,      setComments]      = useState('');
  const [isSubmitted,   setIsSubmitted]   = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var feedback = new Feedback("", email, name, [comments], contactMethod, "FAQ Further Inquiry")
    // Implementation for form submission, e.g., API call
    handleSubmitFAQFurtherInquiryFeedback(feedback)
    .then(()=>{
        setIsSubmitted(true)
        reset()
    })
    console.log("Feedback submitted");
};

  const reset = () => {
      setEmail('');
      setName('');
      setContactMethod([]);
      setComments('');
    }

    const handleContactMethodChange = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
      setContactMethod(selectedOptions);
  };

  return (
    <FAQFeedbackContainer>
      <FAQFeedbackWrapper>
        <FeedbackForm onSubmit={handleSubmit}>
          <FormInput
          label           ='Name'
          type            ='text'
          autoCapitalize  ='words'
          name            ='name'
          value           ={name}
          placeholder     ='Name'
          handleChange    ={e => setName(e.target.value)}
          required />

          <FormInput
          label           ='Email'
          type            ='email'
          name            ='email'
          value           ={email}
          placeholder     ='Email'
          handleChange    ={e => setEmail(e.target.value)}
          required />


          {/* <FormSelect
          label           ='Select Best Method of Contact'
          name            ='contactMethod'
          value           ={contactMethod}
          placeholder     ='Select Best Method of Contact'
          handleChange    ={handleContactMethodChange}
          multiple
          required >
            <option value="" disabled>Select Default Contact Method</option>
            <option value="phone"   >Phone    </option>
            <option value="text"    >Text     </option>
            <option value="email"   >Email    </option>
            <option value="telegram">Telegram </option>
          </FormSelect> */}

          
          <FormInput
          label           ="Ask your question here."
          type            ='text'
          autoCapitalize  ='sentences'
          name            ='comments'
          value           ={comments}
          placeholder     ='Ask your question here.'
          handleChange    ={e => setComments(e.target.value)}
          required />
          <SubmitButton type="submit" disabled={isSubmitted}>
            {isSubmitted ? "Thank You For Your Question" : "Submit Question"}
          </SubmitButton>
        </FeedbackForm>
      </FAQFeedbackWrapper>
    </FAQFeedbackContainer>
  )
}