import React          from 'react';
import Directory from '../components/Directory';

function DirectoryPage() {

  return (
    <>
      <Directory />
    </>
  );
}

export default DirectoryPage;