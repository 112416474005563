// index.elements.js
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding: 40px;
  background: #f9f9f9;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 2px solid #193356;  // Updated to the correct accent color.
`;

export const Name = styled.h3`
  font-size: 24px;
  color: #010606;
  margin-bottom: 5px;
`;

export const Description = styled.p`
  font-size: 18px;
  color: #010606;
  margin-bottom: 5px;
  max-width: 440px;
  text-align: center;
`;

export const JobTitle = styled.p`
  font-size: 20px;
  color: #010606;
  margin-bottom: 5px;
`;

export const CityState = styled.p`
  font-size: 20px;
  color: #010606;
  margin-bottom: 10px;
`;

export const LinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  a {
    font-size: 18px;
    padding: 10px 15px;
    background: #193356; // Updated to the correct accent color.
    color: #f9f9f9;
    border: none;
    border-radius: 50px;
    margin-right: 10px;
    text-decoration: none;

    &:hover {
      background: #67d6ff;
      color: #010606;
    }
  }
`;

export const SaveButton = styled.button`
  padding: 10px 22px;
  margin-bottom: 10px;
  border-radius: 50px;
  background: #193356; // Updated to the correct accent color.
  color: #f9f9f9;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #67d6ff;
    color: #010606;
  }
`;
