import firebase                         from    'firebase/compat/app';
import                                          'firebase/compat/firestore';
import { 
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs }                           from    'firebase/firestore'
import                                          'firebase/compat/auth';
import { firebaseConfig }               from    './config';

import {
    getAuth,
    linkWithPopup,
    linkWithCredential,
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider}                from    'firebase/auth';
import { questionnaireConverter }       from    '../model/Questionnaire.model';

import {
    User,
    userConverter }                     from    '../model/User';
import Prospect, {
    prospectConverter }                 from    '../model/Prospect';
import Feedback, { feedbackConverter }            from    '../model/Feedback';


firebase.initializeApp(firebaseConfig);

export const auth                   = firebase.auth();
export const linkPopup              = linkWithPopup;
export const firestore              = firebase.firestore();
// export const getAuth             = getAuth()


export const googleProvider         = new   GoogleAuthProvider();
export const facebookProvider       = new   FacebookAuthProvider();
export const twitterProvider        = new   TwitterAuthProvider();
export const emailProvider          = new   EmailAuthProvider();

export const staticGoogleProvider   =       GoogleAuthProvider;
export const staticFacebookProvider =       FacebookAuthProvider;
export const staticTwitterProvider  =       TwitterAuthProvider;
export const staticEmailProvider    =       EmailAuthProvider;

googleProvider.setCustomParameters  ({ prompt: 'select_account' });

export const signInWithFacebook     = () => auth.signInWithPopup(facebookProvider);
export const signInWithTwitter      = () => auth.signInWithPopup(twitterProvider);
export const linkWithGoogle         = () =>     handleLinkProvider(googleProvider,      GoogleAuthProvider);
export const linkWithFacebook       = () =>     handleLinkProvider(facebookProvider,    FacebookAuthProvider);
export const linkWithTwitter        = () =>     handleLinkProvider(twitterProvider,     TwitterAuthProvider);
export const linkWithPassword       = () =>     handleLinkProvider(emailProvider,       EmailAuthProvider);

export const ProviderOptionSet = { 
    'password'      : 1 << 0,
    'phone'         : 1 << 1,
    'google.com'    : 1 << 2,
    'apple.com'     : 1 << 3,
    'yahoo.com'     : 1 << 4,
    'facebook.com'  : 1 << 5,
    'twitter.com'   : 1 << 6,
    'microsoft.com' : 1 << 7
 }

 export const getProviderOptionSet = (userAuth) => {
    return userAuth.providerData.map(({ providerId }) => {
        return ProviderOptionSet[`${providerId}`]
    }).reduce((a, b) => a | b, 0);
 }

 export const AccessOptionSet = { 
    'profileComplete'   : 1 << 0,
    'discovery'         : 1 << 1,
    'onboarding'        : 1 << 2,
    'na1'               : 1 << 3,
    'na2'               : 1 << 4,
    'na3'               : 1 << 5,
    'na4'               : 1 << 6,
    'na5'               : 1 << 7
 }

//  export const getAccessOptionSet = (accessList) => {
//     return accessList.map(({ task }) => {
//         console.log("accessOptionSet Task:",task)
//         return AccessOptionSet[`${task}`]
//     }).reduce((a, b) => a | b, 0);
//  }

 export const getAccessOptionSet = (accessList) => {
     return accessList.map((task)=>{ 
        return AccessOptionSet[`${task}`];
      }).reduce((a, b) => a | b, 0);
 }

export const linkAuth = getAuth();
export const linkCred = linkWithCredential;


export const handleLinkProvider = async (provider, staticProvider) => {
    linkWithPopup(linkAuth.currentUser, new staticProvider()).then((result) => {
        // Accounts successfully linked.
        const credential    = staticProvider.credentialFromResult(result);
        const user          = result.user;
        console.log("Credential:", credential, "User:", user);
        // ...
      }).catch((error) => {

        console.log(error);
        // Handle Errors here.
        // ...
      });
}

/**
    * Renders a thing of a component
    * @param {Object}                               props
    * @param {firebase.auth.UserCredential} props.userAuth - the color of the text in the button
    * @param {Object}                               props.additionalData - the background color of the button
*/
 
const handleUserProfile = async ({ userAuth, additionalData }) => {
    console.log(`Called handleUserProfile. !userAuth = ${!userAuth}`);
    if (!userAuth) return;

    var providerOptionSet = userAuth.providerData.map(({ providerId }) => {
        return ProviderOptionSet[`${providerId}`]
    }).reduce((a, b) => a | b, 0);


    const { uid }   = userAuth;

    const userRef   = firestore.doc(`Users/${uid}`);
    const snap      = await userRef.get();

    if (!snap.exists) {
        console.log('Inside !snap.exists');
        const newUsersRef   = firestore.doc(`NewUsers/${uid}`);
        const newSnap       = await newUsersRef.get();
        if (!newSnap.exists) {
            console.log('Inside !newSnap.exists');
            const { uid, email, providerData, photoURL } = userAuth;
            var { displayName } = userAuth;
            // const onboardingLevel   = 1;
            const timestamp         = new Date();
            const accessOptionSet   = 0;
            if (displayName === null) {
                displayName = additionalData.displayName
            }
            

            const prospect = new Prospect(
                uid,
                displayName,
                email,
                photoURL,
                accessOptionSet,
                providerOptionSet,
                providerData,
                timestamp,
                timestamp
            )

            try {

                const ref = doc(firestore, 'NewUsers', uid).withConverter(prospectConverter);
                await setDoc(ref, prospect);



                // await newUsersRef.set({
                //     uid,
                //     displayName,
                //     email,
                //     photoURL,
                //     providerOptionSet,
                //     providerData,
                //     dateCreated: timestamp,
                //     dateUpdated: timestamp,
                //     accessOptionSet,
                //     ...additionalData
                // })
            } catch(err) {
                console.log("Error Logging In:", err)
            }
        } else if (providerOptionSet !== newSnap.data().providerOptionSet) {
            const { providerData } = userAuth;
            const timestamp = new Date();
    
            console.log("New Provider", providerOptionSet, newSnap.data().providerOptionSet)
    
            try {
                await newUsersRef.update({
                    providerOptionSet,
                    providerData,
                    dateUpdated: timestamp,
                    ...additionalData
                })
            } catch(err) {
                console.log(err)
            }
        } else {
            console.log("Provider Matches", providerOptionSet, newSnap.data().providerOptionSet)
        }

        return newUsersRef;

    } else if (providerOptionSet !== snap.data().providerOptionSet) {
        const { providerData } = userAuth;
        const timestamp = new Date();

        console.log("New Provider", providerOptionSet, snap.data().providerOptionSet)

        try {
            await userRef.update({
                providerOptionSet,
                providerData,
                dateUpdated: timestamp,
                ...additionalData
            })
        } catch(err) {
            console.log(err)
        }

        return userRef;
    } else {
        console.log("Provider Matches", providerOptionSet, snap.data().providerOptionSet)
    return userRef;
    }
}

export { handleUserProfile }

/**
    * Fetches questions from Firestore
*/
const fetchQuestions = async () => {
    try {
        const questionsCollection = collection(firestore, "questions");
        const querySnapshot = await getDocs(questionsCollection);
        return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
        console.error("Error fetching questions: ", error);
    }
}

export { fetchQuestions };



/**
    * Renders a thing of a component
    * @param {Object}                               props
    * @param {User}                               props.user - the background color of the button
*/
 
const handleUpdateUserProfile = async ({ user }) => {
    const userAuth = await getCurrentUser();

    console.log(`Called handleUpdateUserProfile. !userAuth = ${!userAuth}`);
    if (!userAuth) return;

    console.log(`User Data\n`, userAuth)
    var providerOptionSet = userAuth.providerData.map(({ providerId }) => {
        console.log("In Map:", providerId, ProviderOptionSet[`${providerId}`]);
        return ProviderOptionSet[`${providerId}`]
    }).reduce((a, b) => a | b, 0);

    console.log("Provider Option Set:", providerOptionSet)

    const { uid } = userAuth;

    const userRef = firestore.doc(`Users/${uid}`);
    const snap = await userRef.get();

    if (!snap.exists) {

        console.log(userAuth);
        
        const { uid, displayName, email, providerData, photoURL } = userAuth;
        const timestamp = new Date();
        
        const ref = doc(firestore, 'Users', uid).withConverter(userConverter);
        await setDoc(ref, user);
    } else if (providerOptionSet !== snap.data().providerOptionSet) {
        const { providerData }  = userAuth;
        const timestamp         = new Date();

        console.log("New Provider", providerOptionSet, snap.data().providerOptionSet)

        // try {
        //     await userRef.update({
        //         providerOptionSet,
        //         providerData,
        //         dateUpdated: timestamp,
        //         ...additionalData
        //     })
        // } catch(err) {
        //     console.log(err)
        // }
    } else {
        console.log("Provider Matches", providerOptionSet, snap.data().providerOptionSet)
    }

    return userRef;
}
export {handleUpdateUserProfile};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    })
}

/**
    * Submits Discovery Questioneer answers to database
    * @param {Array<string>}                       answers - the answers to the questioneer
*/

const handleUserDiscovery = async (answers) => {
    console.log(`Called handleUserDiscovery. !answers = ${!answers}`);
    // if (!answers) return;
    const userAuth = await getCurrentUser()

    console.log(`User Data\n`, answers)

    // await setDoc(doc(firestore, "TestUsers", "User2","Discovery","Answers"), {
    //     answers: answers
    // });

    const { uid } = userAuth;

    const discoveryRef = firestore.doc(`Users/${uid}/Documents/Discovery`);
    const snap = await discoveryRef.get();

    if (!snap.exists) {

        console.log(discoveryRef);
        
        // const { uid, displayName, email, providerData, photoURL } = userAuth;
        const timestamp = new Date();
        
        try {
            await discoveryRef.set({
                "answers":answers,
                timestamp
            })
        } catch(err) {
            console.log("Error Logging In:", err)
        }
    } else {
        console.log("Answers already submitted", answers, snap.data())
    }

    return discoveryRef;
}
export { handleUserDiscovery }



/**
    * Submits Discovery Questionnaire object to database
    * @param {Array<string>}                       questionnaire - the questionnaire object
*/

const handleSubmitQuestionnaire = async (questionnaire) => {
    console.log(`Called handleSubmitQuestionnaire. !questionnaire = ${!questionnaire}`);
    const userAuth = await getCurrentUser()

    console.log(`Questionnaire Data\n`, questionnaire)

    const questionnaireRef = collection(firestore, "Questionnaires").withConverter(questionnaireConverter);
await addDoc(questionnaireRef, questionnaire);

    return questionnaireRef;
}
export { handleSubmitQuestionnaire }

/**
    * Submits Social Media Feedback object to database
    * @param {Feedback}                       feedback - the feedback object
*/

const handleSubmitSocialMediaFeedback = async (feedback) => {
    console.log(`Called handleSubmitSocialMediaFeedback. !feedback = ${!feedback}`);

    console.log(`Feedback Data\n`, feedback)
    // const feedbackRef = collection(firestore, "Feedback").withConverter(feedbackConverter);
    const feedbackDocRef = doc(collection(firestore, "Feedback")).withConverter(feedbackConverter);
    feedback.id = feedbackDocRef.id

// await addDoc(feedbackRef, feedback);
await setDoc(feedbackDocRef, feedback);

    return feedbackDocRef;
}
export { handleSubmitSocialMediaFeedback }

/**
    * Submits FAQ Further Inquiry Feedback object to database
    * @param {Feedback}                       feedback - the feedback object
*/

const handleSubmitFAQFurtherInquiryFeedback = async (feedback) => {
    console.log(`Called handleSubmitFAQFurtherInquiryFeedback. !feedback = ${!feedback}`);

    console.log(`Feedback Data\n`, feedback)
    const feedbackDocRef = doc(collection(firestore, "Feedback")).withConverter(feedbackConverter);
    feedback.id = feedbackDocRef.id

// await addDoc(feedbackRef, feedback);
await setDoc(feedbackDocRef, feedback);

    return feedbackDocRef;
}
export { handleSubmitFAQFurtherInquiryFeedback }