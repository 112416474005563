import React from 'react';
import {
  InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper,
  TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, Btn, BtnLink
} from './index.elements';

const ContactInfoSection = ({
  lightBg, topLine, headline, description, buttonLabel, img, alt, id, darkText
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading>{headline}</Heading>
                {/* <Subtitle darkText={darkText}>{description}</Subtitle> */}
                <Subtitle darkText={darkText}>
                    {description.split('\n').map((line, index, array) => (
                        <>
                        {line}
                        {index === array.length - 1 ? null : <br />}
                        </>
                    ))}
                </Subtitle>
                <BtnWrap>
                    <BtnLink to="/">{buttonLabel}</BtnLink>
                    <BtnLink to="/directory">Company Directory</BtnLink>

                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default ContactInfoSection;
