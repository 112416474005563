import React      from 'react'
import questions  from '../components/FAQ/faq.json'
import Banner     from '../components/FAQ'
import Video      from '../videos/faq_bg.mp4'
import { HeroBg, VideoBg } from '../components/FAQ/faq.elements'

const FAQPage = () => {
  return (
    <>
      <Banner>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
        <Banner.Header>Frequently Asked Questions</Banner.Header>
        {questions.map((question) => (
          <Banner.Entity key={question.id}>
            <Banner.Question>{question.question}</Banner.Question>
            <Banner.Text>{question.answer}</Banner.Text>
          </Banner.Entity>
        ))}
        <Banner.Footer>Question not on the list? Contact us for further inquiries.</Banner.Footer>
        <Banner.ContactForm/>
      </Banner>
    </>
  )
}

export default FAQPage