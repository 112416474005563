import React, { useState, useEffect }          from 'react';
import { useDispatch, useSelector }   from 'react-redux';
import AuthProviders                from '../AuthProviders';
import { auth, handleUserProfile }  from '../../firebase/utils'
import FormInput                    from '../Forms/FormInput';

import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  // FormLabel,
  // FormInput,
  FormButton,
  // Text,
  Errors,
  SigninA
} from './elements';
import { signUpUserStart } from '../../redux/User/user.actions';
import { useNavigate } from 'react-router-dom';


const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const Signup = props => {
  const dispatch                                = useDispatch();
  const { currentUser }                         = useSelector(mapState);
  const navigate                                = useNavigate();
  const [confirmPassword, setConfirmPassword  ] = useState('');
  const [displayName,     setDisplayName      ] = useState('');
  const [password,        setPassword         ] = useState('');
  const [errors,          setErrors           ] = useState([]);
  const [email,           setEmail            ] = useState('');

  useEffect(() => {
    if (currentUser) {
      reset();
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const reset = () => {
    setConfirmPassword('');
    setDisplayName('');
    setPassword('');
    setErrors([]);
    setEmail('');
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    if (password !== confirmPassword) {
      const error = ['Passwords don\'t match'];
      setErrors(error)
      return;
    }

    try {
      // const userAuth = await auth.createUserWithEmailAndPassword(email, password);
      // const { user } = userAuth;
      // console.log(`Successfully created Account for email ${user.email}`)      
      // await handleUserProfile(userAuth, {displayName: displayName});
      // dispatch(emailSignInStart({ email, password }));
      dispatch(signUpUserStart({displayName, email, password, confirmPassword}))

    } catch(error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode === 'auth/weak-password') {
      alert('The password is too weak.\nPassword should be at least 6 characters.');
    } else {
      alert(`Default: ${errorMessage}`);
    }
    console.log(error);
};

  }

    return (
      <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleFormSubmit}>
              <FormH1>Sign up for your account</FormH1>
              <Errors>
              {errors.length > 0 && (
                <ul>
                  {errors.map((err, index) => {
                    return (
                      <li key={index}>
                        {err}
                      </li>
                    )
                  })}
                </ul>
              )}
              </Errors>
              
              
              <FormInput
              type            ='text'
              label           ='Display Name'
              autoCapitalize  ='words'
              name            ='displayName'
              value           ={displayName}
              placeholder     ='Full name'
              handleChange    ={e => setDisplayName(e.target.value)}
              required />

              <FormInput
              label               ='Email'
              type                ='email'
              name                ='email'
              value               ={email}
              placeholder         ='dan.daly@example.com'
              handleChange        ={e => setEmail(e.target.value)}
              required />

              <FormInput
              label               ='Password'
              type                ='password'
              name                ='password'
              value               ={password}
              placeholder         ='Enter password'
              handleChange        ={e => setPassword(e.target.value)}
              required />

              <FormInput
              label               ='Confirm Password'
              type                ='password'
              name                ='confirmPassword'
              value               ={confirmPassword}
              placeholder         ='Verify password'
              handleChange        ={e => setConfirmPassword(e.target.value)}
              required />

              <FormButton type    ='submit'>Continue</FormButton>

              <AuthProviders />
              <SigninA href='/signin'>
                Already have an account? Signin
              </SigninA>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
    )
}

export default Signup;
