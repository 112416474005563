import styled from 'styled-components';

export const SignInWithProviderButton = styled.button`
  background      : #193356;
  padding         : 16px 10px 16px 10px;
  margin-top      : 2rem;
  border          : none;
  border-radius   : 4px;
  color           : #fff;
  font-size       : 20px;
  cursor          : pointer;
`;
