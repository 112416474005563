import { StyledFooterLink }         from "./index.elements";
import { animateScroll as scroll }  from "react-scroll";

export const FooterLink = ({ to, children }) => {
    const scrollToTop = () => {
      scroll.scrollToTop();
    };
  
    return (
      <StyledFooterLink to={to} onClick={scrollToTop}>
        {children}
      </StyledFooterLink>
    );
  };