// Components
import React, { useState, useEffect }   from 'react';
import { useDispatch, useSelector }     from 'react-redux';
import AuthProviders                  from '../AuthProviders';
import { useNavigate }                from 'react-router-dom';

import {Container,
        FormWrap,
        FormContent,
        Form,
        FormH1,
        FormLabel,
        FormInput,
        FormButton,
      }                               from './linkSignIn.elements';



import { 
    linkGoogleStart, 
    linkFacebookStart, 
    linkTwitterStart, 
    linkPasswordStart }                 from '../../redux/User/user.actions';

// Utils
import { ProviderOptionSet } from '../../firebase/utils';
import { handleLinkEmailPassword } from '../../redux/User/user.helpers';

//Assets
const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const LinkSignIn = props => {
    const dispatch                      = useDispatch();
    const navigate                      = useNavigate();
    const { currentUser }               = useSelector(mapState);
    const [email,         setEmail]     = useState('');
    const [password,      setPassword]  = useState('');
  
    useEffect(() => {
      if (!currentUser) {
        resetForm();
        navigate('/signin');
      }
    }, [currentUser, navigate]);
  
    const resetForm = () => {
      setEmail('')
      setPassword('');
    };
  
    const handleSubmit = e => {
      e.preventDefault();
    //   if (email.length > 4 && password.length > 5) {
    //       dispatch(emailSignInStart({ email, password }));
    //   };
    }

    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors,          setErrors]          = useState([]);




    const handleLinkPassword = () => {

        console.log("Called handleLinkPassword");

        dispatch(linkPasswordStart({ email, password }));
    };

    const handleLinkGoogle = () => {
        dispatch(linkGoogleStart());
    };

    const handleLinkFacebook = () => {
        dispatch(linkFacebookStart());
    };

    const handleLinkTwitter = () => {
        dispatch(linkTwitterStart());
    };

        return (
            <>
                <Container>
                    <FormWrap>
                        <FormContent>
                            <Form onSubmit={handleSubmit}>
                                <FormH1>Link with another account</FormH1>

                    { (ProviderOptionSet["password"] & currentUser.providerOptionSet) === 0 && (
                        <>

                            <FormLabel htmlFor  ='for'>Email</FormLabel>
                            <FormInput
                                type                ='email'
                                name                ='email'
                                value               ={email}
                                placeholder         ='dan.daly@example.com'
                                onChange            ={e => setEmail(e.target.value)}
                                required />

                            <FormLabel htmlFor  ='for'>Password</FormLabel>
                            <FormInput
                                type                ='password'
                                name                ='password'
                                value               ={password}
                                placeholder         ='Enter password'
                                onChange            ={e => setPassword(e.target.value)}
                                required />  

                            <FormLabel htmlFor  ='for'>Password</FormLabel>
                            <FormInput
                                type                ='password'
                                name                ='confirmPassword'
                                value               ={confirmPassword}
                                placeholder         ='Confirm password'
                                onChange            ={e => setConfirmPassword(e.target.value)}
                                required />  
                            

                            <FormButton
                                type    ='submit'
                                onClick ={handleLinkPassword}>
                                Link
                            </FormButton>
                            
                        </>
                    )}

                    { (ProviderOptionSet["google.com"] & currentUser.providerOptionSet) === 0 && (
                                <FormButton
                                type    ='submit'
                                onClick ={handleLinkGoogle}>
                                    Link with Google
                                </FormButton>
                            )}

                    { (ProviderOptionSet["facebook.com"] & currentUser.providerOptionSet) === 0 && (
                                <FormButton
                                type    ='submit'
                                onClick ={handleLinkFacebook}>
                                    Link with Facebook
                                </FormButton>
                            )}

                    { (ProviderOptionSet["twitter.com"] & currentUser.providerOptionSet) === 0 && (
                                <FormButton
                                type    ='submit'
                                onClick ={ handleLinkTwitter /*linkWithTwitter*/ }>
                                    Link with Twitter
                                </FormButton>
                            )}

                            </Form>
                        </FormContent>
                    </FormWrap>
                </Container>
            </>
        )    
}

export default LinkSignIn;

// // Components
// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector }   from 'react-redux';
// import AuthProviders                  from '../AuthProviders';
// import { useNavigate }                from 'react-router-dom';
// import { emailSignInStart }           from '../../redux/User/user.actions';

// import {Container,
//         FormWrap,
//         FormContent,
//         Form,
//         FormH1,
//         FormLabel,
//         FormInput,
//         FormButton,
//         SignupA
//       }                               from './elements';

// const SignIn = props => {
//   const dispatch                      = useDispatch();
//   const navigate                      = useNavigate();
//   const { currentUser }               = useSelector(mapState);
//   const [email,         setEmail]     = useState('');
//   const [password,      setPassword]  = useState('');

//   useEffect(() => {
//     if (currentUser) {
//       resetForm();
//       navigate('/dashboard');
//     }
//   }, [currentUser, navigate]);

//   const resetForm = () => {
//     setEmail('')
//     setPassword('');
//   };

//   const handleSubmit = e => {
//     e.preventDefault();
//     if (email.length > 4 && password.length > 5) {
//         dispatch(emailSignInStart({ email, password }));
//     };
//   }

//   return (
//     <>
//       <Container>
//         <FormWrap>
//           <FormContent>
//             <Form onSubmit={handleSubmit}>
//               <FormH1>Sign in to your account</FormH1>
//               {/* <FormLabel htmlFor  ='for'>Email</FormLabel>
//               <FormInput
//               type                ='email'
//               name                ='email'
//               value               ={email}
//               placeholder         ='dan.daly@example.com'
//               onChange            ={e => setEmail(e.target.value)}
//               required /> */}

//               {/* <FormLabel htmlFor  ='for'>Password</FormLabel>
//               <FormInput
//               type                ='password'
//               name                ='password'
//               value               ={password}
//               placeholder         ='Enter password'
//               onChange            ={e => setPassword(e.target.value)}
//               required />               */}
//               {/* <FormButton               type    ='submit'>  Continue  </FormButton> */}
//               <AuthProviders />
//               <SignupA href='/recovery'>  Forgot password                </SignupA>
//               <SignupA href='/signup'>    Don't have an account? Signup  </SignupA>
//             </Form>
//           </FormContent>
//         </FormWrap>
//       </Container>
//     </>
//   );
// };

// export default SignIn;