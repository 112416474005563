import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InfoContainer = styled.div`
  background: #f9f9f9; // Light background
  padding: 100px 0;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  flex: 50%;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  flex: 50%;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #67d6ff; // Accent color
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #010606; // Dark text color
`;

export const Subtitle = styled.div`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #010606; // Dark text color
  
  a {
    /* color: #67d6ff; // Accent color */
    margin-right: 20px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      color: #fff;
      background-color: #67d6ff;
      padding: 5px 10px;
      border-radius: 10px;
    }
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const BtnLink = styled(Link)`
  border-radius: 50px;
  background: #67d6ff;
  white-space: nowrap;
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }

// export const BtnLink = styled(Link)`
//   border-radius: 50px;
//   background: #67d6ff;  // Accent color
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #010606;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
//   }
// `;

