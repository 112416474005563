import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const InvestorsPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch our investors portal where you can stay up to date on the state of our wonderful company! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default InvestorsPage