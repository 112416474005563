import styled from 'styled-components';

export const SocialMediaContainer = styled.div`
  padding     : 100px 0;
  background  : #010606;
  color       : #ffffff;
`;

export const SocialMediaWrapper = styled.div`
  max-width : 1100px;
  margin    : auto;
  padding   : 0 20px;
`;

export const Introduction = styled.p`
  font-size     : 18px;
  margin-bottom : 30px;
`;

export const ServicesOfferedList = styled.ul`
  list-style    : inside;
  margin-bottom : 30px;
`;

export const WhyChooseUs = styled.p`
  font-size     : 18px;
  margin-bottom : 35px;
  line-height   : 24px;
  color         : '#ffffff'; //${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const FeedbackForm = styled.form`
  display         : flex;
  flex-direction  : column;
`;

export const Input = styled.input`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;
`;

export const TextArea = styled.textarea`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;
`;

export const Select = styled.select`
  padding       : 10px;
  margin-bottom : 20px;
  border-radius : 5px;
  border        : 1px solid #ccc;

  option {
         color      : black;
         background : white;
       }
`;

export const SubmitButton = styled.button`
  border-radius   : 50px;
  background      : #67d6ff;
  white-space     : nowrap;
  padding         : 10px 16px;
  color           : #010606;
  font-size       : 16px;
  outline         : none;
  border          : none;
  cursor          : pointer;
  transition      : all 0.2s ease-in-out;
  text-decoration : none;

  &:hover {
    transition  : all 0.2s ease-in-out;
    background  : #fff;
    color       : #010606;
  }
`;

export const Label = styled.label`
  margin-bottom : 5px;
  font-size     : 16px;
`;

