import { Timestamp }    from "firebase/firestore";
import PointOfContact   from "./PointOfContact";
import Category         from "./Category";
import Tag              from "./Tag";
import Section          from "./Section";

/**
 * Class defining Questionnaire Document Object
 */
class Questionnaire {
    /**
     *@param {PointOfContact}   author      - Author information who created the questionnaire
     *@param {Array<Section>}   sections    - List of sections in sequential order from top to bottom
     *@param {Category}         category    - Category of the questionnaire
     *@param {Timestamp}        date        - Date that the questionnaire was created
     *@param {string}           id          - Unique Identifier of the Questionnaire
     *@param {Array<Tag>}       tag         - Tags associated to the Questionnaire
     *@param {string}           title       - The Title of the questionnaire
     */

    constructor(
        author,
        sections,
        category,
        date,
        id,
        tag,
        title   
    ) {
        this.author     = author;
        this.sections   = sections;
        this.category   = category;
        this.date       = date;
        this.id         = id;
        this.tag        = tag;
        this.title      = title;
    }

    /**
     * Function ToDict
     * @returns {Object}
     */

    ToDict() {
        return {
            "author"    : this.author,
            "sections"  : this.sections.map(section => section.ToDict()),
            "category"  : this.category.getCategory(),
            "date"      : this.date,
            "id"        : this.id,
            "tag"       : [],  // this.tag,
            "title"     : this.title   
        };
    }

    /**
     * Function FromDict
     * @param {Object} data - The data from database
     * @static
     * @returns {Questionnaire}
     */

    static FromDict(data) {
        if (data == null) {
            return data;
        }

        return new Questionnaire(
            data.author,
            data.sections.map(sectionData => Section.FromDict(sectionData)),
            data.category,
            data.date,
            data.id,
            data.tag,
            data.title
        );
    }
}

/**
 * A Questionnaire
 * @typedef {FirebaseFirestore.FirestoreDataConverter} QuestionnaireConverter
 * @property {Function} toFirestore - Function to send data to database
 * @property {Function} fromFirestore - Function to retrieve data from database
 */

export const questionnaireConverter = {
    toFirestore: function (questionnaire) {
        return {
            author      : questionnaire.author.ToDict(),
            sections    : Section.MapToDict(questionnaire.sections),
            category    : questionnaire.category.getCategory(),
            date        : questionnaire.date,
            id          : questionnaire.id,
            tag         : Tag.MapToDict(questionnaire.tag),
            title       : questionnaire.title
        }
    },

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Questionnaire(
            data.author,
            data.sections.map(sectionData => Section.FromDict(sectionData)),
            data.category,
            data.date,
            data.id,
            data.tag,
            data.title
        );
    }
}

export default Questionnaire;
