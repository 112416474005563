import React from 'react';
import SignUp from '../components/SignUp';
import ScrollToTop from '../components/ScrollToTop';

const SignupPage = props => {
  return (
    <>
      <ScrollToTop />
      <SignUp />
    </>
  ); 
}

export default SignupPage;
