
// Category enums can be grouped as static members of a class
class Category {
    // Create new instances of the same class as static attributes

    // static Area         = new Category("area")        
    // static Cert         = new Category("cert")          
    // static Company      = new Category("company")              
    // static Contractor   = new Category("contractor")                  
    // static Finding      = new Category("finding")              
    // static Group        = new Category("group")              
    // static Hazard       = new Category("hazard")              
    // static Inspection   = new Category("inspection")                  
    // static JSRA         = new Category("JSRA")          
    // static Person       = new Category("person")              
    // static Place        = new Category("place")              
    // static Process      = new Category("process")              
    // static Program      = new Category("program")              
    // static Publication  = new Category("publication")                  
    // static Qual         = new Category("qual")          
    // static Task         = new Category("task")          
    // static Test         = new Category("test")          
    // static Training     = new Category("training")              
    // static TSAC         = new Category("TSAC")          
    // static Vehicle      = new Category("vehicle")            
    static Development  = new Category("development") 
  

    constructor(name) {
      this.name = name
    }

    getCategory = () => {
        return this.name
    }
    
  }
  //   // Now we can access enums using namespaced assignments
//   // this makes it semantically clear that "Summer" is a "Season"
//   let category = Category.Place
  
//   // We can verify whether a particular variable is a Season enum
//   console.log(category instanceof Category)
//   // true
//   console.log(Symbol('something') instanceof Category)
//   //false
  
//   // We can explicitly check the type based on each enums class
//   console.log(category.constructor.name)
//   // 'Category'


  export default Category;