/**
 * Class to create a QuestionBlock object
 */
class QuestionBlock {
    /**
     *@param {string} headline - Headline of the question block
     *@param {string} question - Question text for the question block
     *@param {string} placeholder - Placeholder text for the question block
     */

    constructor(headline, question, placeholder) {

        /**
         *@property {string} headline - Headline of the question block
         *@property {string} question - Question text for the question block
         *@property {string} placeholder - Placeholder text for the question block
         */

        this.headline = headline;
        this.question = question;
        this.placeholder = placeholder;
    }

    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of a QuestionBlock
     * @returns {Object}
     */

    ToDict() {
        return {
            "headline" : this.headline,
            "question" : this.question,
            "placeholder" : this.placeholder,
        };
    }

    /**
     * Function FromDict
     * @property {Function}                  FromDict Creates a QuestionBlock object from a dictionary
     * @property {Object}                    data The data of the QuestionBlock
     * @static
     * @returns {QuestionBlock}              The QuestionBlock object for export
     */

    static FromDict(data) {

        if (data == null) {
            return data
        }

        console.log(`Called FromDict in QuestionBlock`);

        return new QuestionBlock(
            data.headline,
            data.question,
            data.placeholder);
    }
}

/**
 * A QuestionBlock Object
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} QuestionBlockConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type QuestionBlockConverter
 */

export const questionBlockConverter = {

    /**
     * Function toFirestore
     * @property {Function}                  toFirestore Function to send QuestionBlock object to database
     * @param    {QuestionBlock}             questionBlock QuestionBlock to be converted
     * @returns  {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (questionBlock) {
        return {
            "headline" : questionBlock.headline,
            "question" : questionBlock.question,
            "placeholder" : questionBlock.placeholder,
        }
    },

    /**
     * Function fromFirestore
     * @property {Function}                                  fromFirestore Function to convert data from database into QuestionBlock object
     * @param    {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param    {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns  {QuestionBlock}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new QuestionBlock(
            data.headline,
            data.question,
            data.placeholder,
        );
    }
}

export default QuestionBlock;
