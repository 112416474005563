import React          from 'react';
import ContactProfile from '../components/ContactProfile';
import { sampleUser } from '../components/ContactProfile/sampleUser';

function ProfilePage() {

  return (
    <>
      <ContactProfile user={sampleUser} />
    </>
  );
}

export default ProfilePage;