
/**
 * Class to create a Phone object
 */
 class Phone {
    /**
     *@param {string} label - Users phone label
     *@param {number} number - Users phone number
     *@param {string} [provider] - Users phone service provider (Optional)
     *@param {boolean} textMe - Users election for receiving texts from Corps Diamond
     *@param {string} type - Type of users phone service
     */
    constructor(label, number, provider, textMe, type) {

        /**
         *@property {string} label - Users phone label
         *@property {number} number - Users phone number
         *@property {string} [provider] - Users phone service provider (Optional)
         *@property {boolean} textMe - Users election for receiving texts from Corps Diamond
         *@property {string} type - Type of users phone service
         */

        this.label = label;
        this.number = number;
        this.provider = provider;
        this.textMe = textMe;
        this.type = type;

    }
    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of
     * @returns {Object}
     */

    ToDict() {
        return {
            "label": this.label,
            "number": this.number,
            "provider": this.provider,
            "textMe": this.textMe,
            "type": this.type
        };
    }

    /**
* Function FromDict
* @property {Function} FromDict Returns a dictionary format of a
* @property {Object} data The data from database
* @static
* @returns {Phone} The map object for export
*/

    static FromDict(data) {
        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Phone`);

        return new Phone(
            data.label,
            data.number,
            data.provider,
            data.textMe,
            data.type);

    }
}

/**
 * A Phone
 * @typedef {FirebaseFirestore.FirestoreDataConverter} PhoneConverter
 * @property {Function} toFirestore - Function to send data to database
 * @property {Function} fromFirestore - Function to retrieve data from database
 */

/**
 * @type PhoneConverter
 */

export const phoneConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send Phone object to database
     * @param {Phone} phone Phone to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (phone) {
        return {

            label: phone.label,
            number: phone.number,
            provider: phone.provider,
            textMe: phone.textMe,
            type: phone.type

        }
    },

    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into Phone object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {Phone}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Phone(
            data.label,
            data.number,
            data.provider,
            data.textMe,
            data.type
        );
    }
}

export default Phone;