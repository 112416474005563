import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  // FaTwitter,
  FaLinkedin,
  FaTiktok
} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { FooterLink }               from '../LinkScroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterLinkDisabled
} from './elements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Company</FooterLinkTitle>
              <FooterLink to='/about-us'>                       About Us                </FooterLink>
              {/* <FooterLink to='/careers'>                        Careers                 </FooterLink>
              <FooterLink to='/investors'>                      Investors               </FooterLink>
              <FooterLink to='/press'>                          Press                   </FooterLink> */}
              <FooterLinkDisabled>                              Careers                 </FooterLinkDisabled>
              <FooterLinkDisabled>                              Investors               </FooterLinkDisabled>
              <FooterLinkDisabled>                              Press                   </FooterLinkDisabled>
            </FooterLinkItems>
            
            <FooterLinkItems>
              <FooterLinkTitle>Support</FooterLinkTitle>
              <FooterLink to='/contact'>                        Contact Us              </FooterLink>
              <FooterLink to='/terms.html'    target="_blank">  Terms & Conditions      </FooterLink>
              <FooterLink to='/privacy.html'  target="_blank">  Privacy Policy          </FooterLink>
              {/* <FooterLink to='/guides'>                         Guides                  </FooterLink> */}
              <FooterLink to='/faq'>                            F.A.Q.                  </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Services</FooterLinkTitle>
              <FooterLink to='/social-media'>                                       Social Media            </FooterLink>
              {/* <FooterLink to='/app-dev'>                                            App Development         </FooterLink>
              <FooterLink to='/web-dev'>                                            Web Development         </FooterLink>
              <FooterLink to='/bolt-on'>                                            Bolt On Applications    </FooterLink>
              <FooterLinkDisabled >                                             Social Media          </FooterLinkDisabled > */}
              <FooterLinkDisabled >                                             App Development       </FooterLinkDisabled >
              <FooterLinkDisabled >                                             Web Development       </FooterLinkDisabled >
              <FooterLinkDisabled >                                             Bolt On Applications  </FooterLinkDisabled >

            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Community</FooterLinkTitle>
              {/* <FooterLink to='/blog'>                                               Luckey Logic Blog       </FooterLink> */}
              <FooterLink to='//www.instagram.com/luckeylogic'>                     Instagram               </FooterLink>
              <FooterLink to='//www.facebook.com/luckeylogic'>                      Facebook                </FooterLink>
              <FooterLink to='//www.tiktok.com/@luckeylogic'>                                    TikTok                  </FooterLink>
              <FooterLink to='//www.youtube.com/channel/UCYpu2dcEZ6VRi_DZtKV34ZQ'>  Youtube                 </FooterLink>
              {/* <FooterLink to='//www.twitter.com'>                                   Twitter                 </FooterLink> */}
              <FooterLink to='//www.linkedin.com/company/luckeylogic'>              Linked  <FaLinkedin />  </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Luckey Logic
            </SocialLogo>
            <WebsiteRights>© 2021-2022 Luckey Logic LLC All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='//www.facebook.com/luckeylogic' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='//www.tiktok.com/@luckeylogic' target='_blank' aria-label='TikTok'>
                <FaTiktok />
              </SocialIconLink>
              <SocialIconLink href='//www.instagram.com/luckeylogic' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href='//www.youtube.com/channel/UCYpu2dcEZ6VRi_DZtKV34ZQ'//channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
                target='_blank'
                aria-label='Youtube'
                rel='noopener noreferrer'
              >
                <FaYoutube />
              </SocialIconLink>
              {/* <SocialIconLink
                target='_blank'
                aria-label='Twitter'
                href='//https://www.twitter.com'
              >
                <FaTwitter />
              </SocialIconLink> */}
              <SocialIconLink href='//www.linkedin.com/company/luckeylogic' target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
