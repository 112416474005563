import React, { useState } from 'react';
import NavbarAuth   from '../components/NavbarAuth';
import Footer       from '../components/Footer';
import SidebarAuth from '../components/SidebarAuth';

const AuthenticatedLayout = props => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
        <NavbarAuth {...props} />
        <SidebarAuth isOpen={isOpen} toggle={toggle} />
        {props.children}
        <Footer />
    </>
  )
}

export default AuthenticatedLayout