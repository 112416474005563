//  Feedback.js
//
//  Created by Miguel Brown on 2/13/2024, 7:59:19 PM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";

/**
* Feedback data model represents user feedback collected from various pages or sections of the website. It includes information such as the user's contact details, their comments or feedback, and the topics or subjects related to their feedback. The model is designed to be generic, allowing for versatile use across different contexts, and provides a structured format for storing and organizing feedback data in the database.
*/
class Feedback extends React.Component {
    
    /**
			*@param {string} id - A unique identifier for the feedback entry.
			*@param {string} email - The email address of the person providing feedback.
			*@param {string} contactName - The name of the person providing feedback.
			*@param {Array<string>} comments - An array of comments or feedback provided.
			*@param {Array<string>} topics - An array of topics or subjects related to the feedback.
			*@param {string} subject - The subject or category of the feedback.
    */
    
    constructor(id, email, contactName, comments, topics, subject) {

        /**
			*@property { string } id - A unique identifier for the feedback entry.
			*@property { string } email - The email address of the person providing feedback.
			*@property { string } contactName - The name of the person providing feedback.
			*@property { string } comments - An array of comments or feedback provided.
			*@property { string } topics - An array of topics or subjects related to the feedback.
			*@property { string } subject - The subject or category of the feedback.
        */

	super(id, email, contactName, comments, topics, subject);

	this.id = id; 
		this.email = email; 
		this.contactName = contactName; 
		this.comments = comments; 
		this.topics = topics; 
		this.subject = subject; 

    }
    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of a Feedback
     * @returns {Object} The map object for export
     */

    ToDict() {
        return {
	"id" : this.id,
			"email" : this.email,
			"contactName" : this.contactName,
			"comments" : this.comments,
			"topics" : this.topics,
			"subject" : this.subject
        };
    }

    /**
    * Function FromDict
    * @property {Function} FromDict Returns a dictionary format of a Meal
    * @property {Object} data The data from database
    * @static
    * @returns {Feedback} The database object to convert to Feedback
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new Feedback(
	data.id,
			data.email,
			data.contactName,
			data.comments,
			data.topics,
			data.subject);
    }

}

/**
* A Feedback
* @typedef {FirebaseFirestore.FirestoreDataConverter} FeedbackConverter
* @property {Function} toFirestore - Function to send data to database
* @property {Function} fromFirestore - Function to retrieve data from database
*/
        
/**
* @type FeedbackConverter
*/

export const feedbackConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send Feedback object to database
     * @param {Feedback} feedback Feedback to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(feedback) {
        return {                
	id : feedback.id,
			email : feedback.email,
			contactName : feedback.contactName,
			comments : feedback.comments,
			topics : feedback.topics,
			subject : feedback.subject
        }
    },
    
    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into Feedback object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {Feedback}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        return new Feedback (data.id,
			data.email,
			data.contactName,
			data.comments,
			data.topics,
			data.subject);
    }
}
export default Feedback