import React from 'react';
import Recovery from '../components/Recovery';
import ScrollToTop from '../components/ScrollToTop';

const RecoveryPage = props => {
  return (
    <>
      <ScrollToTop />
      <Recovery />
    </>
  ); 
}

export default RecoveryPage;
