// Components
import React            from 'react';
import { useDispatch }  from 'react-redux';

import { 
  googleSignInStart, 
  facebookSignInStart, 
  twitterSignInStart }                from '../../redux/User/user.actions';

import { SignInWithProviderButton }   from './authProviders.elements';

const AuthProviders = props => {
  const dispatch                      = useDispatch();

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  const handleFacebookSignIn = () => {
    dispatch(facebookSignInStart());
  };

  const handleTwitterSignIn = () => {
    dispatch(twitterSignInStart());
  };

  return (
    <>
        <SignInWithProviderButton type    ='submit'   onClick={handleGoogleSignIn}>   Sign in with Google   </ SignInWithProviderButton>
        <SignInWithProviderButton type    ='submit'   onClick={handleFacebookSignIn}> Sign in with Facebook </ SignInWithProviderButton>
        {/* <SignInWithProviderButton type    ='submit'   onClick={handleTwitterSignIn}>  Sign in with Twitter  </ SignInWithProviderButton> */}
    </>
  );
};

export default AuthProviders;
