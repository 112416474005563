
/**
 * Class to create a Email object
 */
 class Email {
    /**
     *@param {string} domain - Users email domain may be used as qualification with some Affiliation restrictions and requirements
     *@param {string} email - Users full email address
     *@param {string} label - Users email label
     */

    constructor(domain, email, label) {

        /**
         *@property {string} domain - Users email domain may be used as qualification with some Affiliation restrictions and requirements
         *@property {string} email - Users full email address
         *@property {string} label - Users email label
         */

        this.domain = domain;
        this.email = email;
        this.label = label;

    }

    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of
     * @returns {Object}
     */

    ToDict() {
        return {
            "domain": this.domain,
            "email": this.email,
            "label": this.label
        };
    }

    /**
* Function FromDict
* @property {Function} FromDict Returns a dictionary format of a
* @property {Object} data The data from database
* @static
* @returns {Email} The map object for export
*/

    static FromDict(data) {
        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Email`);

        return new Email(
            data.domain,
            data.email,
            data.label);

    }

        /**
* Function fromString
* @property {Function}  fromString Returns new Email instance from email string
* @property {Object}    email The data from database
* @static
* @returns {Email} The map object for export
*/

static fromString(fromEmail) {
    if (fromEmail == null) {
        return fromEmail
    }

    var email   = fromEmail;
    var name    = email.substring(0, email.lastIndexOf("@"));
    var domain  = email.substring(email.lastIndexOf("@") + 1);
    // console.log(`Called fromString in Email`);

    return new Email(
        domain,
        name,
        'default');

}
}


/**
 * An Email
 * @typedef {FirebaseFirestore.FirestoreDataConverter} EmailConverter
 * @property {Function} toFirestore - Function to send data to database
 * @property {Function} fromFirestore - Function to retrieve data from database
 */

/**
 * @type EmailConverter
 */

export const emailConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send Email object to database
     * @param {Email} email Email to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (email) {
        return {

            domain: email.domain,
            email: email.email,
            label: email.label

        }
    },

    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into Email object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {Email}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Email(
            data.domain,
            data.email,
            data.label
        );
    }
}

export default Email;