// import Street, { streetConverter } from "./Street";

/**
 * Class to create a Address object
 */
 class Address {
    /**
     *@param {string} [street] - Users street address information (Optional)
     *@param {string} [city] - Users city address information (Optional)
     *@param {string} state - Users state address information
     *@param {number} [zip] - Users address zip code (Optional)
     *@param {string} country - Users Country address information
     *@param {string} label - Users address label
     */

    constructor(street, city, state, zip, country, label) {

        /**
         *@property {string} [street] - Users street address information (Optional)
         *@property {string} [city] - Users city address information (Optional)
         *@property {string} state - Users state address information
         *@property {number} [zip] - Users address zip code (Optional)
         *@property {string} country - Users Country address information
         *@property {string} label - Users address label
         */

        this.street = street;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = country;
        this.label = label;

    }

    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of
     * @returns {Object}
     */

    ToDict() {
        return {
            // "street": this.street.ToDict(),
            "street": this.street,
            "city": this.city,
            "state": this.state,
            "zip": this.zip,
            "country": this.country,
            "label": this.label
        };
    }

    /**
* Function FromDict
* @property {Function} FromDict Returns a dictionary format of a
* @property {Object} data The data from database
* @static
* @returns {Address} The map object for export
*/

    static FromDict(data) {

        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Address`);

        return new Address(
            // Street.FromDict(data.street),
            data.street,
            data.city,
            data.state,
            data.zip,
            data.country,
            data.label);

    }
}


/**
 * An Address
 * @typedef {FirebaseFirestore.FirestoreDataConverter} AddressConverter
 * @property {Function} toFirestore - Function to send data to database
 * @property {Function} fromFirestore - Function to retrieve data from database
 */

/**
 * @type AddressConverter
 */

export const addressConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send Address object to database
     * @param {Address} address Address to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (address) {
        console.log(address.street);
        if (address.street != null) {
            // var returnStreet = streetConverter.toFirestore(address.street)
            // console.log("Street = " + returnStreet);

            return {
                // street: returnStreet,
                street: address.street,
                city: address.city,
                state: address.state,
                zip: address.zip,
                country: address.country,
                label: address.label

            }
        }
        else {

            return
        }
    },

    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into Address object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {Address}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Address(
            // Street.FromDict(data.street),
            data.street,
            data.city,
            data.state,
            data.zip,
            data.country,
            data.label
        );
    }
}

export default Address;