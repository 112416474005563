import React, { useState }  from 'react';
import Sidebar              from '../components/Sidebar';
import HeroSection          from '../components/HeroSection';
import InfoSection          from '../components/InfoSection';

import {
  homeObj1,
  homeObj2,
  homeObj3,
  homeObj4,
}                           from '../components/InfoSection/Data';
import Services             from '../components/Services';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} /> */}
      <HeroSection />
      <InfoSection {...homeObj1} />
      <InfoSection {...homeObj2} />
      <InfoSection {...homeObj3} />
      <Services />
      <InfoSection {...homeObj4} />
    </>
  );
}

export default Home;
