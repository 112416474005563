import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const GuidesPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch the Guides page where we will show you how to use our services to best fit your business! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default GuidesPage