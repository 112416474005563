import { useEffect }    from "react";
import { useSelector }  from "react-redux";
import { useNavigate }  from "react-router-dom";

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const useAuth = props => {
    const { currentUser } = useSelector(mapState);
    const navigate = useNavigate();
    useEffect(() => {
        if (!currentUser) {
            console.log(`useAuth.js => !currentUser`)
            navigate('/signin');
        } else if (currentUser.accessOptionSet === 0 ) {
            console.log(`useAuth.js => currentUser.accessOptionSet ${currentUser.accessOptionSet}`)
            navigate('/complete-profile');
        } else if (currentUser.accessOptionSet === 1 ) {
            console.log(`useAuth.js => currentUser.accessOptionSet ${currentUser.accessOptionSet}`)
            navigate('/discovery');
        } else if (currentUser.accessOptionSet === 3 ) {
            console.log(`useAuth.js => currentUser.accessOptionSet ${currentUser.accessOptionSet}`)
            navigate('/dashboard');
        }  else {
            console.log('Inside useEffect useAuth.js',currentUser);
        }
    }, [currentUser]);

    return currentUser;
}



export default useAuth;
