const vCard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//iPhone OS 17.1//EN
N:Brown;Miguel;;;
FN:Miguel Brown
ORG:Luckey Logic;Corporate
TITLE:Founder/CEO
EMAIL;type=INTERNET;type=WORK;type=pref:miguel.brown@luckeylogic.com
TEL;type=IPHONE;type=CELL;type=VOICE;type=pref:(252) 648-1099
X-SOCIALPROFILE;type=facebook:http://facebook.com/LuckeyLogic
item1.ADR;type=pref:;;P.O. Box 466;Luckey;OH;43443;United States
item1.X-ABLabel:Mailing Address
item1.X-ABADR:us
NOTE:My go to Taco Bell order: Mexican pizza, Cheesy bean and rice burrito +beef +sour cream, Large Baja Blast. Round up the change for their education!
item2.URL;type=pref:www.luckeylogic.com
item2.X-ABLabel:_$!<HomePage>!$_
PHOTO;ENCODING=b;TYPE=JPEG:/9j/4AAQSkZJRgABAQAASABIAAD/4QBMRXhpZgAATU0AKgAA
 AAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABQKADAAQAAAABAA
 ABQAAAAAD/wAARCAFAAUADASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcI
 CQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0f
 AkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4
 eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2u
 Hi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QA
 tREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRCh
 YkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOE
 hYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5e
 bn6Onq8vP09fb3+Pn6/9sAQwACAgICAgIDAgIDBQMDAwUGBQUFBQYIBgYGBgYICggICAgICAoK
 CgoKCgoKDAwMDAwMDg4ODg4PDw8PDw8PDw8P/9sAQwECAgIEBAQHBAQHEAsJCxAQEBAQEBAQEB
 AQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQ/90ABAAU/9oADAMBAAIR
 AxEAPwD6cooor+yD/IcKKKKACiiigAooooAKKKKACiiigAoopCQBljgeppSkkrsunTlOSjBXbF
 orxP4n/GPTPBFj5Wk7dR1KTICA5WPHGWPrnoK+Q9a/aQ+Jc7jNytmBn5YgB68Zr85zzxOy/B1H
 Rjecl22+8/e+D/o655mlBYmq1Rg9ua/M/Oy6erP0pwfSk5r8p5f2jviAsgkl1Z0c547YrQtP2m
 PiWrwquo+aiDkMB82TnOa8GHjNhL+9Ql96PtK30Ucel7mMg36NH6kUV+e1h+2B4h05fL1vTobw
 jGGQFMivafBf7U3grxMwt9UhfTLg44blcfWvpMB4m5TXai5uLfdfqfA5z9HbiXCQlUjTjUS/ll
 d/c7M+oKKz9M1XTdZtVvtKuY7qBiQHjYMuR1GR3rQr72lVjUipwd0+p+J4rCVaFSVGtFxktGno
 0FFFFaHOFFFFABRRRQAUUUUAFFFFABRRRQAUUUUAFFFFABRRRQB//9D6cooor+yD/IcKKKKACi
 iigAooooAKKKKACiiuE8X/ABG8LeCtkOsXai7lGY4FOZGHqR2Hua5Mbj6OGpurXkoxXVnq5Nkm
 LzDERwuCpuc3sl/W3md0zKqlmOAoyT7V4F8X/HVjY6bPp9pdhbhACoBwSeCdwH16GuH8S/FnXP
 FepS+H/C8DKIimQmctnkgkiuUsvhF4g1OYap4jZ3eWQuwb09PcV/P/AB54lwxEHhcJ8PV9z+5f
 Bj6P1XLqkcxzKzq9EtUvn3PnTxHqk2sme4DtiEkpnOH8zk8j36cV5vfw30yokLeaQuWBGCM8dR
 X2lcfCqCMPbtHtjHA4/hBJAzXPXPw2soGnaNfmkwMgdhg/pX4rHGQ6n9ZRyio0fD17pc8qxyRI
 zK4OVPVSO1Z4tLqzkAQvnGee1fX934AMQmWOIkhwQcZ+XHNc9c+ByZ8rb5Zl4x1z059qt4uJM8
 pmlc+fIp7q7jFtMgOTwduGz7VDi7024Vbg/wAXykHkD39vSvfLrwLFHBJcQxFfKwenJYdcfrU0
 /gCz1G0JmUrKiDA7k+tCxMTGWXTSM/4a/GHxN8P4nTTrgvDM4zE3KZJ5OORmv0T+GPxa0D4jae
 rQyLb6inyyQE4yR3XPUGvzMl8Hy2EDt5X+sYEE5zlR0x0q1oN9qHhm9W6tHaGVGRxjg7hyPavt
 eF+NsVltROD5qfWLeny7M/IfEXwjy7P6ElWgoVuk0tb+fdeT+R+wdFcD8M/F8fjbwfZa0WBucG
 K4Ufwypwfz613oOa/rHL8fTxNCGIpP3ZK5/mjn+SV8uxlXBYlWnBtP5dfRi0UUV2HjhRRRQAUU
 UUAFFFFABRRRQAUUUUAFFFFABRRRQB//0fpyiiiv7IP8hwopMimGQY4oAkoqIyCmb89aBXLFIS
 B1qsWNIWY8E0C5izvXtTfMGcVAM9q+P/jJ+0/a+FNWufBXgmFb/V4lxNcE5igJ6gf3mFePnee4
 bL6Lr4mVl07t9kfW8G8F5hnuLWDwELvdvpFd2/6bOs+LH7R2l+CtUk8I+HLf+09dwVPP7uGTHA
 b1PtXifhH4Z+Mfi7qMnijXWkjmvJAZZmHHlnkBc9BzxisL9n74T3/xL8VXHi3WzutlkLyO2SXY
 nJyfev1FsNPsNItYbGwjWOOJcAAADj6V/I/GnHOJzCq+d+6npHov82f6g+E3g9l+R4Zexh77S5
 pveT6+ivskeb+D/hpong+1MltEHuHIBkblvl4HJrqbi0iw0suBgdO1bNxcIqsR1XgfWuC1LUnd
 zGxIAzivz11JPWTP3WGGhFWijn9bis5pQq/KSOf8a5b+yrR4/LC8Ak/nV3ULs+b8gzkZJ9DTbS
 Xew5GD/IUlI25UT2fhixl5ljyR/StY+A9JumD+Uu/6dquWMgOAenpXT2c3PJ6VnOb7lqnF7o86
 n+GGnyNiOLjnOOOvJpJfhBZXkZVFKNjgjggHsMV7VE6SABOCetdDbRsw3dc1m6kl1L+rQe6Pjv
 WPgxfoQpi86KLd5agfxMMA81y+ufBGF7ZCIytxGCY2x6DAHuK/QaG3V8q45PHNNu/DdvcpwoJH
 tW8MbNHn4jJqU07bn57/AActdX8OeK7nwy7GCS5VpNnZmReq9jnFfVOl3xv7KO6I2s2Qw9GU4I
 /SqvjL4aX5lTxLojCDUtMRjEQMliclgexB6VzXgHV2v9Iksb8GLVbKRvtcTDBR5CWH4EdDX9J+
 DnEsKn+xylrrp923nufwT9KrgGpSpf2pCndJxTaWq3Tv5fD8zvfMOeOKXzT1qKiv6BP4VuTCXn
 mneYOe+Kr0UBcsCQHrTtwqrS5NA+YtZB6GlqoCRTt7etAcxZoquJDUgkHfigdySimhuKUEHpQM
 WiiigAooooA//9L6V8w+lRk5pKM1/ZB/kHcMmiiigVwziiiigOYPeiiqGq6nZ6Lpt1q+oyCK1s
 42lkY8AKgyamc1FOUnojSjSnUnGnTV23ZLu2fOH7Svxrj+G3hw6DocqnxDqqFY/wDphE3DSH0P
 92vzo8C+ENQ8RasoVmmubyUF5G+YncfetzVrvW/i58RNS8VXWXiuJyI1PRYgcIAOwAAr7E+EHg
 KHTNSslVQZGZAx/uknJI+nav5D454pnmOKlO/uR0ivLv6s/wBTvCDw1o5DltPDpfvZWdR9329F
 sj7L+GnhG18E+DbXTbdAkjqGcgYJbFdEbo3B/dZ2sMjPGRXYnTZF02OZVBCLnPoBwK4a6kFu5O
 eF6e+7rX5bUTbuf01h4xhBRXQj1ONYbUSZxgE/X1ryrU7l2lklGTsGFUfrmvQtU1SKSzeLdkZy
 D7Dr+ded6jIJYDs+6XyCPQjpUWHKSvocbe3TF8E88HHYVPZXcThWJ5BPtjFYOoFvNcucAHp6+l
 WrNlAyPu9azKtoei2cyMm5W5J/Kuo065DNg/MOnvXnFnOzOXHIbj8a7nRUJKyjjaefx607I0pq
 71Ov0+6QThQQSe1eh2LrtUgZH6V5YN0cw2Dn3r0LTLmN4hGD8y1FSHU6pRVtDtLeP5wxNa0MbE
 5zWHbsSVHHH9K6O2baM9R6VnBamM1oTG2SQZZc18s/HDw/L4Mu7b4q6DCfJtSI9UijH+stzwGw
 O6nmvriPZInyisnU9Ns9VsbjS7+MS210jRyKwyGVhivWyzMKmDrwxFJ2cWfNcQ5Lh8ywlTBYmN
 4zTTXqfMmkarZa3pltq2nSeZbXaCSNh3DVpV414Qs7/wCG3jPU/hTqp/0NWa50mQnO6Fjlkye4
 7D2r2Mtg4r+5eFc+hmWChiYPV7+T6n+NfifwNW4ezitl9RPlTvF94vb7tn5odRQORkUmR619Ef
 n9mgFLRRQHMFFFFA+YKOtFFA7i7iKXcabRQO5J5jCniQHrxUFFA7loODTsiqdSKxPWgakf/9P6
 QopCQOtJnvX9kH+QKTew6imnNJz3NMag+w4mgHJxTMdzS5xScki40e4457V8vftT+KZdM8FQ+F
 bNj9q16URsF6+Sv3h/wI4FfUGfavjrxdZN8RPjtJYcSWnhuGFMH7pkdwMfXc36V8B4k5z9Vyua
 i7Sn7q+e/wCB+4fR/wCFoZhxHSnNXjRTm/VaR/Fp/I4/4V/DB9MshfXNvsiHl7iOM55OM+oBr6
 28DeHhBepdtHtJbKD69B+ANeh6Z4RA0G3hgj5uI9yKfVQct9M8DivUNH8EyWtmJ2hJYYwSD/F1
 /pX8lYmk+XQ/1DyhxlLUvXEpXQfLU4Y7VIHt/KvK9ZidhIEXaqkEc88rkfzr0nUhNb2XlSKBlT
 3HHG39K4OWaCe3BU7gzfNzg5xgflXkTT6n2EZHmN67MoDZyecemRXP3SuqZ6fLgDtmvQbyOBF3
 vjaf6CuPuSGAKcAk49hWTE1Znn01qzO2/npiprSH5eRyelXJigYljnr+PPSoYGKuHXpWdjqRu6
 ZaGNgCcbjwD2/yK7e1BiXag4Hf/GuPt7jaBt+9jHrXWadMZJPKfDBQPxNNHXCOhsb2WdATwM4O
 fzrr9KlyhOcsDk1xsq75cL75zW1YTSxxgvkcDge9U0bwPSLOd5FX5vnPUV1lqSSVfpivP9Md3G
 VGOf0Fd1Yksygcjg1moiqrQ6q1kDpxxSzIWAK9RRbpgbsdasqiuGVuCuetVy3R41R+8fKf7Snh
 a6vPDtt410QbNV8PSedG44LL/Epx2xWbouqQa3o9lq8BDJdxLIMdMsOR+B4r6W8T6RBrOkXmlS
 qGFzC6YPI5GK+J/hPM9ro+oeGZxtl0O8lg2nqI2O5P5mv3bwQzmVPFVMFJ6SV16r/gH8Y/S/4U
 hWyyjm0I+9TlZ+ktPzserZ4wKMAelM3Uua/pnnZ/ne0n0FOc8UvNNzilyaftBckR3NIGyaTcaM
 +lPnRDpLoPPHBpQCelR5z1p44p3T2BUdfIKXJpmG70ZPpVWMXBodRSEgdeKWkJ3WgU5abSg0D5
 j//U+jsD+I0mTTcik3V/YjbZ/kWtNh+TSZpmaTNKw7khNISKZRQkIHlWKN5XOFQFj9Bya+av2e
 NGXxT4g8QeKpyRJqeo7lBzyqyfIfoK998QmQaBqfk8P9lm2/XYa82/Z8019H0nSITGxkmQXDdi
 dwIK++Bgjnr71+IeMOIfNhqPT3n89Ef2Z9FDL4cmOxT+K8I/LVn6B6DocNyLaxlj2+SoC8fMBg
 OFP4Yr1u28Ok2uG6gY/QD+lYnhCC3kjt7xlIXbuyevoAfoK9GedSRAB269iDX4tKKbP7PjNwWh
 8s/ELS1glns7dQFycjHOBtxj2zXi2oWaWUAmZ9sQJPzHGAPX8P1r6r+JNpp9pZXGt3cixm3HP4
 V+Tnxq+M97MVFhOLW2iGVjUZaQn1+leLisK+a59NhsxioK+rPVNf8AF2mpI7mdERR3NeUa38Wd
 CtbdoY7hDgeuCf8AGviLxB4z1iaN3e5kfcwbYCeo9a8iudU1WSY3JDOXz/CayhgkzOrmsux+gQ
 +KmjE+YZ9qqc457da0rH4maRcSMqTDgDg8enf1r4HsLjVbxGWRWQKD2IOa9G8N2+osFyCSo5z3
 +lOphYpDo5rUlK1j750XxFaX0QZGGcgk/WvRbW5+zBZ1PB9/WvkrwKbmKQQyOQn3ePrmvoNNRL
 RxRPyOpPtXm1aaT0PpMLiG1qexaWYr2IMD8wPTNdXLaNbwo4TG/G72OMCuB8HSSTTrLwYyemeR
 /nNe0XNvG2n3CqMnIYY9h2/KsuboetTldGTBqdtYW3lzsFckhR3JJ4H5V2Wj67p8mJI2BC5VTn
 g4r5N+IF/qcReW2yMKemeM8du57V8o+KviT480eYxaddtGoJHcjHtmuiFFNXPExmb8jcbH6/Qe
 JbFJCksirkjGD1zXQW2sWF0cRShs9Oa/EKH9oPxfbyRJe3hY8AFdw9uB/WvWvDfx51WW9im024
 aRc8jI6g8kjpj1rd4JpaM8OWdxbtyn6zyqD06jp9K+KNUsR4a+NutadGnl22u2a3kfYb4mw3/o
 VemfCP4yQ+LdukayRBen/VEnhv8AZzUfxa01IvFnhjxCF5BuLJj7SoXX9Vr6TgLFzw+c4eV7Xk
 l8nofn/jLl9LMOFMfG17QlJesVdfijHooor+2z/H24uTRk0lJnnFAx2aXdTaKLAPyKWo6KXKBJ
 S5NR5NG40WfQdyUGjr0pmaXNNTaB2e45cjgilJApu404c9apTuZukraH/9X6Ioo5o4r+xD/Iq4
 UUUUC5goopCQKBXM/WA7aTerH94wyAfXaao/DXT7u5m0+JVIWKHaGIIOAMr+ZU/lWvcxLc28lu
 TgSqUz/vDFavw/d4beUeUzRqiBmPQFece24tX4P4wtqvh/R/mj+4folxhLBY1deeP5P/AIJ9m+
 CzJb6HZwuPlKZ5OTzyBXXy3DQrGv8AEDgfRea5Xw6zNZW8iH/lmvH1FP1vVxpyNdSjKwqWHvgH
 j9K/GYO5/ZNSjrY+W/2lfGdzDaS6WNwjGw4U4LMv3s47ZIzX5NeMdNuPE+oy3Lh0cYDLj5V9AM
 YxwK+5vjn48i1nXltLsKRHksueGBOcf59K+QvFHifSdIikRGRYRgs/AJOM5/pWOKqr4ex1YXCu
 93seaab8Obe5kV747gD34NezaP4F8MW0IElujtt7gHp+lfLWvfGUK5TTj5eOFOAWbnsB/WuLn+
 K3igRtPKH8hDk72I4z6LiuB05y2OiWJoQdj7d1PwvoHkedHHFEyjG3C/SvNr+7i0d/NiiQopwS
 vFeUaPq2r+JdFTWZbMyWcsxt0aKQ+Y0ioJCVQ8sAGGSOnSuXu/El3ZRyNFcm6tV4kR8mRc9x9O
 +amWFqLVjp46k37qPpXQ/HGnllRcRsTivTNM8Uwudu/huMj3r4EsdeZ5xJG23JGOa948JanNfR
 xMCdzEAA9ciuOvScT38DXjPRH6PfDC5+0JGI+VxgfX1r6BvYvs8Ix3GPzr5Q+EF+bR4VnJzkA+
 lfSPjLXo7TQJrndtwAAfc+nvXlNu9j3Ie7G55v4outDtYZVvGU9cg9eOK+U/F7eE9bYxxDByfu
 kDtXF/F34iTabLItzM0jPghF++zHsB6Y6mvlx/HXiNrpZ53FuH+ZYYhufb7n/PNenQoTSufL43
 F0pS95H1Tovws8LX9wJZI3nBIOCcbj6Hj1r0zw78AWnMlzpkPkYBwOuCefevkbwj8abm3n+aWZ
 xASXK7W24IyW44AzX3J8OPjSYiI5ZVuUXlkcBJQD1OO/St3KpBnFCOFqOy0MW58Ha14NnS+Akh
 azYOJc457YHFfTGo+ID4z+HFjrEq/6VZahahyO7FlXd+Iaupll0Hx74bea1YTJIvGP4W9x2xXE
 aJo0mmeBtb0qRNr297bsD6rHLH9enNdWUYmP1yjNu1px/NHk8SZdL+zsXSirqVOfz91j6KgwQT
 7Gl3Gv7yP8UXpuTUVFuNOD+tAXH0dsU3cKXNA0xaKM0UD5gooooDmClB7UlFBVxcmpFPWoqctF
 gP/W+iKQkCot1HLHiv7EP8h1rsS5pu4npTNr05AQeaVyowd9RC+elIA7sEUZLHAHqTTtlWre6e
 wFxqMQDS2VvNOgIyN8cZK/kea4syxyw2HqYhq6im/uVz6HhXh2eZ5nhsthKzrTjBPtzSSv+JS8
 ZWeueDtFbWLyxZht3KuQOT0zUXwx1z+3NC+0ICGcAzr/ALakg89OQN2O2K8/8F/Fi+8d2c/gHx
 jP588sga2mI5dWYb4j745T3r0/wB8OdZ8JR+KLizmMlhFcRPbxk/M0bxlZT6cZr+Qs54wxmbVY
 yxLVleySta5/rpkPghlXCGGnSy6L5nbmbbfNbRPst9kkfb/h5Fi0u1CHOI1OT1PHGa434l3N/B
 4evzaW5fbGTkEAkqc5AzXW6du8iJYW2KFAAx2xxXN/EVJJdLW0UnE52vjglSDke2a8XmtC59LC
 jzVEj8Xvibd6xPrrJHbzyykjAXDseNxGB614LdfC7X/HXieHSvE18fD+nSMHYty5Vjj6A/Wv1E
 1fw3YaVcy3dpaI0qPx3dyvqTyR3NfOvjTQ7m5dpo3IlblgoDAnHfPU15ft7O561TL248qZ8SeI
 f2f9K8M6jNBoeqDUwkh2yOfmIHqPrWQ3g8rbva3SNsY/MRg98n9a9i8QeENbaWS4gZmctnIyDX
 A3HhfxpEzeVJIykjOcHp2PFbLFuWpwzy2CVuW7IvD8t34YvtIuImE9povneTbt8qgz53Ej1G41
 5b4+ttR1jXrnWLPy7Y3JyUjOF989q9QtfAvjG/lVZEYZOcscDFdTZ/CQo32nWZfOI5Cj7uc1cc
 WoqzZz08rk53hGx88aN4dv5IFLEMxIzt6fnX1X8PvDrwRwzSqVVMEVq6X4QiVxBBDtBxgAcACv
 onwt8OtSuPL+0WzpbjJzt7CuTmlVd2fQ4TCqlu9TpfBzbII0jP7zt7c9a+jde0YeJPCEtnF8sr
 orRntvXkZrxRNDTR5dsClAcdRz15r3rw5eC50yNFzzxzXl11aR9HQp80HFn5U/GbwD4kt3udQR
 f9Olbazt1VB1VR2B5r57gi1KK7t5VtxvjhaFxnOckHP1yK/aD4heBNP8S2ki3EHLA/MByG+tfF
 /iX4FXFjeiayYyIzDgjBwB7Yr1MNjItWZ8fmWSyjK+6Plz4f8Agext7HW7C8svPOoqgiY5LRNn
 BIOOnqOc17L4t8C+MfFmtxa34C0uTT5EVY2CAgSMowC3qfevVPD3h2TQCIpojlcdccfmK9u0Dx
 tf6eES3hyrNyVUA5/MVVTGtXSRw0cqpp36nnnwP1r4n+BtafRvHdjiG+VVVw3yiT1OenvX3a2m
 ebaXXlbZIp7d2kI6Z28H65ryXSYIfEEkj39sQXGQxYnB61714XsI7XT/ALNJzkYz6j3rxZVv3i
 klY9uGFfs3GWp4QuGTP97n86Ngp8sMttLJbTDDxMykfQkUyv8AQPLcT7XD06q+1FP70f4jcSZd
 9VzDEYWS1hOUfuk0BQAetIFyDmnZNGTXbzHiezQ0L68U3kdak69aRgCafMTOlfYZk0/d3o2UbK
 dzP2Mhdwp2RUZQ005HWmTJNbk9FQhiKduwKCbklLTQwpaCrn//1/oQp6UoAXmjOaSv7Buf5IqK
 XQfkU3NJSZFIoWnSym30jWrpesOn3JHsWXZn/wAeqEuOxq1bWratb6loyY339ncQp/v7dy/mVF
 eBxbCTyvEqG/JL8j9J8G61KHFuVTrO0VXp3/8AA0fL3wH046v8evCtns3A3glYf7MSs5J/LNfo
 94TlvbrxFr1nqMHlJK07RIcYkhYHYcex4/Kvhv8AZ/ki8I+JfF/j68TMvh7SHSAf9PN04iHXuF
 DVyHh/43eMX+Lug30l4ZVubgWckeMBopzsYfUZGPcV/ElCuqcFdat/8A/3A4jwE8TXq8u0Vb57
 /lY/W/SGO+GEjBUJn8RUfjf5rcxwReZKyjaPcZqhb3qwqkijHyK2enbNdZaIt9CJLgZJG0evzD
 mvape9FxPy2r+7mqh8q654VNpE8u4meZirN1wmMsRn6D868B1/w6ouHIUADknsecH8BX3p4z0d
 Y7Z3IOyGL73+0x6fj/Svh7x9qH2d5YIGwiL06A5yfyzXmYmhyto+hy6uqsbnkOpaXpwuPJjAcN
 3HbFZh0OAZ2xAei96ti5V23ep/M1fiZGcOT04PevPuesqaOek02LyyAgGAOnrXL6jBa24LStnB
 yQcdBXd6lNHBG0pHQZ+o9q+cPEHiSW/1A6eHO1mHAPUk4q1qZzikfQfwo13w/ceJ7ewlgR1kcB
 iwyBX6n2ug6GbGNLOJdzLhQoGOR6V+dXwX8E6DZCPVJzGjADG5u5OCa+yrX4peFPCmiSzQ3KXN
 2gkEYLZ2krjP0ya9rASUY2keJj8POTTp7nzh48vLaw16a2UhURyv58fzNdt4FljuYVhZsbWUD6
 jkV8reJfEv9peI5JSdyMQ2SfXmvo/4UNJOyzFRKiAPg+vrXhV9Ztn1dBWj8j2adbeB2W4UbCT1
 HX0rzDXbjQpZmWFgsgbAHcH/AAr2DUUsr5mjb93sGeT3r5t+L/hqXTYofEujufMBKSgHgjqCBW
 cqepmtVdms/hnT7n59gKycE8U+18HWMMqeSgG05PHAryjwV41ujqkQvZS1mqEOh67h0NfRdhf2
 9/Iv2X5gVDZqCnQi9Wjd0e2hik8po9voRXo2nQKI8J25rmNNt8xiVh9c11drFLDKjxjKHqPX8a
 UaetzhxdtkeE+NLdrTxFc7hgSYcfQj/GuU8wZwOa9R+KlsqXtleKMeZGyk+6nI/nXk9f2/wDiv
 b5Nhp/3bfdp+h/jV45ZX9T4tzCj0c3L/AMDSl+pa3DtS5qpTg5FfX8p+UcxazRVbe2aeJfUUuU
 fMTZpc1EJAacGBpWHckyaa2DSZzS0gauJtHvTQrHpT6UHFVzGbpRZHhvSlDGpN1NwOxxmhSIdL
 sf/Q+gi2O1M832qHJpK/sOx/kjzDy5puSaSkzTJFqzZ3Ulldw3kJw8Dq4+qnNVM0m41M4KScZb
 M0oYiVKpGrTdpRaafZrYx/G3hw6RZ/Ee40uPbaanb6bexbRwI5JHLdPQtg18f/AAxso9Q+Knhm
 O6OIxqduWPoFcH+lfobeXUQ0DSbu+H+hXsNzo92SMgIzB4mP+4zCuT8R/A7wp8OdY8PapoIeW9
 jaK6mLtkNsGSfQAk8Cv4a4kyuWFx1XDRWkJNfK+n4H+7/AnGcc04fwuZT+KvTjL58qUvuaPre0
 dZbO1hZg6iKLBz1yP/rV2tnqMVu0aSEAg9P5cfnXkmkTzQ3Ol2F1gSbeMdASPmH04OPStjXdQM
 cUptuXdCFxwQf8axpVOXU8KpS51yln4jeMbe30mWzDqskw6E9F6kk/7v61+cvjbXDf6lcSQnMT
 ucds44H4V7L8U/E7gvZISxePHPXgYx+hr5TuZJJJtwJz+dc+OrXPSy2kqeiNqxnaR0j25yevti
 umhjDKXPAT8BzXPaVAin5idx5/IVz3jvxbHoGnfY7Uh7mYEIgPP1PsK86Mb6HsyrJK5zfjbxgi
 SNYWrjPIJ9K8X1UmSNJ7YBXjYN7nBzVHe243F5JuklY5yc5P41Uu9Uig3K5DBR0HXntW/I1scE
 sQr3bPUdI+KUVrB9mvp5ICg+6O/wBK4fXvin45urhW0BIlgB6SZJI7Z5ryHUtbDTs+OAeB1yfT
 8Ks6fqjRnzS3DdvTtVxjbcynmil7p9CeHPGF3qs8a6nB5F4y845Q4Hb0r7s+EviNbaBYtw3zDm
 vzp8La9opuBFcF5JsArjnH16cV7tZfG3TPDkQtd6qEGzI5PPp9KwrRvsa0c1jFWep9KfEG8+I1
 34gtrrQb8W+nRHJt0HzyHuXb39K9AvHv9U0WCHUACsQO8dTvIwPwxXyZpvx6FxeW6D9+hYAkAY
 2sQNx//XX0B4U+JOi6rb4lk2id8KjccjIHXuelYuCOmGb05Jx2PnbxIZ/C/iPyTlI5Gznt81e+
 +AfFaQrGZZvlA578VyvxQ8M2viLSzd2DgyAFlwefUV5P8NdfNveix1H5JbVtjA98d/pUzhZXO2
 hiYy0P060WSK4RTHyrqMd+MV2UQgaMGM7mXIAFeYeCr6G9s4pYT1UNx0xXbIWglMhOA5AHfrWl
 Op1POxcPePP/AIqR5sLKXukrL+Yz/SvEs17r8TQz6FG/YXCj81NeE59eK/rnwirOeSwT6Skvxv
 8Aqf5UfSuwsaXF9WUftQg362t+g7d60uaZRX6cfzZzElFR0uaB8w+jNMBNOzQNMk3mnCTAqKig
 q5YEimpMg1TpQSKXKPmLdFQCQ96lDZqXEpM//9H3fPpSZptHNf2If5G6i5pKOaCPSgfK2FJnnA
 p6rjmlwKXMaKiz3TwxpSr4LtILy1F0moTvMM/djT7mT7/LxWr491vTfJ05ZYwLdZIRK+Mny4sE
 Y7/wn8awfC/xD0/TfDVvpl+mJLEnnsyAlh+OeK8M8V/EM63r0nmbY7UzQyRrg8IigEfQkkkd8V
 /HHHEasc0xHtlZuT+7p8rH+w/hLisHLhHL/qc04wpxWjvaVlzJ9ne57P4N1galqtom8s8LO/Oc
 fPnIz7cYHvW/rlxKt+yE/eHfjpz/APrrwzwjqD22vae1yhgIEEsm7ht0nykt9f6ivZvEqGC4Zg
 37pQTweefevktbH3+AqqSZ8ifEq4lOsOVUsPKODnuPT868St/NeZVP8DAfhXs3j1BGbq6ZiPs7
 iNR3G/IJ/QV43Yl47kpLwAc89cZ6/jXLXO2jJo6XU9Ss9A0W41i5+UW0bOxz6Dt718Ea7471LV
 9TvNYnlPmSPsjXsvPQfQV9d/Ge2uJfhffy24J2tGWx/d3c5r4g0/SJLoLNdjCxkbFH8RYnk/lV
 YeOl2cOYV5tqKOns729vf3rgsyrtyeMHufqeK3otFlljeKRCrdeev5+4rMOsaBo8YW8u4451Ls
 VZxlSMY4rf0fxRp2pl/JuQYlAYkD16HNayjbcjDpSdmzAbwU7XDRFsqpByehHFdLYeBYZbKaIy
 nJZQpA7dTXcRW1vbW0V7do/kSnaJNpwc9MV1VjFbG7i0+2jla5kG5U2HkDnNI9ajl1I4qw8BWY
 tiplMUx3HcucdOB+ddvpXwG/tKUSvMXhkXcj44V1zlT7HFeiw+ENZtLVb2WwlEOVQtt43t0BPq
 e1fSnhHw14vj01r9tLxZwgE7gB078n1OaylNM9OGX4dQ1sfI6fBG60u6ZrZSsc+4JjoG27VP03
 Gu8svhzq+l+HNaupIS0tiI3hPOWZDuOMeoY19NSa+IntI7/TpI2vVJgHlHEu3+7gHpVPxZ478P
 aTp7wXFxDb+YuGSRgp9D1x7is2zjq5bSWqZ8t3firVdOe3Fwx8gxoGByQvygHOPTvXkPjLxN/Y
 +rRa/bAh4nHmqp+9GTyR617J4iXw9fpaz6ZfRMJd4WMMGyzfTtxXifi/T7GOKWzTdPFG2wH0Vu
 UJ+mfypRW6Z4FWpOnPRn6P8AwL8TR61ocU0bb4yoGc9Qa+m0AnXDHORXxh+zBoc9n4Cg1AvhSd
 g44wBjr7GvryzuHT92eTGFzgdS3AArzoztJo+jk7xTZyfxMYppNrHnh5yQD/sqf8a8SZQxzXqH
 xQ1u2NzZaOG/exh5ip7oyqM9e2a8u31/Wngzjo1MpdJbxk/x1P8AMv6W2Tzp8RxxctY1IR++N0
 0GwetLt460bhS5FfrvMfyu6Uew1eetIAe9SUU+Yj2KI+nFFOZd3tShafMZuixlOzSFTTQc0zJp
 rcfnmnVHRQFyTNPU1DmpFNA0z//S922470oFIWFIX9K/sByP8mlFLYfRUO40bjS5huQ/f7ZpN/
 tUdFLmEpCXMSyWcyy8RujAkHGMjGfzNeXvdWOr6jDJdMYrO2QpGoGTlNq/Me4I3NnpyBXXeN76
 6sPDzSWYJdmAJ6KqgZyzdAOK8AsL97e5iiuXYtex7iAAdoDDYo9228/X2r+YPFzG+1zGEf5Vb8
 T/AEZ+jVlH1TIpzu/3kub8LH0BqWrK3iH+3RMRbTM6oFxgJA525J9TgjvgV9D6vPHc6bHcGQlZ
 0RuDnJboPw4zXwY3iGLWr6108q6W9qrN8mdrEKAZGPr/AI19T+F/E5vdGtY2QLBb2zXIUjllJA
 Xjvu3cD6GvzKSVj+jMDW5WeSePoLldRlgK71uozJuyMkqScD8RXjQnijuVdlA81goB6gn3r6A8
 WrEY47q6O4oSyY5GM8qPxOK8K1i32XjPLGIw+JRx0DLwMenIrlrR7Hu0qp39nolv4m8Jahocy+
 YbqORfm55xx/8AWr5A1LwLP4fu/ss8OGtMYPYlq+rfA+pSW8sUaMAIz0z1B6k/iazPiLYQzF7l
 CApBIwO9cynZWFOHNLU/Mz4g/CeSa9l1PSpSkspZyGyVJY5Iz2rM8IXuq+EXkg1a1cJ5YTIGRk
 f0r7Ku9OglfyJV+cjNcpfeG0TkRDbk84/nXesU5w9nIunldJz572Z65pfj3wHNZ+HdClvIHm1F
 o0KnpDsXcWf+7yAB9a9j8G3vgePxZdT6lqVn5qRGOFjIpGAdxAI9hXxa/hfR3kWW4tkYgYwVGa
 6/TPBHhCeIZswWcoMgsGHPPQ1zVcLpoz0KWUOWkJH6c2/xC+FD+GLnTbnWLTdNNbpGoYczCQMu
 PYKrEntXufhfx18OrzS7y2m1a2WFIGwqyKSwxz/Svy68PeAfCFpj+z4kHmqRvZd3UY79PrXU6L
 4ctZZZISBEuTlgAGyAV4I7cnisYYZpptkvhqb05j9N5LrwPcT+FbaO5t5r60kuJYwrKWEYQGQk
 ducKPrX4cft5+NG+IfxP0jwf8PoXuLq1ttsq2udu523EsRxwTyTX2z4d+G1xdajJeWRljaRCjy
 K7ZC4wBknpg9K6Wx+CGleHXaW2soTfXWS04Ub8E8jNdlJxpy55K9jKXDDWjqn50/AH9nfxvc6w
 ut+I7iT7PpSbjDuJUswxtz9OteyeIPCQN1Lp6Aqbh8cDnHTqc1+jln4TsvCvhlLCKMCSYlnOOS
 T2r5w8ReH2bx1BbomRuQLx6t/hXn18U5SbtYUsJGLSWx9E/Drw9FoHgzTdFjwnlRB5Bju/P516
 VYOjeYAuc4ZT055P9OK5aw8xfJgJVFIB46nBAH6V1FrcolrC23azsq88YJ6n8BXnx1dzsc9LHy
 d8bdXaPxykds3z6daxRzKD/wA9Nz4x64x+VWrG4S8061u4zkTRq34kV83/ABL8anU/iFrV9Cd6
 S3DZIP8AyyX5Iz+g/M17t4Wnjl0G0MRBQICuP7p5Ar+kPBuvGE5UU90fwp9KvCyr4SGKa0jKy9
 Do+1Lmod/NO31/QVj+ELku49qUMai3U7OaeoXJt4pcioKUHFHMNSJ6TAqPeaUP60+Ydx5Gaagz
 1p2RS1VyZU03cj4py96dQRk80+YydDsf/9P3Cimk460wvmv6+5T/ACYbuS0wtg1Hmkp8oh5c0m
 402kyKdgMLxhFa6losOkXjtFHd3MSb1baFJ4Bf1T1r4n8eeKLvR5ZdJKmDUY38iRccxiBmTAbu
 xC84r7h1y3ju9JniddxTbIPrGQw/PFfE/iTwp4m8S6xHr97B/ol9MzJg85XBctzkFQ2eeea/mb
 xay/2eYKovtI/0Q+jXnf1nIXSe9OTX6mb4a1qUadc6leXgjaZI7RIyMoi7T5mTx8xxgc+ua9z8
 F+KLgwyxT3gtxKgECk8iKIHGP+BL+WMdq+eNLi0220q/0YTh/wDS5NzOpYyochTGvqDn0xk1se
 I9RudCkGmKy3Mtjcb5PKIGYUSMqB3H3un4V+Z8t1Y/oRVrO59YTeJbTXdMeR1aOSIwW8cY5LRk
 sufqCR9c15frGqWt7rtmkLFoWDxsmRlYowSu49jx+leeT+J44rBkFw8YiuomUqCHKwD5iMdcse
 vTiuFsvFl1NcSSxqqySwXCIO3zhwzN7qpZh74rmlRkz18Piz1rSteisjbSBiEnBcE9uSBn8ia6
 i91Nb8LFLP5m/oOwHpXzamrCMCBJRLFDDgFc9HbqfQ//AFq208Yxie3igcNKdoyM4A3N19ea4K
 2Ha1PRpYuLep3/AIhgeyUTW6h2BHJ7VXtpbWdQpcHaPmB4FdRboms6UowGc7juB6g9B+Fee3sb
 aVclJBuc5VR2Gecn6CsKb1PWgaFzpthcSlmGB2IPr0rd0vQYhLGttPtKnJY8gDFcB9pnYSXMzE
 AAFV7tjp/9erFr4huNFtFlk+YuVLE9uDwB35z+ldKqM2hmDpbH0To+nxfbIoDcjzJBgDHBxXsP
 h/w9pq3Xmzy7ldmJ6Abhn9BXwc3j/U5NYs7m3kCzOyKPSNQuCT2yK9g8GePr+7t4rdiSXeRAw9
 VPBz79DUVJtalx4hl2P0P8PaRHZRCWGTIb5yCePb9K7i2tYg3mTAZ/WvC/BmrXV1bQoGMixBCe
 c/ezwPXGK9YiuJYEe4uPuqMLu/WuZ1n1OmOIdVcyIfENwJbqOBDnYM/j6VxS+HTP4jTUpU3BT1
 I5GM9/rXTWMUmramLk/wCryfpx/wDXqa/1OKw1NLWcbRPuAPoQMgn9a5Z6u5FS0VZm1aqkK7pC
 AIvbJ9f0rzf4meOLfwp8P9W1GOVVuUSWOA5/iYAAj6Zzn2pnirxW1lod3LC4Ek2Y4+cYZlLA/g
 oya+EvjF8QF1m+g0+KXNnbQGORT3diQxx6g9/atsLQcmj5/MswUINRep89eJNSmubi7u0YsZiZ
 VIboVI+X35r1/wCBnizV579LJrxpbZcoY2O7GACMV82S3oNtdW+4mUOQB2XaR/M8V6V8LtTFn4
 mSSHKGQhj+P0Ar9Q4HrSpZhTadj8H8U8FDE5LXpyV9D9EiRwR3oqpazC4t45U5DAVYBr+uk01d
 H+XFSLjJxY+nbj61HnNLmmTckD0u+o6KLAT7gelLVenBiKnlAnzShjUQcd6UHNKw7k++n1Xp6k
 9BzTTKUj//1PaM0U0t6VJ5We9f2Bc/yYSb2I91Ju5NKBuOKkVMHmi4Ri2Rjk0qruJGal2qMk0h
 YKMt0FS5I1hQu0jn9f1uy0GxuJrmRfMWF5AhIBIGBxnvk8Dua+T/ABL4ku7/AMNahdWsexbW4e
 XHCM7SICy8dvlIJPce9anjbWpvEGqXM9wxW2WTbGCf4UPHH61Q1bQdL1jw1LNLdmS61d5t8cQx
 HH5km1HY/wAEauSM9M/Wv5g43zSWY4t1Psx0j6f8E/0l8IeEaeQ5XHDp3nO0p/4mtl5Lb8T5uT
 xHp15rt5cLcSROyFbbBULHuG1ixOARhm4HqOeKxtU1Xde3tpqBdrovJKsicbdzBmJ7kEDA9M5r
 J1c2+hzRaglnugkdY4HzuXdGxJYH0PB6VK+lObK/iW4S71DUJYIops7cQyEhjk9s4BPpXwXJ0P
 1vm6nR6X4ss73xCs4kZNFjk8pAw3sI2IJBHf3+tH9uSazr93e2ce5JWlBjjADJDEmS4xxs29T6
 CvH9KnfQ9Rllt2SaGxzIGU7lJU7R7nJxnis6LVLi1ihu4CSdjFlJwpLs2V7cEYq/ZO2paxFtj3
 DRLyR9GnbKB7mVoy3pHGm9mz7nAH402zvI476JVkVeSp6cDoTn2rn9Hf8As7R0htD532hmO8jD
 HhjnB42jb+OPwqhdNa3tjaWZ/dXmJ7iWQtndsIwvGMZAz/Ws50rpo7aWI2Z9o+DNWtJ4ljjIWN
 fuj27Vf8QaTBeXPnqACqk5/T9a+U/C3ii5RiochYlG0dOWIA/KvoLS/EUl5a+Q6iUqsJdwfuiQ
 HAJ92G3614FXDyhI+swmOjNK5AdNZWLTOAi8jjI245rD8VadZJLbNZ3SXivErEKCNjA7Sje/uO
 taVzrLXENykcojAUKue7ueF/IE1Po0Nm0ukWZxNLfJdNIw6RmIMy5+m3J9qEXiKiex57B4ZZ2j
 REMcsmck/dA64Hc16r4Z8O3tnHbvbqRMzlYos8Dld5wPWjRfs+UjIWSWJzLIMZ2qELBc+pYgn6
 AV6Z4dkjhv47qNtoDsTnkqsQB49NxwMe9E9rHJofZPwu0X+ztIsYrhg1xFE28e7kEZ+gGB/wDX
 r0nxBZTPBHGgzuxgfnXivw/8R28t7Ml+7IhQOoB2lVJHfvjNerN4jtrS6aO+lDrGWQYbkE8DI9
 c8VhOCse1hMRFRWpveG7Qw6f05xgn/AAr55+KHi250rxHJdwDzI7BRuQdewzj6nFe3eKfGWl+F
 NBLMQNoK4z1bHT8+a/NPx18TZNR1qYW4wr+c5LHhxkbix9cilSpNnmZrmC2R2PxG+IUljbziec
 MiRMI0B+9Nhec+h6V8aatqZkuI55HLLJvzk55LbyT9dxqfx/4ul1SZ3i+W2GQHxk8Nnj9K8sbV
 Hu7Z5JEIZNzJn+6g6++B1r26GHUUfG4vEuTubcs1mNUeZGMeWOQx7Dk8/wCc16x8NovO1G3vAc
 q5wH6Aqp6D8+1fPKyC7llincDYoYMehUkAYOO4NfZ3wW8Htc2FjcIpeNcsDn5cH0/KvuuDMDOt
 i1ZbH5d4k5zDC5ZN1Ha+h9iaCMadGOvHBrbGACAetUoIhbwJCvRQKl3HrX9VUouMUj/NTG1lOt
 Ka6sk8setR7Sff6U/eRS7x2rXmOJ04jCCp54ozUwkBOKGXeQQcU+Yl0uqZFnNLTvK96Yy7PfNO
 5DhJK7FpQcVGKdmgm5JuNSA55qDrT04NKwz/1fbVXbknvS7hUJf0phOa/ry5/lDdLYmL44xUbN
 6daZSE4oE2OJJqlqTumnXbR/eET4+u01a3elYfiLVLPS9HvJrqRQRE4C5G5iRgYHXqa4M0xEKW
 HqTm7Kz/ACPd4XwNXEZjQpUYOTco6fNfh3PjjULtnmaInftHC5wM49/Ss3VtafS4ZvsT+es1nL
 ZEplQRJ8xP0Bzj3ANNv32zAgFh3789PWueumaZ44SAd7EEZIHoCx5wK/mJ2sf6YqTvoeWXUWk3
 ENvpkKvJdSFooEYE4kkdGRj2YHBXjpmsrXbGW1jkgurcxx+SPszIQuJXZWbP95QWwo7Aity/06
 40/UrSewt/tEkEylGHygIp9fqOK88svEOmyX1vBrKPIk9xtkKPhpI2znyywwrdMH2968bFUUuh
 7OHxDkc7bo1q8lkEDTzHyeW7ooPA9GY9faumsdDvropot7ADLbTymJQ6BWkBCyFz/EAqjZhsZ6
 dawJNJ+1zfbLZ2iVH2iQggqhI27z9Dk+9Xvsg0y6RXuReSLNJEyI3ythcfK3cbufQ8dqw5Tpcj
 t722sL7XxoqvJa3yPCsoODGu4/vAoGPug5x3Oa4DxT4isptPg0iz2SJNdOovNu2UwxKFC7R/Cz
 Hcc8kr6VX03Uzd3/8AaV45gW3idJZFyXJbIWTHqu7GM8kV5zDPHL9quLo/JApKIRjndgAfXOTU
 ciuaKsz17TNUSSC1vYpdxMZsQp++xdQYycdfusc+1e2aNq32TV9Et7VHdLyXcwY5WUwyYUHH8K
 sHb8vSvn/wE+np4bu5LnLyR3ltcR7QNyxqjo5/76IUD1YGvX9GF74V1tJGclrOSWSyL4OQ4A57
 AgO2cdCPxrgxFJM9HC4iSW56Rpd0H0jWCyBpdP1BAu4Z3yuhHlpjliDtHp1pYPEH9m6lq1pp6g
 FIlt8nlleRAJcejNyOK4vQNQ1W60ca/PuM91cXsts4KiPEKbdxXqT5r4X3HXisu+vobWC3dZGl
 ubiUuwXHzMCAgHfkk/pXnSw+9zv+tNnuugpd3F/c2EbbVQEOw4VpMZI+mFOT6D3r0LSNTt47sR
 WbecEbqT8zEHlgD0AHSvGRrUVhfS6NbT/u7S2IkZOPMuJNu4n2VjtHsPetHQdQlttVhljcuWt5
 Yw3PMgjJZh64PFcsoNHQsT0Pou38V29rbRTQTnzIMDKt/e/h+ldnY+LH1LTbfUN7IEZoJjknBi
 UMh5653DJz2r480/UpJJYLbzBHA5Uk5BZ3ccBR3I9TwK9E1rxE/wDZ02m2LEJpkzpOqH93umRl
 Ge5IEbAk9wTimqbuZ1MW7WL3xX+L2o+ICLKCQrbwRl2OeruFyR9ORXy9q+sSTaZJLIQgUiNQD8
 xJ+Zie/YVLreqSxXuoW7NmSGd4yRjooOV49+a8u1LVRcWryXKEqzqqnOOnOVPsAK9OjRSseZXq
 yfUk1K/uriwihhOcAyu3T5SQgQ5PGMk/jWJaWl7eXDFicjcm1T1Enytj8DQRDfI78yCQjkHHA4
 56Yx1zXf8AhS0l1XxVa6LYQtNM1wEO0YJHqw9K9GhRc5xpx3bseXjMRGlSlVm7KKv9x7x8KPgd
 b6pbJPrbMqMMKq8fLnI5r7c8NeHdP8M6bFptggWKIYAAxUGgaKulWkUTAblUA/Wul3V/VXDHDd
 HA0VaPvdWf5w+JXiJis4xMo+0vTvouhJuo3UzNLX1R+VklFR0oNAEmadvxUW6lyKAJhJ+FP3qe
 Kr570UD5iXy/ek8tqbuNTB80+Yn2cWQgE9BT1OTTgOPSmFSvv71SZm6bR//W9kppNZ2paraaTA
 bi7Y4H8KjLGvmvx1+03onhhhb2tuGcqSWY5I5wOBxX9K5pxfgMJP2dSd5dlr/wPxP86uGvCTO8
 0pqtRpcsHtKTsn6dWvO1j6lHNRTTQW6NJcSrEiKWYscAKBkmvgj/AIa1OtyvptqiMFAJ2kozMT
 jaPavQL/xHe3nwsm8Rzb4brVJZYssxOYYMKT9C+7j2FfI4vxLi7xw1L5y/yX+Z+s5N9HCUWp5l
 idP5YL/25/5Glrfxr1PxT4gu/D/ghhZ6XpgVr7UGHzgOdqJHngM56Dk4BNcFd62JXaV74zGXcS
 WYt09Sc1xWg6Zc6d8LrS8jwLvXJ7i+dv7xEghhB4zhFD4PYscV4xpnjK4tdTn0bVCIpbZmKHHy
 MBxkdMjsBX5tmmbYjFS58RJv8vkuh/RnD3C+Ayyl7LA0lBeS1fq938z3651OySRUN7HJIoGe2D
 nGDnqffpWKskM8gyAyNKSTktuycY+gHSvn7WNdErGaFikb4V3LDJOCTyc++Mc10ng7xHcXUwkM
 W6KNvlboD24HpgfjXmcyPoeTQ7nxKSsh+y5kKN/rMfeJJY47/pXy54qj+x6o16rZa1d3jXPy7Q
 mUwO3Jr7A1GDzLV54Dy7MqnGCVPXH8q+YfHGnxRwj7V+8mDk+WhOQxIzk88AcY9ayrRujWg3Fk
 ujXep65ZwyQkyRTQorlWO0sMBwxPQ7j9PSq1lo95dTJLOT5yvvB5ACRg4Ug49Bz+FePWesapoC
 XVpBKVUsdiE/ICTyT6nAwPrXr3ws8T3t39u026uVLbYciUYB+fZhWGScby5HfbXnzhY9aLuQ6p
 9ms7LUZMPBcyShI0AG1sYf5j7cdq8iSe5NndRsx3FwzZ74BHWvXvGhnuLjTlnQx29yX2qMBmLt
 hnz9cfhgV5JKn2deJNxL7iMf3eP6msomsWd38OtYuIhdpaQCSSKIAhuVliDiR0Yds7B06da+gP
 Ed1/Z/iE6VayrIstm6JuO5ljjkDOw9CzDbkdia+dNCLaR/aCacCpmtSDn7x81l3Ad8BeM9816l
 KxuNVt/E8rf8Sy2063klkYnrChj8seuXXOO9c1aPvXN6UtD0WeWLw94b0xfORp/wCzobfaMgB5
 nM07D3UsFz6k+lcJomtXYeynuo8TWUks4P8ACTCWKgZ9GGa47V9cm1sXE1kx8u2LW6KeoRUL5P
 uWOSfequn3hv7OIo2z7SuSPRn+Rvw61z+z0Oj2up7PpGoXEl1p1zJJ/o0omM3GC+5xGB9cnj06
 12bavb2mr6Pp5kKywzB5VH8Cl9mz/gXP5ivPvBt5u0q1hdd72Fy8cg4JAbDKc+gY1DqOoibxId
 QmcCBJFDYHDhGEi8/QCuD2d5NHS5HsfgS5gnmtry5HmSWd0SifwhEBYAj0XGc1Uuteu9N8Parq
 5mE0+qeahcgEG4csSQPZXcAmuC8L3lxJpN/dI2xVmDFAeoZeMH8DV+K6uJND1VHiAhsZLeWNCB
 gN8ySYPrtfp7U1TsyXK5xnjPUJINSv7u1KmSaSVlw3Qu3zf1FefTanJdyW4I2xqxVwR0UnsPzr
 f1CzWXU9Tg2NNHBO6AD7xP8Age9U7XSEt7uCaRC7OSUU8DIGdp9q74rTU46g/wAMSNBC81xJhF
 bCJg4+bPrzX2n+y5oenzeINX1WZVmu7NFUSHrucnOPp618ay6VJbQ2pDCVpnMj849gfoK9S+HX
 xkX4byz3UcZZdQB5B7hz1H+FfT8JYyjhswpV8R8Kf6HwPiVlWKx+SYjCYN+/NWX3q/3o/Vc9aT
 NeE/Cf456L8RD/AGXOgs9RxuQFsrMv+yfX2r3kKQRmv6my7M6GLpKth5Xif5v8QcNYzK8Q8Lja
 bjL813T6iZoDEVIy5703y+M5ruPFcGG7HvTt1RHijmixBOOaKgBNPDUAS5pd1R7qXIoAloqOlB
 NAEgJHNShyagzT1oHc/9fk/iPqU0rtGb3y1YNtXGMDB7k96/Ov4qRMkzzQK0kIyrMeufX8+a9Y
 8XfGm11PeUcybxkdzkjGCTgD8q+cfEHiebUiyOx2sMdf5VpOd2cdONkedaRNJb3gKfNK21cfjy
 eK/Ufw/c6fe/BbRYblSwtrW5Zw5xmV5HZiO+MEDNflgp+z3vmQMR79/wATX334J1GXXPh9b6do
 rvPLcKYGTnCs4yxbHIUAZNaYOVm0LE6o7q88QWtz4T0jTNLQLFZWqgbsMN0jlmz1/vetfN/jfR
 7DxACPkF1Cfkmi+XGex4wT3I969itrjSvDfh2y0SRHv77aykhgibA2B1J4wc15lf6hcXRSGGIw
 wTs0e5VxlFPAHcDA645roqu61IpnhVrBPbXb6dqknmLztcfddgf0PavatFtobS2SQKpdgVUE8A
 bTz9cD9a5DUbG3u0fzbdP3pVMAbdhB6/41iWmtT6M5spS00MeQsh6qfrzn61yx0Zrc9/kv47lv
 slid8MRVcycH5iFJbHyj1/CvM/F1h5XnzK+yQfKPnKqpbPzcdeM0/TdQk8+3eP543wVG4fNtyC
 xOQD0Bq3fTxajbsjDMoYk55yzDC8HnjmtHJNCPkfX03zSNADsTk9cAA7Sefequj69c6NK9zbKD
 OQFRzn5BznA6ZPr1HavQ/HthAjeZEMyJtVscc7QMY5/D8a8klTZIy+hxXK10OuMux7dp+tHxFp
 NolyVU6f8Au4D08s8k/mTyT3x7VFp3h8Ge8kZ/NVU852IAyMqAqnsS7bR69a8t0bU30u6Vm+aJ
 +GU9MV9B+GLcahdWx06XDTMAhA3KTzjg8dfyrklSaeh2QrK2pUGj39pPdTX8Qg+wW48045LOfk
 jH+6Mkgelc/fT3EelfYUmMloc+Xg/LtkYHBHryRX1GfC8F/wCK72311t9pAbh3uIFxG6vGYkJz
 kYDOVUDqeleG6noZ09p9DlVS0mSg6MhBPJ9DjqO1ZzgWpp7Hm0eovp90BbKJAyhWXsDgDGfcVo
 bBYMixPlFmOwDtGp4GfXNQppTW1y9zJG2w7dnBILA9SfQ11cemfazp4lj4jCxMAQTkkgHHqciu
 ds1T7G74Ku7m01q5gbAiuI3V2YfxH5gfx+77ZzT5EmuLONpeFlY4XHO5TtwR+GK1bDw9e/2k0k
 TKI4wnTPLE/MSOpwM/jXTX8UU07R2kIQlpJNxH3PMbcACOnHpXJNandB3RzvgqVobC9srkgJcS
 bU3ZGxsgqfxIx7c10NxHJKj2Us5jtnYyZBzl32glsehH5VDZ6PJZG5a7TCSRt5JA4Zl2uSB16H
 8zW7pGj2twbD+0Fz508kLnGU2p8wyQeT0P0zWMjaKM/QdBu7vXYtRuoS1jJKElkC5AkyMgkf7I
 LfSvZ/GHwrsoNk1jbyRxBgLedlJ80M8mRx0O0cZHIHavqX4WeAnutH1OynsDHFd5PC8o6wfIzd
 MFgQoHq2K9O8V+CrnTLvw94An2Nc311Bdz5AYt5ETow9ky33eeTk1FCrKdRRix4jDqnRlUkfjt
 KZptb8u6YQxq6IFxkCN8L+YFeU6vcQWwvrV0aRYY3W3Yj5twcde2c5+lfoL+2P8As+SfDu2l8W
 +Hrc/YljWWVe/lEfMf+AuQfavzW1DxQuqRNLgL5LLzgfMzlmY+vJ/lX0X1ZxlaR808SqkOaJ2n
 gvxhqGg3dhcQu0U0X72NunVjjn2xX6l/CH46t46vYdD1iGOK5kj/AHcqHiR1GSCD0JHpX5MWdx
 ZXfkR3K4eJcZXoDkn9M17B4L1bU/DGo2mp6dOf9GcSI4/hYHPNfU8N5/WwFdShL3G1zLuj8748
 4Hwud4SVOrFe0SfJLrF/5dz9ms5pa4nwJ4vtPGvhmz1+1I3SriVR/BKv3lrst/Ff0vQrwqwjUp
 u6eqP88cdg6mGrTw9eNpRbTXmhwWhhk0gcGnZFanLZbEez3puCKnpMUEuHYhyaM9qeEppXFOxm
 0+o4NTwwNQ4xQDikInp6n0qAPUqGgD//0PxVkiuG5WVsVjXSX0OTv37vWuvEQZT8vI6VVurNp4
 X2DLdfpQYJ2Z5vJqF1HKc8YPpX3B+x/wCJZHh8T2t1NtaHyJkzwOQwP8h+lfFt/Z+XJ8ykDp9T
 XrXwD8Qw6D4suLC5l8mPUoggb/bjOUH05z+Fa0HaaY60eaDR9q6lfxafGsCWxMkyHa7j5VGCcj
 jrz6dcV51esxuFMqCOOBlCKByRjDMT9TgD611980jxRXYeSUxwNypK7eRyT2wO1ea6pcwRXAaO
 EBRGQWYn5Sx3LgMeeOSa6arOKKtoY+pSwfZ5ra3RfOE2M4wAjDdyevHc964O5bz0a1kIZYhgIM
 gDnJ/PNaV9fRlo5S4YBgCMjLZByeK5q4M8McisQHViCB8wyuf19K52zdFEzT6S8U9uzS2yOoKn
 nbzkgCug0fVIJYprnYZY/wCFFYljt7nPTk1y9xczQxQjJ4G4EjHzH71QRPPBM0lr/o5kXkHoc/
 e69zWaL3Om1zT5GsJ5ZBjbvycZyQBwv0zx+NeB6tYSafevbycYJI96+ibTVTqcawSKFkRg4VuO
 VDNk+38zivJ/FulzRym+lwvmsQoHTCYGOpx3psqnLWx5/JEfKyM/Lz+Fd/8AD3xq3h2+WG8Ja2
 bOMn7jMMZ+nrXJ2tuZSUHLcjH1rFeMxuUbqKg3P0I0efU7rToXs7gypLcCZIl53OoDKx/vL1wO
 g5rIvPDc2pXQu2ViZm86eTG99mdzgDg5Y8D64rxj4M/EdtM1KDQ9elY2bfLFIeTHkYxz2xxX6X
 eDfDOlpFbXYVZJACSAA0fJynPfjBqoUeZ2MZV3TPgjxBpE95dW8AQRQERRRgceVHtUDI/2eeTy
 eTU3hzSA95eXWMW9vKSC3GEA+VV9fumv0e1P4NeENUDyqhjWWJQ0xPA7yEjHXP3fyry+/wDgXe
 3klrF4YtWW25yrLtZyy8uMnJJUd8YHua48RgJ/ZOihmMOp5raeBYb7Trm701xISrShhnCLgMqY
 5Jb5gCfWvY9K+EXh240q3icPPfo1u5YHC75wS8a/QYAJwAck9hXO6d4d8X+Fp1S6QxwueWxycs
 CMjrxjI7GvU9P17W2trfTLW22QxyebKQWDyOnTd68CvOqZbXkvdPWpZvh0vePMPF3w0fw5p2ke
 JdRk8xUlKhF5RVaV4s9eyxEjjoM15c2gRvq7eHgSkscstypVgVIk2NEq9hleDz3NfWPiLU9a1R
 pxPbM0dvaXGxXGVV5OGfnG5jvYAe/FfMmmeFNUHiprCdNkxlL+YpPA3MAB3HQj1qY4KotJLUuW
 ZUpXcGfov4T1y88hWsoN9/qnk3NzEGA8trUlFZsdN4ywHuPQV7D4f8MnU/EK+J9XQXGoxlVV+q
 rG2cIvX1yfU1zPw2+GEFhaIbt3SSUKZDn53BBGGOc455/Cvoq2t9J0W1M6sscNrGx3Nxyo4/z+
 Fe9l+UQovmlrI+bzXPKmIXs07RPEfjb4ZsvFtpc6FfQedHFpVzJIpGQVZ4toP4bvyr+Xr4qeHx
 4W8c6to0EJt4EmLInbB6Y9q/rCjifU7DW9bvlKS6vC0cKHjy7dEYRAjsWLM5H+0PSv59f2w/Dd
 mmuzX4hCTJyXA644rsxcVoziwE9Wj4l0XVXtJ038oDj8DXvnhvUlESPE2UcAbeo5r5mjJR+O9d
 74c1xrWVVyVHbHrXJHQ9CrE/Sf4H/FGz8FJNousbjpl23mqyjLQyY5yO4Nfaeh+I9E8R232rRb
 xLpO4U/Mv1XqK/JXw3q6Xqrk5JABA9K9Q0rWtT8M3iXmnXD28iEMjqTkj0Prj0r7zh7javgoRo
 yXNBfevRn4lx54P4HOKs8XCTp1n13Tt3X6pn6aZFLvrxL4d/F2y8TrDpetbbfUGG1XHEcx/wDZ
 WPp0Ne1fpX7TlOcUcbS9rQd11XVep/HvFHCeMyfEvDYyFn0fRrumTBvWlDjvUHOKcG9a9S581q
 WMigjPaoA1SBzTHcft98Cotue9Sg5paBONyvT0Jp+2m4wM0zOUbH//0fxy2MmCOCR0NIhJ57/0
 r2XxZ8IvFHhxBPcWjvFkfMqnH+c15VdWMtpN5cgII6j0NNqxzRknsclq9r5sece4x29K4IzT2N
 1HcwPh4iGBHYg163JGj5SQ/KBXm+sW5jbleSME+471N7M0g9T7B8A+N7XxD4Jmu7/JltCY5E7y
 H5So9gT1J6CvGvEnijXdRu5ItMhMoiDSSpGGfaWJyTjjHOPTtXmPhXxRNoEd7ZOSbe7UB1Bxkr
 7/AKV3Hhvxpf8Ahzw9qGs2c6R3Wq3ZVkkQOrxwopABzkcue2K3dS+5Dp2ehzdj4k1BZltmU7xu
 DFlzgYAwBjg8Yrqf7SaGNt6OHYAhmXCklfp1yasab8QPEGt297NNbqIbYLJLLCgXYGOBubtk8D
 1rcvvije3iKYjAIj8oGwflkjrU3HY4O6vpJY4QuIyu7069yfY1WS8LBQWLsvXHfFd2PFhu4Xim
 tbZmOQzGJS2PQHH51jTPp86lpVWLJzgce/apEZcepeW+3YY0DZBzzgnoD1/xq7dSx6pbG3uhgs
 wCkYyAxAJ/LrUZ0i3mK+RMCcE/NyD+VIum3iSKZVEiA/MVOeMVRLMXTNEmF7JDt+eNc+xwdvH5
 Vn69o3k6gVUbQ6hsY9Oteo+H57GO8HnAxiRWQ54IPUfhwaZ4v0wMlvdxffV9pJ/u84zTSVhSqO
 542tlLBMrgkAHO4dsV9zfs8/HAaZEnhHxXIXgmcRwTNz5TSOoYk+m0dO1fLKWC3ERUYOBn/wCv
 UVrG9k3mRFlGQVx2OKqErO5M1dan776PGz26kIGtbtot+fmUqGYqfQ44575rbilmSFZI4PKe3J
 LBeDtkj3NkHGOtfBX7L37R5UWHgPxdKDB5gFvcOeEYcbXPpn8q/TJVglOUiBEqqf8AfXaMc89R
 Xp0ZJq6PIxCcXZnG3ttLfodKnt025P710G4kADr2x0/GqthZaWlxL9ksI3jRBtbBABc5J5J7jr
 XpUdirTAoAYwuRxnkkEn8DimppMJLW1sqhtixBfXB28kdcCtjlUtDxrXtRVLEQmyief/V7RnIj
 z1IHQfxY74rG8EeE0m8YRanqSrNHFL9rll2DY8igJBGOPux5yB6gZ717nB4Q0xriTULqPzAhRQ
 MAbjk7ifUk/pXXadptlFZpY28CqgJYEcEAnIx9DzWMqKbuzaOI5VZG9p2qyzMYoYmLFyq8Y2jk
 9vetN4jfSRrqbB1BBEK/dwp4LevTpVO1SaGAOq7SoJY9NzEcD9KthkiaORBtJjBbPze1U0Zrud
 KtvaXcEsOeZQ2Qfevy7/a/+E2g6nd6xCyhZI1Qqw7F1DHpX6PSG480raNgdj/tHtXyh8f/AA3q
 urW15eBS4mTa7Dts4yPSsKtO6sdVCpaSP5zvFnho6BqDQxP5kXY9656JwhyeSMY+v1r3j4weEt
 V0fUZ2uIXVVb68Hv8ASvn/AOZOD39a8yW59BHY9L8M+J5bG4jcyYycnB9uK+sPDl+uvWRtHwbh
 RlG65Poc18GRXRifcOx7e1fRHw28QySGMhx5ilS3b2B/OrjI5K1LS57jY6lJo2pmK4ygQ5Gev4
 V9PfB/42ya/Z3Nhrkh+06bJ5cgb7xjP3W9+K+bPEVsLvTv7ZiUuc7nA4Pv+deafCbU7zXPiBrG
 rwKY7TyNhUE4JHCZHrXrZTnVfA1faUWfK8ScJ4LOMP8AV8ZG6Wz6r0Z+wsMyTxJNEco4BB9Qal
 zXgXw++IBhNpoGssNkiL5UndSeArfj0Ne95Ff0PkWd0sdQVWm9eq7M/gzjfg3FZJjXhq6916xf
 Rr/Puh30p2cUylzXs2Pj73H7hTw351BT+nWgLdixmlquG96eH9aY7n//0vmeL4swXRFnekSwjK
 nJJJ3dwCOmM1m6vovwt8a/vr7/AEG5EbbWh+XLdBkDIPrivnaxkG0GIHcSd3oAP6mtGO8aGQvv
 5UdM1sql9zzvZJbGxq/wE1mJvN0OaO/jIJHzBWKjoce/pXjXiD4fa3awMb+ykgwduWUjP0r3Ox
 8UarZhDb3LfNtPXqR04rsrTxtHeQtDrsQuQATuOMnjp+OKLJl80kfnVrGkXGnybyv7tjgYr1HQ
 dIa/8Ew2ht43keRnUvjcM5zt+oI/IV9NeJPB3g/xhZS/Y4xZSyDgggAE9Pl6V87+I7PxP4Chmt
 47dZrd4VgSdMkBNwYnHYnGM1MoNG8aqkjg77wprGnWr/YEma0l2mXn5Sy9NwHUA9M1iw292zIi
 oVIPcYxiu8074k29vbPDPEVkYgbjkgrxn+VYl/4l0S5mkmiLK0hyeDgk8nipRV2atm97FsQxgH
 PU+3JroQ8lwFDxgbh83p615+viO0UkiUlj2IOOcHJJ96ux+JYsfumJYduOBn6+lUQ4s9MtYrLa
 EngXOMgEelWY9NtJNy27NCx5HJI/I5rzOHxVlyHRunYDr+NaI8WzI+Ugl29j8vTnt+VFyXFnV6
 hp95FLvkKzL6gYI/CnS2t/c2KRXoeO3Y5Uvyvb+Ltz71kWXjXTllUaiJoEZuWEYfg47AjrzXd6
 V4z0S6g+yNcpKkhICHg4zjBVsex6mmrEtNdDkpLJrUozqURlxleVx7VM9lDcReYQWfAHoBx1rv
 pdMtrmN5tJkCnGTGT8nUjp1HIrnrmylsZP3kfkN3I+aNu/4UWFc5ewkg04mWEyJKpyGU4APrjG
 f1r9PP2Xf2iI9fjsfAvjCb/S1IjtZ2ON/YIxPt0Nfm3d6dv/AHi/uztJHcNx2PtVbSry+0q8gn
 tZTDPGwMbAkYI9DWlKq4u6M61JTVmf0TW+9ZzHj5EwcZ/h4/HrVm3ucnvvBYcnBBGcc+lfHH7N
 Px7g8d6VH4S8R3Ah1uENHAzN/rQCMc9zX2qbaOBdy5d2G8kHBTDAd/U9OK9WE1JXR4c4OLsSRe
 cYYSBnYAzDOQTnvWrbmSSQxRpt+UDP+zk5P1rNgaV7jyoAcsAxyf4egyfxreiZoXCRAZbKnPQL
 gAmqItd6mpbuxtpAzcgqoyMHHUEVNGqFNzLuMi7Sfx5qC1hc7TMflAAA65Pqc+2K24tjFPOCqB
 uCkDuRwKybNUijZvtlc43MjE59cdPyqzf6TY6zp11DMoJljZMEAklqlaKRrZp0+UMw7dQRjOPa
 oFd4diWp3eWpJ9z61LZUUflX8aPhvperWNy0sA8zc0Y45yfX6V+PXxG8Pjw9rDWart2kj2r9/f
 jRpD6fJqZPyq8wkT6Pk1+Ofx/8LzOH1iNcmNzuwOxPrXnVoe9oe1hKr5dT5I5zXr/w3trk3UWA
 Qkh5wecdv1rym3hM8yxrxnr7V9HeB9M+xxxuV5Y/ovrWUdzprS9093fVItH8LXd9dASRQQybgT
 jJxgfrim/ADwvPpuhveXabbjUWNwVIwQgHy/j3rC1S3XXLzT/CURPkHF1e47RjlUz745FfR9tD
 Domlvd7SJLlAkfHCqRgVtGN2ee5WVjlL6aS2vlniOQMHHuOlfS/w8+Iia1GulaqwS6XAjc9H9A
 c9/wCdfOCaYZfD91f3B3TtdIkI7+WiN5jf99MoH41U8P3TROYwxEgIIPQgj3r28mzqvgayq0X6
 ro0fJcXcI4POsK8Li1/hl1i+6/VdT79BI4pdw6HrXkfgPx8uoNHoetOEuwMRyE48zHY+/wDOvW
 Cj59K/oDJc7o46iqtJ+q6pn8J8YcG4zJsU8NiVddJLaS7r9V0JqXPaq6uQeealDrXsHySY84pw
 z3plLk0rFpn/2Q==
END:VCARD`;

export default vCard;