import React, { useState }    from 'react';
import SocialMediaHeroSection from '../components/SocialMediaHeroSection';
import SocialMediaInfoSection from '../components/SocialMediaInfoSection';
import Sidebar                from '../components/Sidebar';
import {
  socialMediaObj1,
  socialMediaObj2,
  socialMediaObj3,
  socialMediaObj4,
} from '../components/SocialMediaInfoSection/data';
import SocialMedia from '../components/SocialMedia';


const SocialMediaPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <SocialMediaHeroSection />
      <SocialMediaInfoSection {...socialMediaObj1} />
      <SocialMediaInfoSection {...socialMediaObj2} />
      <SocialMediaInfoSection {...socialMediaObj3} />
      <SocialMediaInfoSection {...socialMediaObj4} />
      <SocialMedia />
    </>
  );
}

export default SocialMediaPage;



// import React from 'react'
// // import PageNotFound   from '../components/PageNotReady';
// // import SocialMedia from '../components/SocialMedia';
// import SocialMediaHeroSection from '../components/SocialMediaHeroSection';

// const SocialMediaPage = () => {
//   return (
//     <>
//       <SocialMediaHeroSection />
//       {/* <SocialMedia /> */}
//       {/* <PageNotFound
//       headline    ="Thank you for your interest!"
//       description ="We are excited that you are interested in our Company. We are almost ready to launch our Social Media Services page where you can learn about the social media services that we can provide! Please feel free to leave your info below and we can let you know as soon as we are ready"
//       actionText  ="Stay Connected" /> */}
//     </>
//   )
// }

// export default SocialMediaPage