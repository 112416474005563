import React      from 'react';
import Icon1      from '../../images/building-app.svg';
import Icon2      from '../../images/building-website.svg';
import Icon3      from '../../images/select.svg'
import Icon4      from '../../images/service-rates.svg';
import RateSheet  from '../../assets/documents/service-rates.pdf'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './services.elements.js';

const Services = () => {

  const handleRateSheetDownload = () => {
    const element = document.createElement('a');
    element.href = RateSheet;
    element.download = 'service-rates.pdf';
    document.body.appendChild(element);
    element.click();
  };
  
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Mobile Development</ServicesH2>
          <ServicesP>
            We create perfect fit apps to increase your overall efficiency and revenue.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Website</ServicesH2>
          <ServicesP>
            We integrate the current or build a new website to match your application.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Prebuilt Applets</ServicesH2>
          <ServicesP>
            Choose from any prebuilt app functions to add to your app or website.
          </ServicesP>
        </ServicesCard>
        <ServicesCard onClick={handleRateSheetDownload} >
          <ServicesIcon src={Icon4} />
          <ServicesH2>Service Rates</ServicesH2>
          <ServicesP>
            Click here to download our current service rates.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
