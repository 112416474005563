import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const CareersPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch our Careers page where you will be able to search through career opportunities! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default CareersPage