export const socialMediaObj1 = {
    id            : 'strategy',
    destination   : 'content',
    isScroll      : true,
    lightBg       : false,
    lightText     : true,
    lightTextDesc : true,
    topLine       : 'Social Media Strategy',
    headline      : 'Crafting Your Path to Success',
    description   : 'Develop tailored strategies that align with your business goals, ensuring your social media efforts drive real results.',
    buttonLabel   : 'Learn More',
    imgStart      : false,
    img           : require('../../images/strategy.svg').default,
    alt           : 'Strategy',
    dark          : true,
    primary       : true,
    darkText      : false
  };
  
  export const socialMediaObj2 = {
    id            : 'content',
    destination   : 'analytics',
    isScroll      : true,
    lightBg       : true,
    lightText     : false,
    lightTextDesc : false,
    topLine       : 'Content Creation',
    headline      : 'Engaging Content That Resonates',
    description   : 'From eye-catching graphics to compelling copy, we create content that captures attention and engages your audience.',
    buttonLabel   : 'Learn More', //'View Portfolio',
    imgStart      : true,
    img           : require('../../images/content_creator.svg').default,
    alt           : 'Content Creation',
    dark          : false,
    primary       : false,
    darkText      : true
  };
  
  export const socialMediaObj3 = {
    id            : 'analytics',
    destination   : 'community',
    isScroll      : true,
    lightBg       : false,
    lightText     : true,
    lightTextDesc : true,
    topLine       : 'Analytics & Reporting',
    headline      : 'Insights to Drive Decision Making',
    description   : 'Utilize data-driven insights to refine your strategy, ensuring your social media efforts are as effective as possible.',
    buttonLabel   : 'Learn More', //'Discover Insights',
    imgStart      : false,
    img           : require('../../images/analytics.svg').default,
    alt           : 'Analytics',
    dark          : true,
    primary       : true,
    darkText      : false
  };
  
  export const socialMediaObj4 = {
    id            : 'community',
    destination   : '/signup',
    isScroll      : false,
    lightBg       : true,
    lightText     : false,
    lightTextDesc : false,
    topLine       : 'Community Management',
    headline      : 'Building Your Online Community',
    description   : 'Engage with your audience to build a loyal community, fostering relationships that turn followers into fans.',
    buttonLabel   : 'Get Started',
    imgStart      : true,
    img           : require('../../images/community_management.svg').default,
    alt           : 'Community Management',
    dark          : false,
    primary       : false,
    darkText      : true
  };
  