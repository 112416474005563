import styled from "styled-components";
import { Link } from 'react-router-dom';

export const StyledFooterLink = styled(Link)`
  color             : #fff;
  text-decoration   : none;
  margin-bottom     : 0.5rem;
  font-size         : 14px;

  &:hover {
    color           : #67d6ff;
    transition      : 0.3s ease-out;
  }
`;