import React, {
    useState,
    useEffect }                       from 'react';
  
  import {
    useDispatch,
    useSelector,
    connect }                         from 'react-redux';
  
  import { IconContext }              from 'react-icons/lib';
  import { animateScroll as scroll }  from 'react-scroll';
  import LuckeyLogicLogo              from '../../images/luckey-logic-logo.svg';
  
  import {
    MobileIcon,
    Nav,
    NavbarContainer,
    NavItem,
    NavLinks,
    IconContainer,
    IconBadge,
    NavLogo,
    NavLogoImage,
    NavMenu,
    NavBtn,
    NavBtnLink,
    NavGradient,
    Hamburger,
    SignOutIcon,
    Avatar }                       from './elements';
  import { signOutUserStart }         from '../../redux/User/user.actions';

  import { NotificationsNone, Language, Settings } from '@material-ui/icons';

  import * as FaIcons from '@material-ui/icons';

  import LogoutIcon from '@mui/icons-material/Logout';

  
  const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser
  });
  
  const NavbarAuth = ({ toggle }) => {
    const dispatch                  = useDispatch();
    const {currentUser}             = useSelector(mapStateToProps);
    const [click, setClick]         = useState(false);
    const signOut                   = () => {
      dispatch(signOutUserStart());
    };
  
    const handleClick               = () => setClick(!click);
    const closeMobileMenu           = () => setClick(false);
  
    const [scrollnav, setScrollNav] = useState(false);
  
    const changeNav = () => {
      if (window.scrollY >= 80) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', changeNav);
    }, []);
  
    const toggleHome = () => {
      scroll.scrollToTop();
    };
  
    return (
      <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <Nav scrollnav={ scrollnav ? 1 : 0 }>
            <NavGradient scrollnav={ scrollnav ? 1 : 0 }>
            <NavbarContainer>
              <NavLogo onClick={toggleHome} to='/'>
                <NavLogoImage src={LuckeyLogicLogo} />
              </NavLogo>
              <MobileIcon onClick={toggle} scrollnav={ scrollnav ? 1 : 0 }>
                <Hamburger scrollnav={ scrollnav ? 1 : 0 } color='#1993356' />
              </MobileIcon>
              <NavMenu>
                <NavItem>
                  <NavLinks
                    to        ='about'
                    smooth    ={true}
                    duration  ={500}
                    spy       ={true}
                    exact     ='true'
                    offset    ={-80}
                    scrollnav ={ scrollnav ? 1 : 0 }
                  >
                    <IconContainer>
                         <NotificationsNone />
                         {/* <IconBadge>5</IconBadge> */}
                     </IconContainer>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to        ='discover'
                    smooth    ={true}
                    duration  ={500}
                    spy       ={true}
                    exact     ='true'
                    offset    ={-80}
                    scrollnav ={ scrollnav ? 1 : 0 }
                  >
                     <IconContainer>
                        <Language />
                        {/* <IconBadge>3</IconBadge> */}
                     </IconContainer>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to        ='services'
                    smooth    ={true}
                    duration  ={500}
                    spy       ={true}
                    exact     ='true'
                    offset    ={-80}
                    scrollnav ={ scrollnav ? 1 : 0 }
                  >
                    <IconContainer>
                      <Settings />
                    </IconContainer>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to        ='signup'
                    onClick   ={signOut}
                    smooth    ={true}
                    duration  ={500}
                    spy       ={true}
                    exact     ='true'
                    offset    ={-80}
                    scrollnav ={ scrollnav ? 1 : 0 }
                  >
                    <IconContainer onClick={signOut}>
                      <LogoutIcon />
                    </IconContainer>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to        ='signup'
                    smooth    ={true}
                    duration  ={500}
                    spy       ={true}
                    exact     ='true'
                    offset    ={-80}
                    scrollnav ={ scrollnav ? 1 : 0 }
                  >
                    
                    <Avatar src={currentUser.photoURL} alt="avatar" />
                  </NavLinks>
                </NavItem>
                
              </NavMenu>
              
              {currentUser && (
                <>
                  <NavBtn>
                    <NavBtnLink to='/dashboard'> Dashboard </NavBtnLink>
                  </NavBtn>
                </>
              )}
  
              {!currentUser && (
                <>
                  <NavBtn>
                    <NavBtnLink to='/dashboard'> Sign In </NavBtnLink>
                  </NavBtn>
                </>
              )}
              
            </NavbarContainer>
            </NavGradient>
          </Nav>
        </IconContext.Provider>
      </>
    );
  };
  
  export default connect(mapStateToProps, null)(NavbarAuth);
  




// import React, { useState }  from 'react'
// import { useSelector, useDispatch }     from    'react-redux';
// import { signOutUserStart }             from    '../../redux/User/user.actions';
// import { Link }                         from    'react-router-dom'
// import { NotificationsNone, Language, Settings } from '@material-ui/icons';
// import {
//     NavbarContainer,
//     NavbarWrapper,
//     TopLeft,
//     Logo,
//     TopRight,
//     IconContainer,
//     IconBadge,
//     Avatar} from './elements';


// const mapState = ({ user }) => ({
//     currentUser: user.currentUser
// });

// const NavbarAuth = () => {
//     const rndNmr = () => Math.floor(Math.random() * 99) + 1;

//     return (
//         <NavbarContainer>
//             <NavbarWrapper>
//                 <TopLeft>
//                     <Logo>Luckey Logic Admin</Logo>
//                 </TopLeft>
//                 <TopRight>
//                     <IconContainer>
//                         <NotificationsNone />
//                         <IconBadge>5</IconBadge>
//                     </IconContainer>
//                     <IconContainer>
//                         <Language />
//                         <IconBadge>3</IconBadge>
//                     </IconContainer>
//                     <IconContainer>
//                         <Settings />
//                     </IconContainer>
//                     <Avatar src={`https://randomuser.me/api/portraits/women/${rndNmr()}.jpg`} alt="avatar" />
//                 </TopRight>
//             </NavbarWrapper>
//         </NavbarContainer>
//     )
// }

// export default NavbarAuth