import React from 'react';
import {
  FormCheckboxStyled,
  FormLabelStyled,
  FormCheckboxContainer,
  FormLabelLargeStyled,
  ToggleSwitch,
  Label,
  Input,
  Switch
} from './index.elements';

const FormCheckbox = ({ id, handleChange, label, headline, checked, ...otherProps }) => {
  return (
    <Label>
      <span>{checked ? "Allow Texts" : "Don't Allow Texts"}</span>
      <Input checked={checked} type="checkbox" onChange={handleChange} />
      <Switch />
    </Label>
    // <FormCheckboxContainer>
    //   {headline && (
    //     <FormLabelLargeStyled htmlFor={id}>
    //       {headline}
    //     </FormLabelLargeStyled>
    //   )}

    //   {label && (
    //     <FormLabelStyled htmlFor={id}>
    //       {label}
    //     </FormLabelStyled>
    //   )}
    //   <ToggleSwitch>
    //     <FormCheckboxStyled
    //       id={id}
    //       type='checkbox'
    //       checked={checked}
    //       onChange={handleChange}
    //       {...otherProps}
    //     />
    //     <span />
    //   </ToggleSwitch>
    // </FormCheckboxContainer>
  );
};

export default FormCheckbox;
