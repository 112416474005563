import React from 'react';
import Dashboard from '../components/Dashboard';
import ScrollToTop from '../components/ScrollToTop';

function DashboardPage() {
  return (
    <>
      <ScrollToTop />
      <Dashboard />
    </>
  );
}

export default DashboardPage;
