import styled from 'styled-components';

export const FormCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  span {
    color: #fff;

  }
`;

export const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 34px;
  background: #b3b3b3;
  border-radius: 34px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(26px, -50%);
    }
  }
`;



export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  color: white;
  padding-bottom: 48px;
  cursor: pointer;
`;

// export const Switch = styled.div`
//   position: relative;
//   width: 60px;
//   height: 28px;
//   background: #b3b3b3;
//   border-radius: 32px;
//   padding: 4px;
//   transition: 300ms all;

//   &:before {
//     transition: 300ms all;
//     content: "";
//     position: absolute;
//     width: 28px;
//     height: 28px;
//     border-radius: 35px;
//     top: 50%;
//     left: 4px;
//     background: white;
//     transform: translate(0, -50%);
//   }
// `;

// export const Input = styled.input`
//   opacity: 0;
//   position: absolute;

//   &:checked + ${Switch} {
//     background: green;

//     &:before {
//       transform: translate(32px, -50%);
//     }
//   }
// `;

















// export const FormCheckboxStyled = styled.input`
//   display: none;
//   /* width: 60px; */
//   /* height: 34px; */
// `;

// export const ToggleSwitch = styled.label`
//   position: relative;
//   display: inline-block;
//   width: 60px;
//   height: 34px;

//   span {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     border-radius: 34px;
//     transition: background-color 0.2s ease;

//     &:before {
//         content: "";
//         position: absolute;
//         height: 26px;
//         width: 26px;
//         left: 4px;
//         bottom: 4px;
//         background-color: white;
//         border-radius: 50%;
//         transition: transform 0.2s ease;
//       }
//     }
  

//   ${FormCheckboxStyled}:checked + & {
//     span {
//       background-color: #2196F3;

//       &:before {
//         transform: translateX(26px);
//       }
//     }
//   }
// `;

// export const FormLabelLargeStyled = styled.label`
//   margin-bottom: 8px;
//   font-size: 18px;
//   font-weight: 400;
//   color: #fff;
// `;

// export const FormLabelStyled = styled.label`
//   margin-bottom: 8px;
//   font-size: 14px;
//   color: #fff;
// `;

// export const FormCheckboxContainer = styled.div`
//   display: grid;
// `;
