export const aboutObj1 = {
  id            : 'about',
  destination   : 'discover',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Who is luckey Logic?',
  headline      : 'Dedicated, Focused, Passionate',
  description   : 'We here at luckey logic have worked in many fields, and we understand the day to day hardships and struggles that need attention. We strive to make your day to day tasks as simple as possible.',
  buttonLabel   : 'Get Started',
  imgStart      : false,
  img           : require('../../images/global.svg').default,
  alt           : 'GlobePins',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const aboutObj2 = {
  id            : 'discover',
  destination   : 'discover2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : '... But Why?',
  headline      : 'We have all been there.',
  description   : 'We have all needed a simpler solution. Maybe something more direct! Or maybe something more specific! What ever it may be, that something can change everything that we do!',
  buttonLabel   : 'Learn More',
  imgStart      : true,
  img           : require('../../images/user-accounts.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const aboutObj3 = {
  id            : 'discover2',
  destination   : 'signup',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'The Perfect Fit',
  headline      : 'The desired outcome!',
  description   : 'You came to us for a reason, You needed something new or something changed. We are here to help you help your customer. Our only mission is to help you complete your mission!',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../images/logistics.svg').default,
  alt           : 'Accounts',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const aboutObj4 = {
  id            : 'signup',
  destination   : '/signup',
  isScroll      : false,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Lets Start Today',
  headline      : 'Scheduling your consult is  only a click away',
  description   : "Share your vision, and 10 minutes of your time. All you need to do is add your information and you're ready to go.",
  buttonLabel   : 'Sign Up and Start Now',
  imgStart      : true,
  img           : require('../../images/custom-merch.svg').default,
  alt           : 'Papers',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const aboutObj5 = {
  id            : 'discover',
  destination   : 'about',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Unlimited Potential',
  headline      : 'Create accounts for customers and employees',
  description   : 'We provide you the capability to create and store user accounts for your customers and your employees on the web and mobile devices.',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../images/thought-process.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};