import React from   'react';
import {
    FormSelectStyled,
    FormLabelStyled,
    FormSelectContainer, 
    FormLabelLargeStyled}  from './formSelect.elements'

const FormSelect = ({ handleChange, label, headline, ...otherProps }) => {
  return (
    <FormSelectContainer>
      {headline && (
        <FormLabelLargeStyled htmlFor='for'>
          {headline}
        </FormLabelLargeStyled>
      )}

      {label && (
          <FormLabelStyled htmlFor='for'>
              {label}
          </FormLabelStyled>
      )}
      <FormSelectStyled onChange={handleChange} {...otherProps} />
    </FormSelectContainer>
  )
}

export default FormSelect;