import QuestionBlock from "./QuestionBlock";

/**
 * Class to create a Section object
 */
class Section {
    /**
     *@param {string} title - Title of the section
     *@param {Array<QuestionBlock>} questionBlocks - List of question blocks in the section
     */

    constructor(title, questionBlocks) {

        /**
         *@property {string} title - Title of the section
         *@property {Array<QuestionBlock>} questionBlocks - List of question blocks in the section
         */

        this.title = title;
        this.questionBlocks = questionBlocks;
    }

    /**
     * Function ToDict
     * @property {Function} ToDict Returns a dictionary format of a Section
     * @returns {Object}
     */

    ToDict() {
        return {
            "title" : this.title,
            "questionBlocks" : this.questionBlocks.map(qb => qb.ToDict()),
        };
    }

    /**
     * Function FromDict
     * @property {Function}                  FromDict Creates a Section object from a dictionary
     * @property {Object}                    data The data of the Section
     * @static
     * @returns {Section}                    The Section object for export
     */

    static FromDict(data) {

        if (data == null) {
            return data
        }

        console.log(`Called FromDict in Section`);

        return new Section(
            data.title,
            data.questionBlocks.map(qbData => QuestionBlock.FromDict(qbData))
        );
    }
}

/**
 * A Section Object
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} SectionConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type SectionConverter
 */

export const sectionConverter = {

    /**
     * Function toFirestore
     * @property {Function}                  toFirestore Function to send Section object to database
     * @param    {Section}                   section Section to be converted
     * @returns  {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (section) {
        return {
            "title" : section.title,
            "questionBlocks" : section.questionBlocks.map(qb => qb.ToDict()),
        }
    },

    /**
     * Function fromFirestore
     * @property {Function}                                  fromFirestore Function to convert data from database into Section object
     * @param    {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param    {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns  {Section}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Section(
            data.title,
            data.questionBlocks.map(qbData => QuestionBlock.FromDict(qbData))
        );
    }
}

export default Section;
