import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/global-keywords.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './elements';

function AboutHeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>About Luckey Logic</HeroH1>
        <HeroP>
        Who we are, why we do what we do, and how we would be honored to help you!
        </HeroP>
      </HeroContent>
    </HeroContainer>
  );
}

export default AboutHeroSection;
