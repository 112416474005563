import Address, { addressConverter  } from "./Address";
import Email,   { emailConverter    } from "./Email";
import Phone                          from "./Phone";

/**
 * Class to create a Contact object
 */
 class Contact {
    /**
     *@param {Address}  [address]   - Users contact address information (Optional)
     *@param {Email}    email       - Users contact email information
     *@param {Phone}    [phone]     - Users contact telephone information
     */

    constructor(address, email, phone) {

        /**
         *@property {Address}   [address]   - Users contact address information (Optional)
         *@property {Email}     email       - Users contact email information
         *@property {Phone}     [phone]     - Users contact telephone information
         */

        this.address    = address;
        this.email      = email;
        this.phone      = phone;

    }
    /**
 * Function ToDict
 * @property    {Function}  ToDict Returns a JSON Contact object
 * @returns     {Object}    The returned JSON Contact object
 */

    ToDict() {
        return {
            "address"   : this.address.ToDict(),
            "email"     : this.email.ToDict(),
            "phone"     : this.phone.ToDict()
        };
    }

    /**
* Function FromDict
* @property {Function}  romDict Returns a dictionary format of a
* @property {Object}    data The data from database
* @static
* @returns  {Contact}   The map object for export
*/

    static FromDict(data) {
        if (data == null) {
            return data
        }

        // console.log(`Called FromDict in Contact`);

        return new Contact(
            Address.FromDict(data.address),
            Email.FromDict(data.email),
            Phone.FromDict(data.phone));

    }
}

/**
 * A Contact
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} ContactConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type ContactConverter
 */

const contactConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Contact object to database
     * @param       {Contact}   contact Contact to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (contact) {
        return {

            address : addressConverter  .toFirestore(contact.address),
            email   : emailConverter    .toFirestore(contact.email),
            phone   : contactConverter  .toFirestore(contact.phone)

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into Contact object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {Contact}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Contact(
            Address .FromDict(data.address),
            Email   .FromDict(data.email),
            Phone   .FromDict(data.phone)
        );
    }
}

export default Contact;