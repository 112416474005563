import React, { useState }          from 'react';
import About from '../components/About';
import AboutHeroSection from '../components/AboutHeroSection';
import AboutInfoSection from '../components/AboutInfoSection';
import Sidebar from '../components/Sidebar';
import {
  aboutObj1,
  aboutObj2,
  aboutObj3,
  aboutObj4,
} from '../components/AboutInfoSection/Data';


const AboutPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <AboutHeroSection />
      <AboutInfoSection {...aboutObj1} />
      <AboutInfoSection {...aboutObj2} />
      <AboutInfoSection {...aboutObj3} />
      {/* <About /> */}
      {/* <AboutInfoSection {...aboutObj4} /> */}
    </>
  );
}

export default AboutPage;