//19:41 componentDidMount()

import React, { useEffect }         from    'react';
import { useDispatch }              from    'react-redux'
import { Routes, Route, Navigate }  from    'react-router-dom'
import { checkUserSession }         from    './redux/User/user.actions';
import                                      './App.css';


//HOC
import WithAuth from './hoc/withAuth';

// pages
import Home                     from    './pages';
import SigninPage               from    './pages/signin';
import SignupPage               from    './pages/signup';
import RecoveryPage             from    './pages/recovery';
import PageNotReadyPage         from    './pages/pagenotready';
import DashboardPage            from    './pages/dashboard';
import AboutPage                from    './pages/about';
import CareersPage              from    './pages/careers';
import InvestorsPage            from    './pages/investors';
import PressPage                from    './pages/press';
import ContactPage              from    './pages/contact';
import LegalPage                from    './pages/legal';
import GuidesPage               from    './pages/guides';
import FAQPage                  from    './pages/faq';
import SocialMediaPage          from    './pages/socialmedia';
import MobileDevPage            from    './pages/mobiledev';
import WebDevPage               from    './pages/webdev';
import BoltOnPage               from    './pages/bolton';
import BlogPage                 from    './pages/blog';

import UnauthenticatedLayout    from    './layouts/UnauthenticatedLayout';
import AuthenticatedLayout      from    './layouts/AuthenticatedLayout';
import LimitedLayout            from    './layouts/LimitedLayout'

import LinkSignIn               from    './components/LinkSignIn';
import Discovery                from    './components/Discovery';
import FinishProfile            from    './components/FinishProfile';
import ProfilePage              from './pages/profilePage';
import DirectoryPage            from './pages/directory';

const App = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkUserSession());
    }, [dispatch])

    return (
            <Routes>
              <Route exact  path='/'                    element={
                  <UnauthenticatedLayout>
                      <Home />
                  </UnauthenticatedLayout>
              } />

              <Route        path='/signin'              element={
                  <LimitedLayout label="Home"           destination="/">
                      <SigninPage />
                  </LimitedLayout>
              } />

              <Route        path='/signup'              element={
                  <LimitedLayout label="Home"           destination="/">
                      <SignupPage />
                  </LimitedLayout>
              } />

              <Route        path='/recovery'            element={
                  <LimitedLayout label="Home"           destination="/">
                      <RecoveryPage />
                  </LimitedLayout>
              } />

              <Route        path='/about-us'            element={
                  <LimitedLayout label="Home"           destination="/">
                      <AboutPage />
                  </LimitedLayout>
              } />

              <Route        path='/careers'             element={
                  <LimitedLayout label="Home"           destination="/">
                      <CareersPage />
                  </LimitedLayout>
              } />

              <Route        path='/investors'           element={
                  <LimitedLayout label="Home"           destination="/">
                      <InvestorsPage />
                  </LimitedLayout>
              } />

              <Route        path='/press'               element={
                  <LimitedLayout label="Home"           destination="/">
                      <PressPage />
                  </LimitedLayout>
              } />

              <Route        path='/contact'             element={
                  <LimitedLayout label="Home"           destination="/">
                      <ContactPage />
                  </LimitedLayout>
              } />

              <Route        path='/profile/*'     element={
                  <LimitedLayout label="Home"           destination="/">
                      <ProfilePage />
                  </LimitedLayout>
              } />

              

                <Route        path='/directory/'     element={
                  <LimitedLayout label="Home"           destination="/">
                      <DirectoryPage />
                  </LimitedLayout>
              } />
              

              <Route        path='/legal'               element={
                  <LimitedLayout label="Home"           destination="/">
                      <LegalPage />
                  </LimitedLayout>
              } />

              <Route        path='/guides'              element={
                  <LimitedLayout label="Home"           destination="/">
                      <GuidesPage />
                  </LimitedLayout>
              } />

              <Route        path='/faq'                 element={
                  <LimitedLayout label="Home"           destination="/">
                      <FAQPage />
                  </LimitedLayout>
              } />

              <Route        path='/social-media'        element={
                  <LimitedLayout label="Home"           destination="/">
                      <SocialMediaPage />
                  </LimitedLayout>
              } />

              <Route        path='/app-dev'             element={
                  <LimitedLayout label="Home"           destination="/">
                      <MobileDevPage />
                  </LimitedLayout>
              } />

              <Route        path='/web-dev'             element={
                  <LimitedLayout label="Home"           destination="/">
                      <WebDevPage />
                  </LimitedLayout>
              } />

              <Route        path='/bolt-on'             element={
                  <LimitedLayout label="Home"           destination="/">
                      <BoltOnPage />
                  </LimitedLayout>
              } />

              <Route        path='/blog'                element={
                  <LimitedLayout label="Home"           destination="/">
                      <BlogPage />
                  </LimitedLayout>
              } />

              <Route exact  path="/privacy.html"        element={
                  <LimitedLayout label="Home"           destination="/">
                    <Navigate push to="/privacy.html"/>
                  </LimitedLayout>
              } />

              <Route exact  path='/dashboard'           element={
                  <WithAuth>
                      <AuthenticatedLayout>
                        <DashboardPage />
                      </AuthenticatedLayout>
                  </WithAuth>
              } />
              <Route exact  path='/link-account'        element={
                  <WithAuth>
                      <AuthenticatedLayout>
                        <LinkSignIn />
                      </AuthenticatedLayout>
                  </WithAuth>
              } />
              <Route exact  path='/discovery'           element={
                  <WithAuth>
                      <LimitedLayout label="Dashboard"  destination="/dashboard" >
                        <Discovery />
                      </LimitedLayout>
                  </WithAuth>
              } />
              
              <Route exact  path='/complete-profile'    element={
                  <WithAuth>
                      <LimitedLayout label="Dashboard"  destination="/dashboard" >
                        <FinishProfile />
                      </LimitedLayout>
                  </WithAuth>
              } />

              <Route        path='*'                    element={
                  <LimitedLayout label="Home"           destination="/" >
                    <PageNotReadyPage />
                  </LimitedLayout>
              } />
            </Routes>
            );
}

export default App;
