import React from 'react'
import PageNotFound   from '../components/PageNotReady';

const WebDevPage = () => {
  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our Company. We are almost ready to launch the Website Development page! Soon you will be able to find more information about the Websites and Web Apps we build. Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  )
}

export default WebDevPage